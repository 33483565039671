import React from 'react';
import './AvailableSize.scss';

interface IAvailableSizeProps {
  sizes: number[];
}

export const AvailableSize = ({ sizes }: IAvailableSizeProps) => (
  <div className="AvailableSizeHolder">
    <span className="AvailableSizeTitle">Available sizes:</span>
    <ul>
      {sizes
        .sort((a, b) => b - a)
        .map((item) => (
          <li key={item}>{item}</li>
        ))}
    </ul>
  </div>
);
