import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { SimpleBreadcrumbs } from '../../Components/Breadcrumbs/SimpleBreadcrumbs';
import { categories } from '../../utils/constants';
import locations from '../../utils/locations';

import './SectionPage.scss';

const SectionPage = () => {
  const { sectionName } = useParams<{ sectionName: string }>();

  return (
    <main className="section-page-wrapper">
      <SimpleBreadcrumbs sectionName={sectionName!} />
      <div className="section-page-content">
        {Object.entries(categories[sectionName!]!).map((category, index: number) => (
          <Link
            key={index}
            to={locations.categoryPage(sectionName!, category[0])}
            className="section-page-link"
          >
            {category[0]}
          </Link>
        ))}
      </div>
    </main>
  );
};

export default SectionPage;
