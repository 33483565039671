import React from 'react';

import { Popup } from '../Popup/Popup';

interface INotificationProps {
  id: number;
  type: string;
  message: string;
  clearNotification: (is: number) => void;
}

const Notification = ({ id, type, message, clearNotification }: INotificationProps) => {
  let notificationTitle;

  switch (type) {
    case 'success':
      notificationTitle = 'Congratulations!';
      break;
    case 'warning':
      notificationTitle = 'Warning!';
      break;
    case 'error':
      notificationTitle = 'Sorry!';
      break;
    case 'info':
      notificationTitle = null;
      break;
    default:
      notificationTitle = null;
      break;
  }

  return (
    <Popup
      title={notificationTitle}
      text={message}
      onClose={() => clearNotification(id)}
      open={true}
    />
  );
};

export default Notification;
