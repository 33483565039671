import React from 'react';
import { useHistory } from 'react-router-dom';

import { MainButton } from '../../../Components/Buttons/MainButton';

const WISHLIST_EMPTY_TEXT =
  'Your Wishlist is empty. The items you added to the Wishlist will be available here.';

export const WishlistEmpty = () => {
  const history = useHistory();
  const redirectToMainPage = () => {
    history.push('/');
  };
  return (
    <div className="my-content-empty">
      {WISHLIST_EMPTY_TEXT}
      <MainButton fontSize="12px" className="btn-pink-default" onClick={redirectToMainPage}>
        SHOP NOW
      </MainButton>
    </div>
  );
};
