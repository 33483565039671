import React from 'react';
import { Link } from 'react-router-dom';

import { getImagesUrl } from '../../../utils';
import locations from '../../../utils/locations';
import './Product.scss';
import { LIST_OF_CATEGORIES_WITH_LENGTH } from '../../../utils/constants';

interface IProductProps {
  primaryImageId: number;
  categoryId: number;
  productId: string;
  brand: string;
  model: string;
  color?: string;
  size?: number;
  quantity: number;
  price: number;
}

export const Product = ({
  primaryImageId,
  categoryId,
  productId,
  brand,
  model,
  color,
  size,
  quantity,
  price,
}: IProductProps) => {
  const image = getImagesUrl({
    id: primaryImageId,
  });

  const isLength = LIST_OF_CATEGORIES_WITH_LENGTH.includes(categoryId);

  return (
    <div className="order-details-product">
      <div
        className="product-img"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="product-desc">
        <Link to={locations.productPage(productId)}>
          <div className="model">
            {brand} {model}
          </div>
          <div className="color"> Color: {color}</div>
          <div className="size"> {`${isLength ? 'Length' : 'Size'}: ${size}`}</div>
          <div className="quantity"> Quantity: {quantity}</div>
          <div className="price">${price * quantity}</div>
        </Link>
      </div>
    </div>
  );
};
