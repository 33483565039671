import React, { ChangeEvent, Dispatch } from 'react';
import { Formik } from 'formik';
import { OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';

import { SimpleForm } from './SimpleForm/SimpleForm';
import {
  IEditProfileFormData,
  IEditProfileFormFields,
  IInitialErrors,
} from '../../TypesAndInterfaces/Interfaces';

interface ISimpleFormWrapperProps {
  fields: IEditProfileFormFields;
  initialValues: IEditProfileFormData;
  handleValueChange: (field: string) => (e: ChangeEvent<HTMLInputElement>) => void;
  setIsValidForm: Dispatch<React.SetStateAction<boolean>>;
  initialErrors?: IInitialErrors;
  showAllErrors?: boolean;
  textFieldClassName?: string;
  formControlClassName?: string;
  setErrorNotification?: Dispatch<React.SetStateAction<string>>;
  validationSchema: OptionalObjectSchema<
    {
      [x: string]: RequiredStringSchema<string | undefined, Record<string, any>>;
    },
    Record<string, any>,
    TypeOfShape<{
      [x: string]: RequiredStringSchema<string | undefined, Record<string, any>>;
    }>
  >;
}

const SimpleFormWrapper = ({
  fields,
  initialValues,
  handleValueChange,
  setIsValidForm,
  initialErrors,
  validationSchema,
  showAllErrors,
  textFieldClassName,
  formControlClassName,
  setErrorNotification = () => {},
}: ISimpleFormWrapperProps) => (
  <Formik
    validateOnMount={true}
    enableReinitialize={false}
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={() => {}}
  >
    {(props) => (
      <SimpleForm
        {...props}
        fields={fields}
        setIsValidForm={setIsValidForm}
        handleValueChange={handleValueChange}
        showAllErrors={showAllErrors}
        initialErrors={initialErrors}
        textFieldClassName={textFieldClassName}
        formControlClassName={formControlClassName}
        setErrorNotification={setErrorNotification}
      />
    )}
  </Formik>
);

export default SimpleFormWrapper;
