import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeWishlistItem } from '../../redux/actions/wishListItem/wishListItemAction';
import { getStoredUser, getStoredWishList } from '../../redux/reducers/selectors';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import HelpText from '../Account/Components/HelpText/HelpText';
import Title from '../Account/Components/Title';
import './MyWishlistPage.scss';
import { ModalWrapper } from '../../Components/ModalWrapper/ModalWrapper';
import { WishlistEmpty } from './WishlistEmpty/WishlistEmpty';
import { WishlistFilled } from './WishlistFilled/WishlistFilled';
import { ConfirmationPopup } from '../../Components/Popup/ConfirmationPopup';
import { getWishListPage } from '../../redux/actions/wishListItem/wishListItemAction';
import { MODAL_TEXT } from '../../utils/constants';
import { scrollToTop } from '../../utils/utils';

export const MyWishlistPage = () => {
  const user = useSelector(getStoredUser);
  const wishList = useSelector(getStoredWishList).wishListItems;
  const pageSize = useSelector(getStoredWishList).pageSize;
  const totalRecords = useSelector(getStoredWishList).totalRecords;
  const isLoading = useSelector(getStoredWishList).isLoading;
  const dispatch = useDispatch();
  const { userInfo } = user;
  const userInfoWishListItems = userInfo?.wishlistItems;
  const [page, setCurrentPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const [deleteId, setDeleteId] = useState(-1);

  const hasItems = wishList.length || 0;

  useEffect(() => {
    userInfo?.loggedWith && dispatch(getWishListPage(page));
    scrollToTop();
  }, [page, userInfoWishListItems]);

  useEffect(() => {
    if (wishList) {
      setMaxCount(Math.ceil(totalRecords / pageSize));
    }
  }, [wishList]);

  const onDeleteWishlist = async (id: number) => {
    dispatch(removeWishlistItem(id));
  };

  const handleChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const modalProps = {
    open: deleteId > -1,
    onClose: () => setDeleteId(-1),
    children: (
      <>
        <ConfirmationPopup
          title={MODAL_TEXT}
          isLoading={isLoading}
          handleClick={() => {
            onDeleteWishlist(deleteId);
            setDeleteId(-1);
          }}
          closeModal={() => setDeleteId(-1)}
          confirmText="Confirm"
          cancelText="Cancel"
        />
      </>
    ),
  };

  if (!userInfo?.loggedWith) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <main className="MyWishlistPage with-space-for-header">
      <div className="my-wishlist-head">
        <div className="head-content-wrapper">
          <Title title="My Wishlist" />
          <HelpText textAlign={'right'} />
        </div>
      </div>
      <div className="main-content">
        <Sidebar />
        <div className="content">
          {!hasItems && !userInfo.wishlistItems.length ? (
            <WishlistEmpty />
          ) : (
            <WishlistFilled
              onDelete={(value) => setDeleteId(value)}
              wishItems={wishList}
              maxCount={maxCount}
              handleChange={handleChange}
              onDeleteWishlist={onDeleteWishlist}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </div>
      {deleteId && <ModalWrapper {...modalProps} />}
    </main>
  );
};
