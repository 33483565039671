import { useSelector } from 'react-redux';

import { RootStoreType } from './../store';
import { IShippingReducer } from './shippingInfoReducer';
import { IWishListReducer } from './wishListItemReducer';

export const getStoredUser = (state: RootStoreType) => state.user;

export const getStoredCategories = (state: RootStoreType) => state.categories;

export const getStoredCountries = (state: RootStoreType) => state.countries;

export const getStoredShippingInfo = (state: RootStoreType): IShippingReducer => state.shippingInfo;

export const getStoredWishList = (state: RootStoreType): IWishListReducer => state.wishList;

export const getStoredProducts = (state: RootStoreType) => state.products;
