import { Pagination } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import { useState } from 'react';

import { getBoardByIdUrl, makeRequest, notificationTypes } from '../../../utils';
import { NotificationsContext, ShoppingCartContext } from '../../../contexts/contexts';
import {
  INotificationsContext,
  IProductDetails,
  IShoppingCartContext,
  IShoppingCartProduct,
  IWishlist,
} from '../../../TypesAndInterfaces/Interfaces';
import { WishlistWidget } from '../WishlistWidget/WishlistWidet';

interface IWishlistFilledProps {
  wishItems: IWishlist[];
  maxCount: number;
  onPageChange: (page: number) => void;
  handleChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onDelete: (id: number) => void;
  onDeleteWishlist: (id: number) => Promise<any>;
}

const ADD_SUCCESS = 'Item was added to shopping cart';

export const WishlistFilled = ({
  wishItems,
  handleChange,
  maxCount,
  onDelete,
  onDeleteWishlist,
  onPageChange,
}: IWishlistFilledProps) => {
  const { shoppingCartProducts, setShoppingCartProducts } = useContext<IShoppingCartContext>(
    ShoppingCartContext
  );
  const { notifications, setNotifications } = useContext<INotificationsContext>(
    NotificationsContext
  );
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (wishItems.length === 0) {
      onPageChange(1);
    }
  }, [wishItems]);

  const mapDetailsToSizes = (details: IProductDetails[]) =>
    details.map((item, index) => ({
      value: index,
      boardDetailId: item.id,
      size:
        item.productAttributes.length > 0
          ? item.productAttributes.find((o) => o.attributeName === 'Size')?.attributeValue
          : 'No info',
      quantity: item.quantity,
    }));

  const getProductIndex = (array: IShoppingCartProduct[], boardDetailId: number) =>
    array.findIndex((elem) => elem.boardDetailId === boardDetailId);

  const addToShipping = useCallback(async (id: number, wishlistId: number) => {
    setIsDisabled(true);
    const { data } = await makeRequest('get', getBoardByIdUrl({ id }));
    await onDeleteWishlist(wishlistId);
    setIsDisabled(false);
    if (data) {
      const { id, categoryId, price, model, brand, color, images } = data;

      const sizeList = mapDetailsToSizes(data.productDetails);
      const selectedIndex = 0;
      const selectedQuantity = 1;
      const boardDetailId = mapDetailsToSizes(data.productDetails)[0].boardDetailId;
      const quantityOnStock = data.productDetails.find(
        (elem: IProductDetails) => elem.id === sizeList[selectedIndex].boardDetailId
      )?.quantity;
      const productIndex = getProductIndex(shoppingCartProducts, boardDetailId);

      setShoppingCartProducts(
        productIndex === -1
          ? [
              ...shoppingCartProducts,
              {
                id,
                categoryId,
                color,
                price,
                model,
                brand,
                amount: selectedQuantity,
                size: sizeList[selectedIndex].size,
                boardDetailId,
                primaryImageId: images[findIndex(images, { isPrimary: true })]?.id,
                quantityOnStock: quantityOnStock || 0,
              },
            ]
          : shoppingCartProducts.map((elem) =>
              elem.boardDetailId === boardDetailId
                ? {
                    ...elem,
                    amount: elem.amount + selectedQuantity,
                  }
                : elem
            )
      );

      setNotifications([
        ...notifications,
        {
          type: notificationTypes.info,
          message: ADD_SUCCESS,
          id: Date.now(),
        },
      ]);
    }
  }, []);

  return (
    <>
      {wishItems.map((data) => (
        <WishlistWidget
          isDisabled={isDisabled}
          onDelete={() => {
            onDelete(data.id);
          }}
          onAddToShipping={addToShipping}
          {...data.productDetails.product}
          quantityIntoStock={data.productDetails.quantity}
          productDetailId={data.productDetails.id}
          wishlistId={data.id}
          creationDate={data.creationDate}
          key={data.id}
        />
      ))}
      <div className="my-wishlist-pagination">
        <Pagination count={maxCount} onChange={handleChange} />
      </div>
    </>
  );
};
