import React from 'react';
import './SignUpForm.scss';

const SignUpForm = () => (
  <form className="SignUpForm">
    <div className="InputOuter">
      <input type="email" placeholder="Your email address" />
    </div>
    <div className="SubmitOuter">
      <input type="submit" value="sign up" />
    </div>
  </form>
);

export default SignUpForm;
