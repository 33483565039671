import React from 'react';
import './CustomScrole.scss';
import { Scrollbars } from 'react-custom-scrollbars';

export const KIONScrollableContext = React.createContext();

export class KIONScrollable extends React.Component {
  constructor(props) {
    super(props);
    this.KIONScrollableRef = React.createRef();
  }

  scrollTop = (top) => {
    this.KIONScrollableRef.current.view.scroll({
      top,
      left: 0,
      behavior: 'smooth',
    });
  };

  renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      display: 'block',
      width: '40px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  render() {
    const { children, elementId } = this.props;

    return (
      <Scrollbars
        renderThumbVertical={this.renderThumb}
        hideTracksWhenNotNeeded={false}
        thumbSize={50}
        ref={this.KIONScrollableRef}
        id={elementId}
      >
        <KIONScrollableContext.Provider value={{ scrollTop: this.scrollTop }}>
          {children}
        </KIONScrollableContext.Provider>
      </Scrollbars>
    );
  }
}
