/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

import styles from './form.module.scss';

const Form = ({
  values,
  errors,
  isValid,
  fields,
  handleChange,
  validateForm,
  handleValueChange,
  setIsValidForm,
  touched,
  setFieldTouched,
  unitedStatesID,
}) => {
  useEffect(() => {
    setIsValidForm(isValid);
  }, [isValid, setIsValidForm]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const onChange = (name) => (e) => {
    e.persist();
    handleChange(e);
    handleValueChange(e.target.name)(e);
    validateForm();
  };

  const handleFocus = (name) => (e) => {
    setFieldTouched(name, false, false);
  };

  const handleBlur = (name) => (e) => {
    setFieldTouched(name, true, false);
  };

  return (
    <div className={styles.form}>
      <div className={styles.formField}>
        <span>
          <span className={styles.redAsterisk}>* </span>Address 1
        </span>
        <TextField
          name="address1"
          variant="outlined"
          defaultValue={values.address1}
          className={styles.address}
          error={touched['address1'] && !!errors.address1}
          helperText={touched['address1'] && errors.address1}
          onChange={onChange()}
          onFocus={handleFocus('address1')}
          onBlur={handleBlur('address1')}
        />
      </div>

      <div className={styles.formField}>
        <span>Address 2 (optional)</span>
        <TextField
          name="address2"
          variant="outlined"
          defaultValue={values.address2}
          className={styles.address}
          error={!!errors.address2}
          helperText={errors.address2}
          onChange={onChange()}
        />
      </div>

      <div className={styles.formField}>
        <span>
          <span className={styles.redAsterisk}>* </span>City
        </span>
        <TextField
          name="city"
          variant="outlined"
          defaultValue={values.city}
          className={styles.city}
          error={touched['city'] && !!errors.city}
          helperText={touched['city'] && errors.city}
          onChange={onChange()}
          onFocus={handleFocus('city')}
          onBlur={handleBlur('city')}
        />
      </div>

      <div className={styles.formField}>
        <span>
          <span className={styles.redAsterisk}>* </span>Zip Code
        </span>
        <TextField
          name="zipCode"
          variant="outlined"
          defaultValue={values.zipCode}
          className={styles.zipCode}
          error={touched['zipCode'] && !!errors.zipCode}
          helperText={touched['zipCode'] && errors.zipCode}
          onChange={onChange()}
          onFocus={handleFocus('zipCode')}
          onBlur={handleBlur('zipCode')}
        />
      </div>

      <div className={styles.countryAndState}>
        <div>
          <span>
            <span className={styles.redAsterisk}>* </span>Country
          </span>
          <TextField
            name="countryId"
            select
            className={styles.country}
            value={values.countryId ?? 'false'}
            variant="outlined"
            error={touched['countryId'] && !!errors.countryId}
            helperText={touched['countryId'] && errors.countryId}
            onChange={onChange()}
            onFocus={handleFocus('countryId')}
            onBlur={handleBlur('countryId')}
          >
            {fields.countryId.selectFrom.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <span>
            {values.countryId === unitedStatesID && <span className={styles.redAsterisk}>* </span>}
            State
          </span>
          <TextField
            name="state"
            select
            className={styles.state}
            variant="outlined"
            value={values.countryId === unitedStatesID ? values.state ?? false : ''}
            disabled={values.countryId !== unitedStatesID}
            error={touched['state'] && !!errors.state}
            helperText={touched['state'] && errors.state}
            onChange={onChange()}
            onFocus={handleFocus('state')}
            onBlur={handleBlur('state')}
          >
            {fields.state.selectFrom.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </div>
  );
};

export default Form;
