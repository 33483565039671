import React, { useState, useEffect, useRef, ReactElement, ChangeEvent, Ref } from 'react';
import axios from 'axios';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';

import Icon from '../Icon/Icon';
import PopperWrapper from '../PopperWrapper/PopperWrapper';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import { debounceFunction } from '../../utils';
import { getProductsUrl, getCategoriesUrl } from '../../utils';
import './SearchComponent.scss';
import { IProductData } from '../../TypesAndInterfaces/Interfaces';

const debounceInput = debounceFunction(300);

export interface ICategory {
  id: number;
  name: string;
  parentCategoryId: number | null;
}

const SearchComponent = (): ReactElement => {
  const searchInputRef: Ref<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const searchComponentRef: Ref<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const [productList, setProductList] = useState<IProductData[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const [inputCurrentValue, setInputCurrentValue] = useState('');
  const [inputDebouncedValue, setInputDebouncedValue] = useState(inputCurrentValue);

  const handleLocationChange = () => {
    setIsInputVisible(false);
    setInputDebouncedValue('');
    setInputCurrentValue('');
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputCurrentValue(value);
    debounceInput(setInputDebouncedValue, value.trim());
  };

  const handleContentVisibility = ({ isOnFocus = false, isOnBlur = false } = {}) => () => {
    if (isOnFocus) {
      setIsInputFocused(true);
    } else if (isOnBlur && inputCurrentValue) {
      setIsInputFocused(false);
    } else if (isOnBlur) {
      setIsInputFocused(false);
      setIsInputVisible(false);
    } else if (!isOnBlur && !isInputVisible) {
      setIsInputVisible(true);
      setIsInputFocused(true);
      setInputCurrentValue('');
      setInputDebouncedValue('');
      searchInputRef.current?.focus();
    }
  };

  useEffect(() => {
    if (inputDebouncedValue.length >= 1) {
      const URL = getProductsUrl({
        parameters: { Search: inputDebouncedValue },
      });

      (async () => {
        const { data } = await axios.post(URL);
        const productList = data.data.filter((product: IProductData) => product.isPubliclyVisible);
        setProductList(productList);
      })();

      axios.get(getCategoriesUrl()).then((resp) => {
        const res = resp.data.filter(
          (item: ICategory) => item.name.search(new RegExp(`${inputDebouncedValue}`, 'i')) !== -1
        );

        setCategoryList(res);
      });
    }
  }, [inputDebouncedValue]);

  return (
    <div className="SearchComponent">
      <InputBase
        className={isInputVisible ? 'activeInput' : 'input'}
        ref={searchComponentRef}
        inputRef={searchInputRef}
        value={inputCurrentValue}
        onBlur={handleContentVisibility({ isOnBlur: true })}
        onChange={handleInput}
        onFocus={handleContentVisibility({ isOnFocus: true })}
        endAdornment={
          <InputAdornment position="start" onClick={handleContentVisibility()}>
            <Icon className="NavigationIcon" icon="search" />
          </InputAdornment>
        }
      />
      {inputDebouncedValue.length >= 2 && (
        <PopperWrapper
          anchorEl={searchComponentRef.current}
          placement="bottom-end"
          className="SearchSuggestions"
          isOpenBooleans={[isInputFocused]}
          handleLocationChange={handleLocationChange}
        >
          <SearchSuggestions
            inputDebouncedValue={inputDebouncedValue}
            productList={productList}
            categoryList={categoryList}
          />
        </PopperWrapper>
      )}
    </div>
  );
};

export default SearchComponent;
