import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';

import { getStoredUser, getStoredCountries } from '../../../redux/reducers/selectors';
import { deleteUserSavedAddress } from '../../../redux/actions/user/userActions';
import { EditAddressForm } from './Forms/EditAddressForm';
import { ModalWrapper } from '../../../Components/ModalWrapper/ModalWrapper';
import { ConfirmationPopup } from '../../../Components/Popup/ConfirmationPopup';
import { IAddress, IAddressToDelete } from '../../../TypesAndInterfaces/Interfaces';
import './SavedAddress.scss';
import { MainButton } from '../../../Components/Buttons/MainButton';

interface ISavedAddressProps {
  setShowResultPopup: (value: boolean) => void;
}

interface IFormData {
  address1: string;
  address2: string;
  city: string;
  zipCode: string;
  countryId: number;
  state: string;
}

export const SavedAddress = ({ setShowResultPopup }: ISavedAddressProps) => {
  const { userInfo, savedAddresses } = useSelector(getStoredUser);
  const { countries } = useSelector(getStoredCountries);

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<IFormData | {}>({});
  const [editAddressID, setEditAddressID] = useState<string | null>(null);
  const [addressToDelete, setAddressToDelete] = useState<IAddressToDelete>({ index: -1, id: '' });

  const handleDelete = async () => {
    setIsLoading(true);

    dispatch(deleteUserSavedAddress({ customerId: userInfo.id, addressId: addressToDelete.id }));
    setShowResultPopup(true);

    setIsLoading(false);
    setIsDeleteModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleAddBtnClick = () => {
    setIsEdit(false);
    setFormData({});
    setEditAddressID(null);
    setIsModalOpen(true);
  };

  const handleEditBtnClick = (elem: IAddress) => {
    const { address1, address2, city, zipCode, countryId, state } = elem;
    setIsEdit(true);
    setFormData({
      address1,
      address2,
      city,
      zipCode,
      countryId,
      state,
    });
    setEditAddressID(elem.id);
    setIsModalOpen(true);
  };

  const handleDeleteBtnClick = (addressToDelete: IAddressToDelete) => {
    setAddressToDelete(addressToDelete);
    setIsDeleteModalOpen(true);
  };

  let modalProps = {
    open: false,
    onClose: () => {},
    children: <></>, // because of restrictions of Modal of material-ui package
  };

  if (isModalOpen) {
    modalProps = {
      open: isModalOpen,
      onClose: closeModal,
      children: (
        <>
          <EditAddressForm
            isEdit={isEdit}
            formData={formData}
            setFormData={setFormData}
            editAddressID={editAddressID}
            closeModal={closeModal}
            setShowResultPopup={setShowResultPopup}
          />
        </>
      ),
    };
  }

  if (isDeleteModalOpen) {
    modalProps = {
      open: isDeleteModalOpen,
      onClose: closeDeleteModal,
      children: (
        <>
          <ConfirmationPopup
            closeModal={closeDeleteModal}
            handleClick={handleDelete}
            isLoading={isLoading}
            title={'Confirm the deletion of address?'}
            confirmText={'confirm'}
            cancelText={'cancel'}
          />
        </>
      ),
    };
  }

  return (
    <div className="saved-address">
      <div className="saved-address-header">
        <p>Saved addresses</p>
        {savedAddresses.length < 3 && (
          <span className="span-as-link" onClick={handleAddBtnClick}>
            Add
          </span>
        )}
      </div>
      <div className="saved-address-content">
        {savedAddresses &&
          savedAddresses.map((elem: IAddress, index: number) => (
            <div key={index} className="address-profile">
              <div className="address">
                <div>
                  <b>Address {index + 1}</b>
                </div>
                <div className="address-content">
                  <div>{elem.address2 ? `${elem.address1}, ${elem.address2}` : elem.address1}</div>
                  <div>{`${elem.zipCode}, ${elem.city}, ${elem.state ? elem.state + ', ' : ''}${
                    countries[elem.countryId]?.name
                  }`}</div>
                </div>
              </div>
              <div className="buttons">
                <MainButton
                  data-testid={`address${index + 1}-edit-btn`}
                  className="btn-pink-default"
                  onClick={() => handleEditBtnClick(elem)}
                >
                  Edit
                </MainButton>
                <MainButton
                  data-testid={`address${index + 1}-delete-btn`}
                  className="btn-pink-default cancel"
                  onClick={() => handleDeleteBtnClick({ index, id: elem.id })}
                >
                  Delete
                </MainButton>
              </div>
            </div>
          ))}
      </div>

      {(isModalOpen || isDeleteModalOpen) && <ModalWrapper {...modalProps} />}
    </div>
  );
};
