import React from 'react';
import './PageNotFound.scss';

const PageNotFound = () => (
  <div className="page-not-found-wrapper with-space-for-header">
    <h3>Whoops, page not found.</h3>
    <h4 style={{ fontSize: '200px' }}>404</h4>
  </div>
);

export default PageNotFound;
