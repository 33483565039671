import React from 'react';

import './ProductDetails.scss';
import ProductDetailsImg from '../../../../assets/images/details-img.png';
import { MainButton } from '../../../../Components/Buttons/MainButton';

// TO BE REMOVED LATER
const SOME_HARDCODED_TEXT = `It’s mostly green and a little dark. Every aspect of the board we
were able to inject green into we did. Recycled content sidewalls,
eco sublimated Bio Polymer BEANS top material, eco sublimated TNT
base, sustainably harvested renewable wood core materials and
Central American avocado inspired graphics. Made at our own Mervin
MFG facility in the “Evergreen State” of Washington, with zero
hazardous waste and no toxic solvents.`;

const ProductDetails = ({ description }: { description: string }) => (
  <section className="ProductDetails">
    <div className="ProductDetailsContent">
      <div className="ProductDetailsImg">
        <img src={ProductDetailsImg} alt="" />
      </div>
      <div className="ProductDetailsDesc">
        <h2>Details</h2>
        <div className="ProductDetailsBg">
          <div className="ProductDetailsText">
            <p>{description || SOME_HARDCODED_TEXT}</p>
          </div>
        </div>
        <MainButton textColor="#fff" fontSize="18px" ProductImg="/images/rectangle-small.png">
          Read More
        </MainButton>
      </div>
    </div>
  </section>
);

export default ProductDetails;
