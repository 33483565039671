import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

import NoMatchComponent from '../../Components/NoMatchComponent/NoMatchComponent';
import { SimpleBreadcrumbs } from '../../Components/Breadcrumbs/SimpleBreadcrumbs';
import ProductWrapper from '../../Components/ProductWrapper/ProductWrapper';
import { Product } from '../../Components/Product/Product';
import { getProductsUrl } from '../../utils/endpoints';
import {
  subCategories,
  genders,
  imageSizes,
  sliderSettings,
  stateStatuses,
} from '../../utils/constants';
import { ICategory, IProduct } from '../../TypesAndInterfaces/Interfaces';
import { getStoredCategories } from '../../redux/reducers/selectors';
import {
  makeRequest,
  REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER,
  REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER,
} from '../../utils/utils';
import locations from '../../utils/locations';

import './CategoryPage.scss';

const CategoryPage = () => {
  const { categories, isError } = useSelector(getStoredCategories);
  const [productList, setProductList] = useState([]);
  const [pageStatus, setPageStatus] = useState(stateStatuses.loading);
  const { sectionName, categoryName } = useParams<{ sectionName: string; categoryName: string }>();

  // This category search is temporary until new page content according to the new design will be implemented.
  const category: ICategory | undefined = categories.find((category: ICategory) => {
    if (categoryName === 'Snowboarding') {
      return category.name === 'Snowboards';
    } else {
      return category.name === categoryName;
    }
  });

  let reqBody = {};

  Object.values(genders).map((gender) => {
    if (sectionName === gender) {
      reqBody = { Gender: gender.toString() };
    }
  });

  useEffect(() => {
    (async () => {
      if (category) {
        try {
          const { data } = await makeRequest(
            'get',
            getProductsUrl({ id: category!.id, parameters: reqBody })
          );
          setProductList(data.data);
          setPageStatus(stateStatuses.success);
        } catch (err) {
          setPageStatus(stateStatuses.failure);
        }
      } else if (isError) {
        setPageStatus(stateStatuses.failure);
        setProductList([]);
      }
    })();
  }, [category, isError, sectionName]);

  const settings = { ...sliderSettings, slidesToShow: 6 };

  return (
    <main className="category-page-wrapper">
      <h1 className="category-page-title">{categoryName}</h1>
      <SimpleBreadcrumbs sectionName={sectionName!} categoryName={categoryName} />
      <div className="category-page-content">
        <div className="category-page-links-container">
          {Object.entries(subCategories[sectionName!]![categoryName!]).map(
            (subCategory, index: number) => (
              <Link
                key={index}
                to={locations.subCategoryPage(sectionName!, categoryName!, subCategory[0])}
                className="category-page-link"
              >
                <span>{subCategory[1].title}</span>
              </Link>
            )
          )}
        </div>
        <h2 className="category-page-subtitle">Products</h2>
        {pageStatus === stateStatuses.loading && REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER}
        {pageStatus === stateStatuses.failure && REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER}
        {!productList.length && pageStatus === stateStatuses.success && (
          <NoMatchComponent message="No items match the current category" />
        )}
        <div className="category-page-slider">
          {productList.length < 6 ? (
            productList.map((item: IProduct) => (
              <ProductWrapper
                key={item.id}
                product={
                  <Product
                    id={item.id}
                    img={item.primaryImageId}
                    price={item.price}
                    brand={item.brand}
                    model={item.model}
                    productList={productList}
                    imageSize={imageSizes['200x350']}
                  />
                }
              />
            ))
          ) : (
            <Slider {...settings}>
              {productList.map((item: IProduct) => (
                <ProductWrapper
                  key={item.id}
                  product={
                    <Product
                      id={item.id}
                      img={item.primaryImageId}
                      price={item.price}
                      brand={item.brand}
                      model={item.model}
                      productList={productList}
                      imageSize={imageSizes['200x350']}
                    />
                  }
                />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </main>
  );
};

export default CategoryPage;
