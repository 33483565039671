import { useEffect } from 'react';

const useLocalStorage = (key, localStorageState, setLocalStorageState) => {
  useEffect(() => {
    const localValue = localStorage.getItem(key);
    setLocalStorageState((state) => {
      if (localValue && state !== localValue) {
        const parsedLocalValue = JSON.parse(localValue);
        if (state !== parsedLocalValue) {
          return parsedLocalValue;
        }
      }
      return state;
    });
  }, [key, setLocalStorageState]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(localStorageState));
  }, [localStorageState, key]);
};

export default useLocalStorage;
