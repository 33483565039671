import React, { useState, useEffect, memo } from 'react';
import './Boards.scss';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';

import { Product } from '../../../../Components/Product/Product';
import CardWrapper from '../../../../Components/CardWrapper/CardWrapper';
import ProductWrapper from '../../../../Components/ProductWrapper/ProductWrapper';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import CrossTriangle from '../../../../assets/images/cross-triangle.png';
import BigCross from '../../../../assets/images/big-horizontal-cross.png';
import { imageSizes, sliderSettings } from '../../../../utils/constants';
import { PRODUCT_API } from '../../../../services/product.services';
import locations from '../../../../utils/locations';

// eslint-disable-next-line react/display-name
const Products = memo((_props) => {
  const [data, setData] = useState([]);
  const history = useHistory();

  const redirectToProducts = () => {
    history.push(locations.products);
  };

  useEffect(() => {
    (async () => {
      const { data, err } = await PRODUCT_API.getLatestBoards();
      err ? console.error(err) : setData(data);
    })();
  }, []);

  return (
    <section className="Boards">
      <CardWrapper>
        <div className="BoardsHolder">
          {data.length ? (
            <Slider {...sliderSettings}>
              {/* {error && <div>{error}</div>} TODO */}

              {data.map((item, index) => {
                const { id, sizes, primaryImageId, brand, model, price, discountPrice } = item;

                return (
                  <ProductWrapper
                    index={index}
                    key={index}
                    sizes={sizes}
                    product={
                      <Product
                        id={id}
                        img={primaryImageId}
                        brand={brand}
                        model={model}
                        price={price}
                        discountPrice={`$ ${discountPrice}`}
                        imageSize={imageSizes['200x350']}
                      />
                    }
                  />
                );
              })}
            </Slider>
          ) : null}
        </div>
      </CardWrapper>
      <MainButton
        textColor="#ffffff"
        fontSize="25px"
        ProductImg="images/rectangle-pink.png"
        onClick={redirectToProducts}
      >
        View All Boards
      </MainButton>
      <img className="CrossTriangle" src={CrossTriangle} alt="" />
      <img className="BigCross" src={BigCross} alt="" />
    </section>
  );
});

export default Products;
