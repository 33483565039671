import React from 'react';
import { Link } from 'react-router-dom';

import './ProductNotFound.scss';
import locations from '../../../../utils/locations';

export const ProductNotFound = () => {
  return (
    <div className="product-not-found-wrapper with-space-for-header">
      <h3>
        Sorry, this product is not available. Return to the{' '}
        {<Link to={locations.homepage}> Home page </Link>}.
      </h3>
      <h4 className="product-not-found-wrapper-numbers">404</h4>
    </div>
  );
};
