import { IGetUrlWithRouteProps } from './endpoints';

interface IObjectToQueryParametrsProps {
  parameters: object;
  defaultParameters: {
    [key: string]: string;
  };
  disjoinWithComma?: boolean;
}

export const mapObjectToQueryParametersString = ({
  parameters,
  defaultParameters = {},
  disjoinWithComma = false,
}: IObjectToQueryParametrsProps) => {
  const disjoinedParameters = Object.entries(parameters).filter(
    ([, item]) => item && item.isJoined
  );

  const normalizedParameters = disjoinWithComma
    ? disjoinedParameters.map(([key, item]) => `${key}=${item.values.join(',')}`)
    : disjoinedParameters.map(([key, item]) =>
        [...item.values.map((val: string) => new URLSearchParams([[key, val]]).toString())].join(
          '&'
        )
      );

  const regularParameters = Object.entries(parameters).filter(
    ([key, value]) => value !== defaultParameters[key] && !value.isJoined
  );

  const joinedParameters = regularParameters
    .map(([key, value]) =>
      typeof value === 'object'
        ? Object.entries(value)
            .map((val: any) => `${key}=${encodeURIComponent(val[1])}`)
            .join('&')
        : `${key}=${encodeURIComponent(value)}`
    )
    .join('&');

  let res = `${new URLSearchParams(joinedParameters)}`;

  if (normalizedParameters.length > 0) {
    res += `&${normalizedParameters.join('&')}`;
  }
  return res;
};

export const getUrl = ({
  route,
  id,
  parameters,
  defaultParameters,
}: IGetUrlWithRouteProps): string => {
  if (!parameters && !id) return route;
  if (!parameters) return `${route}/${id}`;

  const query = mapObjectToQueryParametersString({
    parameters,
    defaultParameters,
  });
  return id ? `${route}/${id}?${query}` : `${route}?${query}`;
};
