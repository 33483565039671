import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Product } from '../Product/Product';
import { MainButton } from '../Buttons/MainButton';
import { imageSizes } from '../../utils';
import locations from '../../utils/locations';
import './SearchSuggestions.scss';

const SearchSuggestions = ({ inputDebouncedValue, productList, categoryList }) => (
  <Fragment>
    <div className="SearchSuggestionsTitle">Search for {`"${inputDebouncedValue}"`}</div>
    {productList.length ? (
      <div className="SearchSuggestionsContent">
        <div className="SearchSuggestionsTags">
          <div className="SearchSuggestionsResultsTitle">Suggestions</div>
          <ul className="SearchSuggestionsTagsList">
            {categoryList.map((item, index) => (
              <li key={index} className="SearchSuggestionsTag">
                <Link
                  // Link has to redirect User to category / sub-category page. At that moment, categories page isn't implemented.
                  // So pathname mentioned as /products as temporary solution.
                  to={{
                    pathname: locations.products,
                  }}
                  className="SearchSuggestionsTagLink"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="SearchSuggestionsResults">
          <div className="SearchSuggestionsResultsTitle">Products</div>
          <ul className="SearchSuggestionsProductList">
            {productList.slice(0, 4).map((item, index) => (
              <li key={item.id} className="SearchSuggestionsProduct">
                <Product
                  id={item.id}
                  img={item.primaryImageId}
                  brand={item.brand}
                  model={item.model}
                  price={item.price}
                  oldPrice={item.oldPrice}
                  imageSize={imageSizes['120x160']}
                />
              </li>
            ))}
          </ul>

          <MainButton textColor="#fff" fontSize="16px" ProductImg="images/rectangle-small.png">
            <Link to="/products">
              <p className="ViewAll">view all</p>
            </Link>
          </MainButton>
        </div>
      </div>
    ) : (
      <div className="NoProducts">No products found!</div>
    )}
  </Fragment>
);

export default SearchSuggestions;
