import React from 'react';

import {
  ACCESS_TOKEN,
  clearLoginProvider,
  clearTokens,
  facebookAccessToken,
  REFRESH_TOKEN,
  SHIPPING_INFO_LS,
  USER_DATA_LS,
  SHOPPING_CART,
} from './index';
import { clearUserInfo } from '../redux/actions/user/userActions';
import { clearShippingInfo } from '../redux/actions/shippingInfo/shippingInfoActions';

export const logout = (dispatch: React.Dispatch<any>) => {
  clearTokens();
  clearLoginProvider();
  localStorage.removeItem(USER_DATA_LS);
  localStorage.removeItem(SHIPPING_INFO_LS);
  localStorage.removeItem(facebookAccessToken);
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(SHOPPING_CART);
  dispatch(clearUserInfo());
  dispatch(clearShippingInfo());
};
