import React from 'react';
import type { TextAlignProperty } from 'csstype';
import './HelpText.scss';

interface IHelpTextProps {
  split?: boolean;
  textAlign: TextAlignProperty;
}

const HelpText = ({ split, textAlign }: IHelpTextProps) => {
  return (
    <div className="account-help-text" style={{ textAlign: textAlign }}>
      Need help? {split && <br />} Call{' '}
      <a href="tel:800911-9191">
        <b>(800) 911-9191</b>
      </a>{' '}
      in Los Angeles, USA
      <br />
      <span className="account-head-info-sub">Monday - Friday, 8am - 6pm EST</span>
    </div>
  );
};

export default HelpText;
