import React, { MouseEventHandler, ReactNode } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Buttons.scss';

interface IMainButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  textColor?: string;
  fontSize?: string;
  children?: ReactNode;
  ProductImg?: string;
  onClick?: MouseEventHandler;
  id?: string;
  className?: string;
  soldOut?: boolean;
}

export const MainButton = ({
  isDisabled = false,
  isLoading = false,
  textColor,
  fontSize,
  children,
  ProductImg,
  onClick,
  id,
  className = 'MainButton',
}: IMainButtonProps) => {
  return (
    <button
      className={className}
      id={id}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      style={{ color: textColor, fontSize }}
    >
      {isLoading ? (
        <>
          <span className="title">{children}</span>
          <CircularProgress size={20} style={{ color: '#fff' }} />
        </>
      ) : (
        children
      )}
      <img src={ProductImg} alt="" />
    </button>
  );
};
