import React, { useState, useEffect } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { colorsList, filtersList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '../SelectsStyle.scss';

const SelectColor = ({ filters, clearFilters, handleFiltersChange }: IFiltersProps) => {
  const [chosenColors, setChosenColors] = useState<string[]>([]);

  const colorsSelectOptions = colorsList.map((color: string, index) => (
    <MenuItem key={index} value={color}>
      <Checkbox checked={chosenColors.indexOf(color) > -1} />
      <ListItemText primary={color} />
    </MenuItem>
  ));

  // { value: unknown } is used because if material-ui types documentation; if it is not used there will be TS error.
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    setChosenColors(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    const colorsReq = {
      [filtersList.Color]: chosenColors,
    };
    handleFiltersChange({ ...filters, ...colorsReq });
  }, [chosenColors]);

  useEffect(() => {
    if (clearFilters) {
      setChosenColors([]);
    }
  }, [clearFilters]);

  return (
    <div className="SelectLeftWrapper">
      <div className="SelectCenterContainer">
        <div className="SelectTitle">Color:</div>
        <FormControl variant="outlined">
          <Select
            value={chosenColors}
            input={<OutlinedInput />}
            multiple
            displayEmpty
            onChange={(e: React.ChangeEvent<{ value: unknown }>): void => handleChange(e)}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <span>All</span>;
              }
              if ((selected as string[]).length === colorsList.length) {
                return <span>All</span>;
              }
              return (selected as string[]).join(', ');
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {colorsSelectOptions}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectColor;
