import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import findIndex from 'lodash/findIndex';
import range from 'lodash/range';

import { NotificationsContext, ShoppingCartContext } from '../../../../contexts/contexts';
import { notificationTypes } from '../../../../utils';
import SoldOutLabel from '../../../../Components/SoldOutLabel/SoldOutLabel';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import {
  IMappedDetails,
  INotificationsContext,
  IProductData,
  IShoppingCartContext,
  IShoppingCartProduct,
  ISizes,
  IWishlist,
  ICategory,
} from '../../../../TypesAndInterfaces/Interfaces';
import { isObject } from '../../../../TypesAndInterfaces/TypeGuards';
import { AddToWishList } from '../../../../Components/AddToWishList/AddToWishList';
import { getStoredUser, getStoredCategories } from '../../../../redux/reducers/selectors';
import {
  addToWishlistItem,
  removeWishlistItem,
} from '../../../../redux/actions/wishListItem/wishListItemAction';

import './SelectsProduct.scss';

interface ISelectsProductProps {
  sizes: ISizes;
  setSizes: Dispatch<SetStateAction<ISizes | null>>;
  info: IProductData;
}

const ADD_SUCCESS = 'Item was added to shopping cart.';
const ADD_WISHLIST_ITEM_SUCCESS = 'Item is added to your wishlist.';
const NO_AUTH_ADD_TO_WISHLIST = 'Please sign in to add the product to your wishlist.';

const mapSizesToOptions = (sizes: IMappedDetails[]) =>
  sizes.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.size}
    </MenuItem>
  ));

const getProductIndex = (array: IShoppingCartProduct[], boardDetailId: number) =>
  array.findIndex((elem) => elem.boardDetailId === boardDetailId);

const findWishedItem = (wishlistItems: IWishlist[], selectedSizeItem?: IMappedDetails) => {
  return wishlistItems.find(
    (wishlist: IWishlist) => wishlist.productDetailId === selectedSizeItem?.boardDetailId
  );
};

const SelectsProduct = ({ sizes, setSizes, info }: ISelectsProductProps) => {
  const { categories } = useSelector(getStoredCategories);
  const { shoppingCartProducts, setShoppingCartProducts } = useContext<IShoppingCartContext>(
    ShoppingCartContext
  );
  const { notifications, setNotifications } = useContext<INotificationsContext>(
    NotificationsContext
  );

  const snowboardsCategory: ICategory | undefined = categories?.find(
    (category: ICategory) => category.name === 'Snowboards'
  );

  const dispatch = useDispatch();

  const user = useSelector(getStoredUser);

  const selectedSize = sizes.sizesList.find((size) => {
    return size.value === sizes.selectedIndex;
  });

  const productInCart = shoppingCartProducts.find(
    (elem) => elem.boardDetailId === selectedSize?.boardDetailId
  );
  let quantityInStock = selectedSize?.quantity;

  const soldOut = productInCart?.amount
    ? productInCart.quantityOnStock - productInCart.amount < 1
    : isObject(selectedSize) && selectedSize.quantity < 1;
  quantityInStock = useMemo(
    () =>
      info.productDetails.find(
        (elem) => elem.id === sizes.sizesList[sizes.selectedIndex].boardDetailId
      )?.quantity,
    []
  );

  const handleSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSizes({
      ...sizes,
      selectedIndex: parseInt(event.target.value),
      selectedQuantity: 1,
      boardDetailId: sizes.sizesList[parseInt(event.target.value)].boardDetailId,
    });
  };

  const handleQuantityChange = (buttonClickResult: number) => {
    setSizes({
      ...sizes,
      selectedQuantity: sizes.selectedQuantity + buttonClickResult,
    });
  };

  const handleAddClick = () => {
    const { sizesList, selectedIndex, selectedQuantity, boardDetailId } = sizes;
    const { id, price, model, brand, images, categoryId } = info;
    //this is made because color property is hidden deeply in the info object
    const color = info.productDetails[0]?.productAttributes?.find(
      (attr) => attr.attributeName === 'Color'
    )?.attributeValue;
    const productIndex = getProductIndex(shoppingCartProducts, boardDetailId);
    const quantityOnStock = info.productDetails.find(
      (elem) => elem.id === sizesList[selectedIndex].boardDetailId
    )?.quantity;

    setSizes((sizes) =>
      sizes
        ? {
            ...sizes,
            selectedQuantity: 1,
            sizesList: sizes.sizesList.map((elem) =>
              elem.boardDetailId === boardDetailId
                ? {
                    ...elem,
                    quantity: elem.quantity - selectedQuantity,
                  }
                : elem
            ),
          }
        : null
    );

    setShoppingCartProducts(
      productIndex === -1
        ? [
            ...shoppingCartProducts,
            {
              id,
              categoryId,
              color,
              price,
              model,
              brand,
              amount: selectedQuantity,
              size: sizesList[selectedIndex].size,
              boardDetailId,
              primaryImageId: images[findIndex(images, { isPrimary: true })]?.id,
              quantityOnStock: quantityOnStock || 0,
            },
          ]
        : shoppingCartProducts.map((elem) =>
            elem.boardDetailId === boardDetailId
              ? {
                  ...elem,
                  color,
                  amount: elem.amount + selectedQuantity,
                }
              : elem
          )
    );

    setNotifications([
      ...notifications,
      {
        type: notificationTypes.info,
        message: ADD_SUCCESS,
        id: Date.now(),
      },
    ]);
  };

  if (!sizes || !sizes.sizesList) return null;

  const [isAdded, setIsAdded] = useState(false);

  const [isLogged, setIsLogged] = useState(true);

  useEffect(() => {
    if (
      user.userInfo &&
      Object.keys(user.userInfo).length === 0 &&
      Object.getPrototypeOf(user.userInfo) === Object.prototype
    ) {
      setIsLogged(false);
    }
  }, [user]);

  useEffect(() => {
    if (user.userInfo.wishlistItems) {
      const isFound = findWishedItem(user.userInfo.wishlistItems, selectedSize) !== undefined;
      setIsAdded(isFound);
    }
  }, [isAdded, user, selectedSize]);

  const onAddToWish = () => {
    if (!isLogged) {
      setNotifications([
        ...notifications,
        {
          type: notificationTypes.info,
          message: NO_AUTH_ADD_TO_WISHLIST,
          id: Date.now(),
        },
      ]);
      return;
    }

    if (isAdded) {
      const result = findWishedItem(user.userInfo?.wishlistItems, selectedSize);

      if (result) {
        const itemIndex = user.userInfo.wishlistItems.findIndex(
          (data: IWishlist) => data.id === result.id
        );
        user.userInfo.wishlistItems.splice(itemIndex, 1);
      }
      result && dispatch(removeWishlistItem(result.id));
    } else {
      const foundProduct = info.productDetails.find(
        (data) => data.id === selectedSize?.boardDetailId
      );

      foundProduct && dispatch(addToWishlistItem(user.userInfo.id, foundProduct.id, 1));

      setNotifications([
        ...notifications,
        {
          type: notificationTypes.info,
          message: ADD_WISHLIST_ITEM_SUCCESS,
          id: Date.now(),
        },
      ]);
    }
  };

  return (
    <div className="SelectsProduct">
      <div className="SelectProductSize">
        <div className="SelectTitle">Quantity</div>
        <div className="SelectsProductButtonsWrapper">
          <div className="SelectsProductButtons">
            <button
              className="SelectsProductButtons_Btn-Minus"
              disabled={sizes.selectedQuantity <= 1}
              onClick={() => handleQuantityChange(-1)}
            >
              -
            </button>
            <span className="SelectsProductAmount">{sizes.selectedQuantity}</span>
            <button
              className="SelectsProductButtons_Btn-Plus"
              disabled={sizes.selectedQuantity === quantityInStock}
              onClick={() => handleQuantityChange(1)}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="SelectProductQuantity">
        <div className="SelectTitle">
          {snowboardsCategory && info.categoryId === snowboardsCategory['id'] ? 'Length' : 'Size'}
        </div>
        <FormControl variant="outlined">
          <TextField
            select
            InputLabelProps={{ shrink: false }}
            variant="outlined"
            disabled={sizes.sizesList.length <= 1}
            value={sizes.selectedIndex}
            onChange={handleSizeChange}
          >
            {mapSizesToOptions(sizes.sizesList)}
          </TextField>
        </FormControl>
      </div>
      {<div className="OnlyNItemsLeft">Only {quantityInStock} items left</div>}
      {quantityInStock && quantityInStock === 1 && (
        <div className="OnlyNItemsLeft">Only 1 item left</div>
      )}
      <div className="SelectProductInteraction">
        {soldOut ? (
          <SoldOutLabel />
        ) : (
          <MainButton
            isDisabled={!sizes.sizesList[sizes.selectedIndex].quantity}
            textColor="#fff"
            fontSize="24px"
            onClick={handleAddClick}
            soldOut={soldOut}
          >
            ADD TO CART
          </MainButton>
        )}
        <AddToWishList isAdded={isAdded} onClick={onAddToWish} />
      </div>
    </div>
  );
};

export default SelectsProduct;
