import React from 'react';
import { Modal, ModalProps } from '@material-ui/core';

export const ModalWrapper = ({ open, onClose, children }: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose} style={{ overflow: 'auto' }}>
      {children}
    </Modal>
  );
};
