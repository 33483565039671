import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import identity from 'lodash/identity';

import { LOGIN_PROVIDER } from '../utils/constants';
import {
  getTokens,
  setTokens,
  onRequestInterceptor,
  clearTokens,
  clearLoginProvider,
} from '../utils/utils';
import { getRefreshUrl } from '../utils/endpoints';
import { getUserInfo, clearUserInfo } from '../redux/actions/user/userActions';

const useUserInfo = (setNotifications) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const loginProvider = localStorage.getItem(LOGIN_PROVIDER);
    const [accessToken, refreshToken] = getTokens();

    const onFailedAutoLogin = () => {
      if (!accessToken || !refreshToken) {
        clearTokens();
      }
      clearLoginProvider();
      dispatch(clearUserInfo());
    };

    const refresh = async (originalRequest, accessToken, refreshToken) => {
      return await axios
        .post(getRefreshUrl(), {
          token: accessToken,
          refreshToken,
        })
        .then(async ({ data }) => {
          setTokens(data.token, data.refreshToken);

          return axios(originalRequest);
        });
    };

    const onRejectInterceptor = async (error, onFailedAutoLogin) => {
      if (axios.isCancel(error) || !error.response || error.config._retry)
        return Promise.reject(error);
      if (error.response.status === 401) {
        let response;
        const originalRequest = error.config;
        const [accessToken, refreshToken] = getTokens();
        if (
          originalRequest.url !== getRefreshUrl() &&
          accessToken &&
          refreshToken &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          await refresh(originalRequest, accessToken, refreshToken)
            .then((resp) => {
              response = resp;
            })
            .catch(onFailedAutoLogin);
        }

        return response || Promise.reject(error);
      }

      if (error.response?.data?.message) {
        // TODO Handle errors and define which error should be display in UI
        // setNotifications((notifications) => [
        //   ...notifications,
        //   {
        //     type: notificationTypes.error,
        //     message: error.response.data?.message,
        //     id: Date.now(),
        //   },
        // ]);
      }

      return Promise.reject(error);
    };

    const auth = ({ accessToken, refreshToken, loginProvider, onFailedAutoLogin }) => {
      accessToken || refreshToken ? dispatch(getUserInfo(loginProvider)) : onFailedAutoLogin();
    };

    axios.interceptors.request.use(onRequestInterceptor);
    axios.interceptors.response.use(identity, (error) => {
      return onRejectInterceptor(error, onFailedAutoLogin);
    });
    auth({ accessToken, refreshToken, loginProvider, onFailedAutoLogin });
  }, [dispatch, setNotifications]);
};

export default useUserInfo;
