import React from 'react';
import { Link } from 'react-router-dom';

import './BoardArrivals.scss';
import CardWrapper from '../../../../Components/CardWrapper/CardWrapper';
import Text from '../../../../assets/images/text.png';
import BoardMen from '../../../../assets/images/board-man.png';
import Bitmap from '../../../../assets/images/bitmap.png';
import BitmapGreen from '../../../../assets/images/bitmap-green.png';
import BitmapPurpure from '../../../../assets/images/bitmap-purpure.png';
import Cross from '../../../../assets/images/cross-intro.png';
import CrossRight from '../../../../assets/images/cross-half-right.png';
import CrossLeft from '../../../../assets/images/cross-half-left.png';
import AngeleTop from '../../../../assets/images/angele-top.png';
import AngeleDown from '../../../../assets/images/angele-down.png';
import Arrows from '../../../../assets/images/arrows.png';
import useGetData from '../../../../hooks/useGetData';
import { getImagesUrl, getProductsLatestUrl } from '../../../../utils/endpoints';

const BoardArrivals = () => {
  const { data, isLoading } = useGetData(
    getProductsLatestUrl({
      parameters: { itemsPerPage: 6, sortBy: 'id', sorting: 1 },
    })
  );

  return (
    <section className="BoardArrivals">
      <CardWrapper>
        <div className="BoardArrivalsDescription">
          <img className="Text" src={Text} alt="" />
          <img className="BoardMen" src={BoardMen} alt="" />
          <img className="Arrows" src={Arrows} alt="" />
        </div>
        <div className="BoardArrivalsImg">
          <div className="BoardScope">
            {/* {error && <div>{error}</div>} TODO */}

            {isLoading ? (
              <div>loading</div>
            ) : (
              data?.map((item, index) => {
                if (index > 5 || item.primaryImageId === 0) return null;
                const { id, primaryImageId } = item;

                return (
                  // need to be done using Router
                  <Link to={`products/${id}`} key={index}>
                    <img
                      className={`Board-${index}`}
                      alt=""
                      src={getImagesUrl({
                        id: primaryImageId,
                      })}
                    />
                  </Link>
                );
              })
            )}
          </div>
          <img className="Bitmap" src={Bitmap} alt="" />
          <img className="BitmapGreen" src={BitmapGreen} alt="" />
          <img className="BitmapPurpure" src={BitmapPurpure} alt="" />
          <img className="Cross" src={Cross} alt="" />
        </div>
        <img className="CrossRight" src={CrossRight} alt="" />
        <img className="CrossLeft" src={CrossLeft} alt="" />
        <img className="AngeleTop" src={AngeleTop} alt="" />
        <img className="AngeleDown" src={AngeleDown} alt="" />
      </CardWrapper>
    </section>
  );
};

export default BoardArrivals;
