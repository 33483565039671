import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getStoredUser } from '../../redux/reducers/selectors';
import { SignInSignUpWrapper } from '../../Components/SignInSignUpWrapper/SignInSignUpWrapper';
import locations from '../../utils/locations';

// STYLE MOCKS
const pageStyle = { marginBottom: '250px' };

export const SignInSignUpPage = () => {
  const user = useSelector(getStoredUser);
  const { userInfo } = user;

  const { pathname } = useLocation();

  if (userInfo.loggedWith) {
    return <Redirect to={locations.homepage} />;
  }

  return (
    <div style={pageStyle}>
      <SignInSignUpWrapper isModal={false} isSignInComponent={pathname === locations.signIn} />
    </div>
  );
};
