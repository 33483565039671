import React from 'react';
import { useSelector } from 'react-redux';

import { RootStoreType } from '../../redux/store';
import { Product } from '../Product/Product';
import './ProductList.scss';
import ProductWrapper from '../ProductWrapper/ProductWrapper';
import { imageSizes } from '../../utils/constants';
import { IProduct } from '../../TypesAndInterfaces/Interfaces';
import { getStoredProducts } from '../../redux/reducers/selectors';

interface IProductListProps {
  productList: IProduct[];
}

const ProductList = ({ productList }: IProductListProps) => {
  const products = { data: useSelector(getStoredProducts) };
  const productItems: IProduct[] = productList === undefined ? products.data.products : productList;

  if (productItems === undefined) {
    return null;
  }

  return (
    <ul className="ProductList">
      {productItems.map((item) => (
        <li key={item.id}>
          <ProductWrapper
            product={
              <Product
                id={item.id}
                img={item.primaryImageId}
                price={item.price}
                brand={item.brand}
                model={item.model}
                productList={productList}
                imageSize={imageSizes['200x350']}
              />
            }
          />
        </li>
      ))}
    </ul>
  );
};

export default ProductList;
