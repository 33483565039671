import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { ModalWrapper } from '../../../Components/ModalWrapper/ModalWrapper';
import { formatPrice, getImagesUrl, getOrderCancelUrl, makeRequest } from '../../../utils';
import locations from '../../../utils/locations';
import './OrderWidget.scss';
import { IOrdersData } from '../interfaces';
import { IOrderItems } from '../../../TypesAndInterfaces/Interfaces/User';
import { MainButton } from '../../../Components/Buttons/MainButton';
import { ConfirmationPopup } from '../../../Components/Popup/ConfirmationPopup';

interface OrderWidgetProps {
  order: IOrdersData;
  getOrders: (page: number) => void;
  page: number;
}

const DEFAULT_ORDER_ITEM_INDEX = 0;

export const OrderWidget = (props: OrderWidgetProps) => {
  const order = props.order;
  const history = useHistory();

  const [orderPrimaryImageId, setOrderPrimaryImageId] = useState(order.primaryImageId);
  const [currentOrderItemIndex, setCurrentOrderItemIndex] = useState(getOrderItemIndex());
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToOrderDetailsPage = () => {
    history.push(`${locations.orderDetails}/${order.orderId}`);
  };

  const getNamesOfOrderedModels = (orderItems: IOrderItems[]) => {
    const items = orderItems.map((item) => item.model);
    return items.join(', ');
  };

  const handleImageChangeArrowClick = (step: number) => () => {
    let orderItemIndex = currentOrderItemIndex + step;
    if (
      order.orderItems.length <= currentOrderItemIndex + step ||
      currentOrderItemIndex + step < 0
    ) {
      orderItemIndex = 0;
    }
    setOrderPrimaryImageId(order.orderItems[orderItemIndex].primaryImageId);
    setCurrentOrderItemIndex(orderItemIndex);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpen(false);
  };

  const handleCancelOrder = async () => {
    const cancelBody = {
      rowVersion: order.rowVersion,
    };

    setIsLoading(true);
    const { status } = await makeRequest(
      'patch',
      getOrderCancelUrl({ parameters: { id: order.orderId } }),
      cancelBody
    );

    if (status === 200) {
      props.getOrders(props.page);
      setIsLoading(false);
      setIsCancelModalOpen(false);
    }
  };

  const image = getImagesUrl({
    id: orderPrimaryImageId,
    parameters: null,
  });

  const orderStateWithSpaces = order.orderState.match(/[A-Z][a-z]+/g)!.join(' ');

  let modalProps = {
    open: false,
    onClose: () => {},
    children: <></>,
  };

  if (isCancelModalOpen) {
    modalProps = {
      open: isCancelModalOpen,
      onClose: closeCancelModal,
      children: (
        <>
          <ConfirmationPopup
            closeModal={closeCancelModal}
            handleClick={handleCancelOrder}
            isLoading={isLoading}
            title={'Are you sure you want to cancel the order?'}
            confirmText={'yes'}
            cancelText={'no'}
          />
        </>
      ),
    };
  }

  return (
    <div className="OrderWidget">
      <div className="order">
        <div className="img">
          {gerOrderImageArrows(
            <div
              className="ProductImg"
              style={{
                backgroundImage: `url(${image})`,
              }}
            />
          )}
        </div>
        <div className="order-info">
          <div className="number"> Order No. {order.orderId} </div>
          <div className="category-date-price">
            <div className="category">{getNamesOfOrderedModels(order.orderItems)}</div>
            <div className="date-price">
              <span>
                {order.orderDate}
                <span className="bold"> {formatPrice(order.totalPrice)}</span>
              </span>
            </div>
          </div>
        </div>
        <div className={`order-state ${order.orderState}`}>{orderStateWithSpaces}</div>
      </div>
      <div>
        <MainButton className="btn-pink-default" onClick={redirectToOrderDetailsPage}>
          See details
        </MainButton>
        {(order.orderState === 'InWork' || order.orderState === 'AwaitingConfirmation') && (
          <MainButton
            className="btn-pink-default cancel"
            onClick={() => setIsCancelModalOpen(true)}
          >
            Cancel order
          </MainButton>
        )}
      </div>
      {isCancelModalOpen && <ModalWrapper {...modalProps} />}
    </div>
  );

  function getOrderItemIndex(): number {
    let orderItemIndex = DEFAULT_ORDER_ITEM_INDEX;
    order.orderItems.forEach((o, index) => {
      if (o.primaryImageId === order.primaryImageId) {
        orderItemIndex = index;
      }
    });
    return orderItemIndex;
  }

  function gerOrderImageArrows(imageNode: React.ReactNode): React.ReactNode {
    if (order.orderItems.length > 1) {
      return (
        <div className="img">
          <IconButton onClick={handleImageChangeArrowClick(-1)}>
            <ArrowBackIosIcon className="Arrow" fontSize={'small'} />
          </IconButton>
          {imageNode}
          <IconButton onClick={handleImageChangeArrowClick(1)}>
            <ArrowForwardIosIcon className="Arrow" fontSize={'small'} />
          </IconButton>
        </div>
      );
    } else {
      return imageNode;
    }
  }
};
