import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { userMenuItems } from '../../utils/constants';

interface IUserMenuProps {
  className: string;
  handleLogout: () => void;
  setIsPopperOpen?: (value: boolean) => void;
}

const UserMenu = ({ handleLogout, className, setIsPopperOpen }: IUserMenuProps) => {
  const { pathname } = useLocation();

  return (
    <ul className={className}>
      {Object.values(userMenuItems).map((item) => (
        <li key={item.title} className={pathname === item.route ? 'active' : ''}>
          <Link
            to={item.route || '#'}
            onClick={
              item.title === userMenuItems.logout.title
                ? handleLogout
                : () => setIsPopperOpen && setIsPopperOpen(false)
            }
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default UserMenu;
