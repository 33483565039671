import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  signInValidationSchema,
  signUpValidationSchema,
  forgotPasswordValidationSchema,
} from '../../utils/validationRules';
import { clearFormData, initialFormValues, setLoginProvider, setTokens } from '../../utils/utils';
import { fieldNames, inputTypes, loginOptions, notificationTypes } from '../../utils/constants';
import { NotificationsContext } from '../../contexts/contexts';
import locations from '../../utils/locations';
import SimpleFormWrapper from '../SimpleFormWrapper/SimpleFormWrapper';
import { Popup } from '../Popup/Popup';
import LoginWithGoogle from '../LoginWithGoogle/LoginWithGoogle';
import LoginWithFacebook from '../LoginWithFacebook/LoginWithFacebook';
import Wings from '../../assets/images/or-wings.png';
import SkullIcon from '../../assets/images/skull.png';
import { AUTH_API } from './../../services/auth.services';
import { MainButton } from '../Buttons/MainButton';
import { getUserInfo } from '../../redux/actions/user/userActions';
import './SignInSignUpWrapper.scss';

const signInFields = {
  [fieldNames.email]: { title: 'Email', type: inputTypes.email },
  [fieldNames.password]: {
    title: 'Password',
    type: inputTypes.password,
  },
};

const signUpFields = {
  [fieldNames.firstName]: { title: 'First Name' },
  [fieldNames.lastName]: { title: 'Last Name' },
  [fieldNames.email]: { title: 'Email', type: inputTypes.email },
  [fieldNames.password]: { title: 'Password', type: inputTypes.password },
  [fieldNames.passwordConfirmation]: {
    title: 'Confirm Password',
    type: inputTypes.password,
  },
};

const forgotPasswordFields = {
  [fieldNames.email]: { title: 'Email' },
};

const initialSignInValues = initialFormValues(signInFields);
const initialSignUpValues = initialFormValues(signUpFields);
const initialForgotPasswordValues = initialFormValues(forgotPasswordFields);

export const SignInSignUpWrapper = ({
  isModal,
  closeModal,
  setIsRegisterConfirmModalOpen,
  setPswrdResetConfirmEmailSentSuccessModal,
}) => {
  const dispatch = useDispatch();

  const [isSignInComponent, setIsSignIn] = useState(true);
  const [isForgotPasswordComponent, setIsForgotPasswordComponent] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorNotification, setErrorNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { notifications, setNotifications } = useContext(NotificationsContext);

  const handleForgotPasswordClick = () => {
    setIsForgotPasswordComponent(true);
    setIsSignIn(false);
  };

  const forgotPassword = (
    <Link to="#" onClick={handleForgotPasswordClick} className="forgot-password-semi-link">
      Forgot Your Password?
    </Link>
  );

  signInFields[fieldNames.password].extraElement = forgotPassword;

  useEffect(() => {
    setFormData(isSignInComponent ? initialSignInValues : initialSignUpValues);
    setInitialErrors({});
    setIsSubmitted(false);
    setErrorNotification('');
  }, [isSignInComponent]);

  const getInitialValues = () => {
    if (isForgotPasswordComponent) return initialForgotPasswordValues;
    return isSignInComponent ? initialSignInValues : initialSignUpValues;
  };

  const [formData, setFormData] = useState(getInitialValues());

  const handleOptionsClick = (value) => () => {
    setFormData(clearFormData(formData));
    setIsSignIn(value);
  };

  const handleValueChange = (field) => (e) => {
    const { value } = e.target;
    setFormData((data) => ({
      ...data,
      [field]: value,
    }));

    if (field === 'email') {
      setInitialErrors({});
    }
  };

  const showEmailConfirmPopup = () => {
    setIsRegisterConfirmModalOpen(true);
  };

  const handleSubmit = async () => {
    setInitialErrors({});
    setIsSubmitted(true);
    setIsLoading(true);

    if (isValid) {
      let payload = {};
      let currentComponent = {
        name: '',
        err: null,
        data: null,
      };

      if (isSignInComponent) {
        payload = {
          password: formData.password,
          email: formData.email,
        };
        currentComponent.name = 'signIn';
      } else if (isForgotPasswordComponent) {
        payload = {
          email: formData.email,
        };
        currentComponent.name = 'forgotPassword';
      } else {
        payload = {
          password: formData.password,
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        };
        currentComponent.name = 'signUp';
      }

      switch (currentComponent.name) {
        case 'signIn': {
          const { err, data } = await AUTH_API.signIn(payload);
          currentComponent = { ...currentComponent, err, data };
          break;
        }
        case 'signUp': {
          const { err, data } = await AUTH_API.signUp(payload);
          if (data) {
            await AUTH_API.sendRegisterConfirmEmail(formData.email);
          }
          currentComponent = { ...currentComponent, err, data };
          break;
        }
        case 'forgotPassword': {
          const { err, status } = await AUTH_API.sendResetPasswordEmail(payload);
          currentComponent = { ...currentComponent, err, status };
          break;
        }
        default: {
          break;
        }
      }

      const { err, data, status } = currentComponent;
      let popupText;

      if (err) {
        if (err.response?.data?.errors?.email) {
          setInitialErrors({ email: 'Email is already in use.' });
        }

        if (err.response?.status === 400) {
          setErrorNotification(err.response.data?.message);
        }

        if (err.response?.data?.message?.includes('Email is incorrect')) {
          setErrorNotification(err.response?.data?.message);
        }

        if (err.response?.data?.message?.includes('The email and/or password do not match')) {
          setErrorNotification(err.response?.data?.message);
        }

        if (err.response?.data?.message?.includes(`You didn't confirm your email`)) {
          popupText = 'Please check your email to follow the confirmation link.';

          setNotifications([
            ...notifications,
            {
              type: notificationTypes.info,
              message: popupText,
              id: Date.now(),
            },
          ]);
        }

        if (err.response?.data?.message?.includes('is already taken')) {
          popupText = 'Sorry, this email is already taken.';

          setNotifications([
            ...notifications,
            {
              type: notificationTypes.info,
              message: popupText,
              id: Date.now(),
            },
          ]);
        }

        if (
          err.response?.data?.message?.includes(
            'Your account is blocked. Contact helpdesk@boardzone.com to further clarification'
          )
        ) {
          popupText =
            'Your account is blocked. Contact helpdesk@boardzone.com to further clarification.';

          setNotifications([
            ...notifications,
            {
              type: notificationTypes.warning,
              message: popupText,
              id: Date.now(),
            },
          ]);
        }

        if (
          err.response?.data?.message?.includes(
            'Your account is blocked temporarily because of incorrect email or password'
          )
        ) {
          popupText = 'Your account is blocked temporarily because of incorrect email or password.';

          setNotifications([
            ...notifications,
            {
              type: notificationTypes.warning,
              message: popupText,
              id: Date.now(),
            },
          ]);
        }

        setIsLoading(false);
        return;
      }

      if (status === 200 && isForgotPasswordComponent) {
        closeModal();
        setPswrdResetConfirmEmailSentSuccessModal(true);
      }

      if (data && isSignInComponent) {
        setTokens(data.token, data.refreshToken);
        setLoginProvider(loginOptions.email);
        closeModal();
        dispatch(getUserInfo(loginOptions.email));
      }

      if (data && !isSignInComponent) {
        closeModal();
        showEmailConfirmPopup();
      }
    }
    setIsLoading(false);
  };

  const backToSignIn = () => {
    setIsForgotPasswordComponent(false);
    setIsSignIn(true);
    clearFormData(formData);
  };

  return isForgotPasswordComponent ? (
    <div id="forgot-password-wrapper">
      <div id="sign-in-sign-up-form-elements">
        <div className="forgot-password-text-wrapper">
          <div>
            <span>Password Reset!</span>
          </div>
          <div>
            <span>We will email you instructions on how to reset your password</span>
          </div>
        </div>
        {errorNotification && isForgotPasswordComponent && (
          <div className="error-notification">{errorNotification}</div>
        )}
        <SimpleFormWrapper
          key="forgot-password-component"
          fields={forgotPasswordFields}
          initialValues={initialForgotPasswordValues}
          initialErrors={initialErrors}
          handleValueChange={handleValueChange}
          validationSchema={forgotPasswordValidationSchema}
          showAllErrors={isSubmitted}
          setIsValidForm={setIsValid}
          textFieldClassName="sign-in-sign-up-input"
          formControlClassName="sign-in-sign-up-control"
          setErrorNotification={setErrorNotification}
        />
        <div className="forgot-button-row">
          <MainButton
            className="sign-in-sign-up-submit-button password-reset-buttons"
            isDisabled={!isValid}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Confirm
          </MainButton>
          <MainButton
            className="sign-in-sign-up-submit-button cancel-btn password-reset-buttons"
            onClick={backToSignIn}
          >
            Cancel
          </MainButton>
        </div>
        <Link to="#" onClick={backToSignIn} className="sign-in-link">
          Sign In Now
        </Link>
      </div>
    </div>
  ) : (
    <div id="sign-in-sign-up-wrapper">
      <img src={SkullIcon} alt="" className="sign-in-sign-up-skull" />
      {isModal && (
        <div id="sign-in-sugn-up-form-switcher">
          <Link
            to="#"
            type="button"
            className={isSignInComponent ? 'active' : ''}
            onClick={handleOptionsClick(true)}
          >
            SIGN IN
          </Link>
          <Link
            to="#"
            type="button"
            className={isSignInComponent ? '' : 'active'}
            onClick={handleOptionsClick(false)}
          >
            SIGN UP
          </Link>
        </div>
      )}

      {isSignInComponent && (
        <div>
          <div>
            <LoginWithFacebook closeModal={closeModal} />
            <LoginWithGoogle closeModal={closeModal} />
          </div>
          <div className="sign-in-auth-methods-divider">
            <img src={Wings} alt="" className="or-wings left-wings" />
            <span>OR</span>
            <img src={Wings} alt="" className="or-wings right-wings" />
          </div>
        </div>
      )}

      {errorNotification && isSignInComponent && (
        <div className="error-notification">{errorNotification}</div>
      )}

      <div id="sign-in-sign-up-form-elements">
        <SimpleFormWrapper
          key={isSignInComponent}
          fields={isSignInComponent ? signInFields : signUpFields}
          initialValues={formData}
          initialErrors={initialErrors}
          handleValueChange={handleValueChange}
          validationSchema={isSignInComponent ? signInValidationSchema : signUpValidationSchema}
          showAllErrors={isSubmitted}
          setIsValidForm={setIsValid}
          textFieldClassName="sign-in-sign-up-input"
          formControlClassName="sign-in-sign-up-control"
          setErrorNotification={setErrorNotification}
        />
      </div>

      {!isSignInComponent && (
        <div className="privacy-cont">
          By signing up you accept{' '}
          <a href="https://www.dataart.com/privacy-policy">Privacy Policy</a>
        </div>
      )}

      <MainButton
        className="sign-in-sign-up-submit-button"
        isDisabled={!isValid}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        {isSignInComponent ? 'SIGN IN' : 'CREATE ACCOUNT'}
      </MainButton>

      {!isModal &&
        (isSignInComponent ? (
          <Link to={locations.signUp}>Don&apos;t have an account? Create one now.</Link>
        ) : (
          <Link to={locations.signIn}>Sign In</Link>
        ))}
    </div>
  );
};
