import React from 'react';
import { NavLink } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import locations from '../../utils/locations';

import './SimpleBreadcrumbs.scss';

interface ISimpleBreadcumbsProps {
  sectionName: string;
  categoryName?: string;
  subCategoryName?: string;
  productTitle?: string;
}

export const SimpleBreadcrumbs = ({
  sectionName,
  categoryName,
  subCategoryName,
  productTitle,
}: ISimpleBreadcumbsProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" className="Breadcrumbs">
      <NavLink to={{ pathname: locations.homepage }} className="BreadcumbsLink">
        Home
      </NavLink>
      <NavLink to={{ pathname: locations.sectionPage(sectionName) }} className="BreadcumbsLink">
        {sectionName}
      </NavLink>
      {categoryName && (
        <NavLink
          to={{ pathname: locations.categoryPage(sectionName, categoryName) }}
          className="BreadcumbsLink"
        >
          {categoryName}
        </NavLink>
      )}
      {subCategoryName && (
        <NavLink
          to={{ pathname: locations.subCategoryPage(sectionName, categoryName!, subCategoryName) }}
          className="BreadcumbsLink"
        >
          {subCategoryName}
        </NavLink>
      )}
      {productTitle && <Typography color="textPrimary">{productTitle}</Typography>}
    </Breadcrumbs>
  );
};
