import React from 'react';
import './CardWrapper.scss';

interface ICardWrapperProps {
  children: React.ReactNode;
}

const CardWrapper = ({ children }: ICardWrapperProps) => (
  <div className="CardWrapper">{children}</div>
);

export default CardWrapper;
