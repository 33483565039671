import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS,
  ON_REMOVE_WISHLIST_ITEM,
} from '../actions/wishListItem/wishListItemTypes';
import { IWishlist } from '../../TypesAndInterfaces/Interfaces';

export interface IWishListReducer {
  wishListItems: IWishlist[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  isError: boolean;
  isLoading: boolean;
}

const initialState: IWishListReducer = {
  wishListItems: [],
  pageNumber: 1,
  pageSize: 1,
  totalRecords: 1,
  isError: false,
  isLoading: false,
};

export const wishListItemReducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case ON_FETCHING_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ON_FETCHING_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        wishListItems: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalRecords: action.payload.totalRecords,
      };
    case ON_REMOVE_WISHLIST_ITEM:
      return {
        ...state,
        wishListItems: state.wishListItems.filter((item: { id: number }) => {
          return item.id !== action.payload;
        }),
      };
    default:
      return state;
  }
};
