import React from 'react';

import './SkullEdition.scss';
import RawpixelUnsplash from '../../../../assets/images/rawpixel-unsplash.png';
import Scar from '../../../../assets/images/scar.png';
import BitmapOne from '../../../../assets/images/bitmap-01.png';
import Close from '../../../../assets/images/icon/close.svg';
import OustandingDesign from '../../../../assets/images/oustanding-design.png';
import WideCustomization from '../../../../assets/images/wide-customization.png';
import PerfectFit from '../../../../assets/images/perfect-fit.png';
import Durable from '../../../../assets/images/durable.png';
import EasyToUse from '../../../../assets/images/easy-to-use.png';
import BoardCompatibility from '../../../../assets/images/board-compatibility.png';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import BindingsSlider from '../../../../Components/ProductsSlider/ProductsSlider';
import BigCross from '../../../../assets/images/big-horizontal-cross.png';
import CrossBindings from '../../../../assets/images/crosses-bindings-row.png';

const SkullEdition = () => (
  <section className="SkullEdition">
    <div className="SkullEditionAnimationOuter">
      <h2>Bindings Skull Edition</h2>
      <div className="SkullEditionAnimation">
        <img className="RawpixelUnsplash" src={RawpixelUnsplash} alt="" />
        <img className="Scar" src={Scar} alt="" />
        <img className="BitmapOne" src={BitmapOne} alt="" />
        <div className="Tooltip TipOustandingDesign">
          <img className="OustandingDesign" src={OustandingDesign} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
        <div className="Tooltip TipWideCustomization">
          <img className="WideCustomization" src={WideCustomization} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
        <div className="Tooltip TipPerfectFit">
          <img className="PerfectFit" src={PerfectFit} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
        <div className="Tooltip TipDurable">
          <img className="Durable" src={Durable} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
        <div className="Tooltip TipEasyToUse">
          <img className="EasyToUse" src={EasyToUse} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
        <div className="Tooltip TipBoardCompatibility">
          <img className="BoardCompatibility" src={BoardCompatibility} alt="" />
          <img className="Close" src={Close} alt="" />
        </div>
      </div>
      <MainButton textColor="#fff" fontSize="25px" ProductImg="images/rectangle-green.png">
        view more
      </MainButton>
    </div>
    <div className="BindingsSliderOuter">
      <BindingsSlider />
      <MainButton textColor="#fff" fontSize="25px" ProductImg="images/rectangle-pink.png">
        view all bindings
      </MainButton>
      <img className="BigCross" src={BigCross} alt="" />
      <img className="CrossBindings" src={CrossBindings} alt="" />
    </div>
  </section>
);

export default SkullEdition;
