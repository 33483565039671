import React, { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getStoredCategories } from '../../redux/reducers/selectors';
import {
  filtersList,
  NO_FILTER,
  stateStatuses,
  genders,
  defaultFiltersValues,
  defaultMetaInfoValues,
  subCategories,
} from '../../utils/constants';
import {
  REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER,
  REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER,
  addDefaultParams,
  makeRequest,
} from '../../utils/utils';
import { getFilteredProductsUrl } from '../../utils/endpoints';
import FiltersSection from './Sections/FiltersSection/FiltersSection';
import { SimpleBreadcrumbs } from '../../Components/Breadcrumbs/SimpleBreadcrumbs';
import { MainButton } from '../../Components/Buttons/MainButton';
import ProductList from '../../Components/ProductList/ProductList';
import CardWrapper from '../../Components/CardWrapper/CardWrapper';
import MetaInfoSection from './Sections/MetaInfoSection/MetaInfoSection';
import NoMatchComponent from '../../Components/NoMatchComponent/NoMatchComponent';
import './ProductsListSection.scss';

interface IParams {
  sectionName: string;
  categoryName: string;
  subCategoryName: string;
}

const ProductsPage = () => {
  const { categories, isError } = useSelector(getStoredCategories);
  const { sectionName, categoryName, subCategoryName } = useParams<IParams>();

  const params = {
    section: sectionName,
    category: categoryName,
    subCategory: subCategoryName,
  };

  const [filters, setFilters] = useState(addDefaultParams(defaultFiltersValues, params));
  const [metaInfo, setMetaInfo] = useState(addDefaultParams(defaultMetaInfoValues, params));

  const [pageStatus, setPageStatus] = useState(stateStatuses.loading);
  const [productList, setProductList] = useState([]);

  const category = categories.find(
    (category) => category.name === subCategories[sectionName]![categoryName][subCategoryName].title
  );

  const handleClick = useCallback(() => {
    setMetaInfo((info) => ({
      ...info,
      [filtersList.PageIndex]: info[filtersList.PageIndex] + 1,
    }));
  }, []);

  const handleMetaInfoChange = useCallback((values) => {
    setMetaInfo((info) => ({
      ...info,
      ...values,
      [filtersList.PageIndex]: 1,
    }));
  }, []);

  let reqBody = { ...metaInfo, ...filters };

  Object.values(genders).map((gender) => {
    if (sectionName === gender) {
      reqBody = { ...metaInfo, ...filters, [filtersList.Gender]: gender.toString() };
    }
  });

  useEffect(() => {
    setPageStatus(stateStatuses.loading);

    if (category) {
      (async () => {
        try {
          const response = await makeRequest(
            'get',
            getFilteredProductsUrl({
              id: category.id,
              parameters: reqBody,
              defaultParameters: {
                ...defaultFiltersValues,
                [filtersList.Sort]: NO_FILTER,
                numberOfPages: metaInfo.numberOfPages,
              },
            })
          );

          const { data, page } = response.data;

          setProductList(
            metaInfo[filtersList.PageIndex] === 1 ? data : (list) => [...list, ...data]
          );
          setMetaInfo((info) => ({
            ...info,
            numberOfPages: page.numberOfPages,
          }));
          setPageStatus(stateStatuses.success);
        } catch (err) {
          setProductList([]);
          setPageStatus(stateStatuses.failure);
        }
      })();
    } else if (isError) {
      setPageStatus(stateStatuses.failure);
    }
  }, [
    category,
    sectionName,
    isError,
    metaInfo.PageIndex,
    metaInfo.PageSize,
    metaInfo.Sort,
    metaInfo.Sorting,
    metaInfo.PageSize,
    filters,
  ]);

  return (
    <main className="with-space-for-header">
      <SimpleBreadcrumbs
        sectionName={sectionName}
        categoryName={categoryName}
        subCategoryName={subCategoryName}
      />
      <FiltersSection filters={filters} setFilters={setFilters} setMetaInfo={setMetaInfo} />
      <section className="ProductsListSection">
        <CardWrapper>
          <MetaInfoSection metaInfo={metaInfo} handleMetaInfoChange={handleMetaInfoChange} />
          <ProductList productList={productList} />
          {pageStatus === stateStatuses.failure && REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER}
          {pageStatus === stateStatuses.loading && REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER}
          {pageStatus === stateStatuses.success && !productList.length && (
            <NoMatchComponent message="No matches found" />
          )}
          {pageStatus === stateStatuses.success &&
            metaInfo.numberOfPages > metaInfo[filtersList.PageIndex] && (
              <MainButton
                textColor="#fff"
                fontSize="24px"
                ProductImg="images/rectangle-small.png"
                onClick={handleClick}
              >
                load more
              </MainButton>
            )}
        </CardWrapper>
      </section>
    </main>
  );
};

export default ProductsPage;
