import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getStoredUser } from '../../redux/reducers/selectors';
import { clearUserInfo } from '../../redux/actions/user/userActions';
import { clearShippingInfo } from '../../redux/actions/shippingInfo/shippingInfoActions';
import useGoogle from '../../hooks/useGoogle';
import { clearTokens, clearLoginProvider, makeRequest } from '../../utils/utils';
import {
  facebookAccessToken,
  loginOptions,
  USER_DATA_LS,
  SHIPPING_INFO_LS,
} from '../../utils/constants';
import { USER_API } from '../../services/user.services';
import { ConfirmationPopup } from '../Popup/ConfirmationPopup';
import { logout } from '../../utils/logout';
import { IShoppingCartContext } from '../../TypesAndInterfaces/Interfaces';
import { ShoppingCartContext } from '../../contexts/contexts';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';

interface IConfirmDialogWrapperProps {
  isOpened: boolean;
  closeDialog: () => void;
}

export const ConfirmDialogWrapper = ({ isOpened, closeDialog }: IConfirmDialogWrapperProps) => {
  const dispatch = useDispatch();
  const user = useSelector(getStoredUser);
  const { userInfo, tabsCount } = user;

  const { onGoogleLogout } = useGoogle();
  const history = useHistory();

  const { shoppingCartProducts, setShoppingCartProducts } = useContext<IShoppingCartContext>(
    ShoppingCartContext
  );

  const logoutCleanup = () => {
    logout(dispatch);
    setShoppingCartProducts([]);
    closeDialog();
    history.push('/');
  };

  const _onLogout = async () => {
    await USER_API.logout();
    logoutCleanup();
  };

  const _onGoogleLogout = () => {
    onGoogleLogout();
    logoutCleanup();
  };

  const token = localStorage.getItem(facebookAccessToken);

  const _onFacebookLogout = async () => {
    // TODO Add request to API to logout from Facebook When BE finish implementing Facebook logout from server
    // Logout from Facebook
    // window.FB?.logout(() => {
    // });

    const res = await makeRequest('post', 'https://demo242.dataart.com/api/fb-logout', {
      accessToken: token,
    });

    logoutCleanup();
  };

  const confirmDialogProps = {
    title:
      tabsCount > 1
        ? 'You have more then one page open. If you continue all pages will be logged out.'
        : 'Are you sure you want to log out?',
    handleClick: () => {
      switch (userInfo.loggedWith) {
        case loginOptions.google:
          return _onGoogleLogout();
        case loginOptions.facebook:
          return _onFacebookLogout();
        case loginOptions.email:
          return _onLogout();
        default:
          return;
      }
    },
    closeModal: () => {
      closeDialog();
    },
    isLoading: false,
    confirmText: 'confirm',
    cancelText: 'cancel',
  };

  const modalProps = {
    open: isOpened,
    onClose: closeDialog,
    children: (
      <>
        <ConfirmationPopup {...confirmDialogProps} />
      </>
    ),
  };

  return <ModalWrapper {...modalProps} />;
};
