import React from 'react';

import './UpcomingEvents.scss';
import BitmapTv from '../../../../assets/images/bitmap-tv.png';
import BgTop from '../../../../assets/images/events-bg-top.png';
import CrossesHorizontal from '../../../../assets/images/crosses-horizontal.png';
import CrossesVertical from '../../../../assets/images/crosses-vertical.png';
import EventComponent from '../../../../Components/EventComponet/EventComponent';
import { KIONScrollable } from '../../../../Components/CustomScrole/CustomScrole';
import BgBottom from '../../../../assets/images/events-bg-bottom.png';
import useGetData from '../../../../hooks/useGetData';
import { formatDateToDatetime } from '../../../../utils/utils';
import { getEventsUrl } from '../../../../utils/endpoints';

const EventContent = ({ item }) => {
  if (!item) return <></>;

  const { video, date, title, description } = item.content;

  return (
    <div className="Video">
      <iframe
        title="Youtube video"
        width="100%"
        height="315"
        src={`https://www.youtube.com/embed/${video}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

      <time dateTime={formatDateToDatetime(date)}>{date}</time>
      <strong className="VideoTitle">{title}</strong>
      <p>{description}</p>
      <img className="BitmapTv" src={BitmapTv} alt="" />
    </div>
  );
};

const UpcomingEvents = () => {
  const { data, isLoading } = useGetData(getEventsUrl());

  if (isLoading) {
    return <div>loading</div>;
  }

  return (
    <>
      <img className="BgTop" src={BgTop} alt="" />
      <section className="UpcomingEvents">
        <h2>Upcoming Trips & Events</h2>
        <div className="UpcomingEventsContent">
          <EventContent item={data[0]} />

          <div className="Events">
            <KIONScrollable style={{ width: 500, height: 300 }}>
              {data?.map(({ dates, title, location, price, people, type }, index) => (
                <EventComponent
                  key={index}
                  dateFrom={{
                    from: {
                      number: dates.startDay,
                      month: dates.startMonth,
                    },
                  }}
                  dateTo={{
                    to: {
                      number: dates.endDay,
                      month: dates.endMonth,
                    },
                  }}
                  title={title}
                  city={location.city}
                  country={location.country}
                  price={price}
                  people={people}
                  type={type}
                />
              ))}
            </KIONScrollable>
          </div>
          <img className="CrossesHorizontal" src={CrossesHorizontal} alt="" />
        </div>
        <img className="CrossesVertical" src={CrossesVertical} alt="" />
      </section>
      <img className="BgBottom" src={BgBottom} alt="" />
    </>
  );
};

export default UpcomingEvents;
