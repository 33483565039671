import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from 'lodash/debounce';
import indexOf from 'lodash/indexOf';
import curry from 'lodash/curry';
import axios from 'axios';
import { parse } from 'qs';
import moment from 'moment';

import { Loader } from '../Components/Loader/Loader';
import { ErrorPlaceholder } from '../Components/ErrorPlaceholder/ErrorPlaceholder';
import {
  genders,
  usageList,
  filtersList,
  NO_FILTER,
  LOGIN_PROVIDER,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  AUTHORIZATION,
  loginOptions,
  locationStateFields,
  colorsList,
  joinedFiltersValues,
  boarderParameters,
  itemsPerPage,
  sortByOptions,
  categoriesSubdivisions,
  navigationItems,
  categoriesDivisions,
  defaultLocationStateObject,
  boardSizeParameters,
  priceRangeParameters,
  subCategories,
  USER_DATA_LS,
} from './constants';

export const REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER = <Loader />;
export const REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER = <ErrorPlaceholder />;

export const debounceFunction = (timeout) =>
  debounce((func, ...params) => func(...params), timeout);

export const isPrimitiveAbsent = (array, value) => indexOf(array, value) === -1;

export const handlePrice = (price) => (price === 0 ? 'FREE' : `$${price?.toFixed(2)}`);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatPrice = (price) => {
  const numPrice = Number(price);
  if (Number.isNaN(numPrice)) {
    return price;
  }
  return price === 0 ? 'FREE' : formatter.format(numPrice);
};

export const isAnyFieldDifferent = curry((fields, obj1, obj2) => {
  for (const field in fields) {
    if (fields.hasOwnProperty(field) && obj1[field] !== obj2[field]) return true;
  }
  return false;
});

export const scrollToTop = (behavior = 'auto') => {
  window.scrollTo({
    top: 0,
    behavior,
  });
};

export const setLoginProvider = (loginOption) => {
  loginOption
    ? localStorage.setItem(LOGIN_PROVIDER, loginOption)
    : localStorage.removeItem(LOGIN_PROVIDER);
};

export const clearLoginProvider = () => setLoginProvider();

export const getTokens = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return [accessToken || undefined, refreshToken || undefined];
};

export const setTokens = (access, refresh) => {
  access ? localStorage.setItem(ACCESS_TOKEN, access) : localStorage.removeItem(ACCESS_TOKEN);

  refresh ? localStorage.setItem(REFRESH_TOKEN, refresh) : localStorage.removeItem(REFRESH_TOKEN);
};

export const clearTokens = () => setTokens();

export const setProfileData = (firstName, lastName, email, picURL) => ({
  firstName,
  lastName,
  email,
  picURL,
});

export const cleanUpOnLogout = () => {
  if (localStorage.getItem(LOGIN_PROVIDER) === loginOptions.google) {
    window.gapi.auth2.getAuthInstance().signOut();
  }
  setLoginProvider();
  setTokens();
};

export const initGoogleAuth = () =>
  window.gapi.auth2.init({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    fetch_basic_profile: true,
    scope: 'profile',
  });

export const getGoogleProfileData = (googleUser) => {
  const { GW, GU, Eu, iL } = googleUser.getBasicProfile();
  return setProfileData(GW, GU, Eu, iL);
};

export const onRequestInterceptor = (config) => {
  const [accessToken] = getTokens();
  return accessToken
    ? {
        ...config,
        headers: {
          [AUTHORIZATION]: `Bearer ${accessToken}`,
          'Content-Type': 'application/json;charset=utf-8',
        },
      }
    : config;
};

export const formatDateToDatetime = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const setFormLabel = (field, showOptional) => {
  if (showOptional) return field.title;
  return field.isOptional ? `${field.title} (optional)` : `${field.title}`;
};

export const arrayToSelectOptions = (array) =>
  array.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.name}
    </MenuItem>
  ));

export const initialFormValues = (fields, localValues = {}, useLocalValues = false) =>
  Object.entries(fields)
    .map(([key, value]) => {
      let initialValue;
      if (localValues[key] && useLocalValues) initialValue = localValues[key];
      else initialValue = Object.is(undefined, value.initialValue) ? '' : value.initialValue;
      return {
        [key]: initialValue,
      };
    })
    .reduce((acc, val) => ({ ...acc, ...val }), {});

export const validateRangeValue = (selectInfo, val) => {
  const value = parseInt(val, 10);
  if (isNaN(value) || value > selectInfo.max || value < selectInfo.min || value % selectInfo.step)
    return null;
  return value;
};

export const isMinMaxPairValid = (min, max) => {
  if (!min || !max || min <= max) return true;
  return false;
};

const validationFuncs = {
  [locationStateFields.navItem]: (val) => navigationItems[val] ?? null,
  [locationStateFields.category]: (val) => categoriesDivisions[val] ?? null,
  [locationStateFields.subCategory]: (val) => categoriesSubdivisions[val] ?? null,

  [filtersList.MinPrice]: (val) => validateRangeValue(priceRangeParameters, val),
  [filtersList.MaxPrice]: (val) => validateRangeValue(priceRangeParameters, val),
  [filtersList.MinSize]: (val) => validateRangeValue(boardSizeParameters, val),
  [filtersList.MaxSize]: (val) => validateRangeValue(boardSizeParameters, val),
  [filtersList.Height]: (val) => validateRangeValue(boarderParameters.height, val),
  [filtersList.Weight]: (val) => validateRangeValue(boarderParameters.weight, val),

  [filtersList.Color]: (val) => {
    const index = indexOf(colorsList, val);
    return index !== -1 ? index : null;
  },
  [filtersList.Usage]: (val) => {
    const values = val.map((item) => parseInt(item, 10));
    const usageArray = usageList.map(({ id }) => id).filter((item) => indexOf(values, item) !== -1);

    return usageArray.length ? { values: usageArray, isJoined: true } : null;
  },
  [filtersList.PageSize]: (val) => {
    const value = parseInt(val);
    return indexOf(itemsPerPage, parseInt(value)) !== -1 ? value : null;
  },
  [filtersList.Sort]: (val) => {
    const value = parseInt(val);
    return indexOf(
      sortByOptions.map(({ value }) => value),
      value
    ) !== -1
      ? value
      : null;
  },
};

export const paramToPublicUrlFuncs = {
  [filtersList.Color]: (val) => colorsList[val] || NO_FILTER,
};

export const validateQueryParam = (key, value) => {
  try {
    const result = validationFuncs[key](value);
    return result;
  } catch {
    return null;
  }
};

export const validateParamsObject = (obj) => {
  const isPriceRangeValid = isMinMaxPairValid(obj[filtersList.MinPrice], obj[filtersList.MaxPrice]);
  const isSizeRangeValid = isMinMaxPairValid(obj[filtersList.MinSize], obj[filtersList.MaxSize]);
  const isLocationParamsValid = !!subCategories?.[obj[locationStateFields.navItem]]?.[
    obj[locationStateFields.category]
  ]?.[obj[locationStateFields.subCategory]]?.title;

  return isLocationParamsValid && isPriceRangeValid && isSizeRangeValid
    ? obj
    : {
        ...obj,
        [locationStateFields.navItem]: isLocationParamsValid
          ? obj[locationStateFields.navItem]
          : null,
        [locationStateFields.category]: isLocationParamsValid
          ? obj[locationStateFields.category]
          : null,
        [locationStateFields.subCategory]: isLocationParamsValid
          ? obj[locationStateFields.subCategory]
          : null,
        [filtersList.MinPrice]: isPriceRangeValid
          ? obj[filtersList.MinPrice]
          : obj[filtersList.MaxPrice],
        [filtersList.MaxPrice]: isPriceRangeValid
          ? obj[filtersList.MaxPrice]
          : obj[filtersList.MinPrice],
        [filtersList.MinSize]: isSizeRangeValid
          ? obj[filtersList.MinSize]
          : obj[filtersList.MaxSize],
        [filtersList.MaxSize]: isSizeRangeValid
          ? obj[filtersList.MaxSize]
          : obj[filtersList.MinSize],
      };
};

export const addDefaultParams = (defaultValues, params) =>
  Object.entries(defaultValues)
    .map(([key, value]) => ({
      [key]: params[key] ?? value,
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});

export const parseQueryString = (string) => {
  const validSepatateParams = Array.from(new URLSearchParams(string)).reduce(
    (acc, [key, value]) => {
      if (!filtersList[key] && !locationStateFields[key]) return acc;

      const normalizedValue = isPrimitiveAbsent(joinedFiltersValues, key)
        ? value
        : value.split(',');

      const validatedValue = validationFuncs[key]
        ? validateQueryParam(key, normalizedValue) ?? undefined
        : normalizedValue;

      return {
        ...acc,
        [key]: validatedValue,
      };
    },
    {}
  );

  const validatedParams = validateParamsObject(validSepatateParams);
  return {
    ...validatedParams,
    ...addDefaultParams(defaultLocationStateObject, validatedParams),
    [filtersList.Gender]: genders[validatedParams[locationStateFields.navItem]],
  };
};

export const mapParamsToPublicUrlQuery = (params) =>
  Object.entries(params)
    .map(([key, value]) => ({
      [key]: paramToPublicUrlFuncs[key] ? paramToPublicUrlFuncs[key](value) : value,
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {});

export const mapParamsToSelectRequest = (metaInfo, filters) => {
  return {
    pageInfo: {
      page: metaInfo.page,
      itemsPerPage: metaInfo.PageSize,
    },
    productFiltrationRangeAttributes: {
      minPriice: filters.MinPrice,
      maxPrice: filters.MaxPrice,
    },
  };
};

export const clearFormData = (formData) => {
  for (let key in formData) {
    formData[key] = '';
  }
  return formData;
};

export const makeRequest = async (method, url, body = {}, params = {}) => {
  try {
    const { data, status } = await axios(url, { method, data: body, params });
    return { data, err: null, status };
  } catch (err) {
    return { data: null, err };
  }
};

export const getParamsFromQueryString = (query) => {
  return parse(query, { ignoreQueryPrefix: true, decoder: (c) => c });
};

export const isLoggedIn = () => localStorage.getItem(LOGIN_PROVIDER) !== null;

export const convertDate = (date) => {
  return moment(date.split('T')[0]).format('D MMMM YYYY');
};

export const isUserSignedIn = () => {
  const userData = localStorage.getItem(USER_DATA_LS);

  return userData ? true : false;
};

export const getUniqueKey = (elem) => {
  const randomValue = Math.random() * 10000;
  return `${elem}_${randomValue.toFixed()}`;
};

export const getSelectWishListItems = (listItems) => {
  return listItems && listItems.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
};

export const compareAddresses = (savedAddresses, shippingAddress) => {
  const arr = savedAddresses.filter(
    (address) =>
      address.address1 === shippingAddress.address1 &&
      address.address2 === shippingAddress.address2 &&
      address.city === shippingAddress.city &&
      address.countryId === shippingAddress.country?.id &&
      address.state === shippingAddress.state &&
      address.zipCode === shippingAddress.zipCode
  );

  return !arr.length;
};
