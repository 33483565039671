import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteChildrenProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getStoredUser } from '../../redux/reducers/selectors';
import { getUserSavedAddresses } from '../../redux/actions/user/userActions';
import { DashboardPage } from './DashboardPage/DashboardPage';
import HelpText from './Components/HelpText/HelpText';
import Title from './Components/Title';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import locations from '../../utils/locations';
import { isLoggedIn } from '../../utils';
import './AccountWrapper.scss';

export const AccountWrapper = () => {
  const dispatch = useDispatch();

  const user = useSelector(getStoredUser);
  const { userInfo, isLoading } = user;

  const [showResultPopup, setShowResultPopup] = useState(false);

  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getUserSavedAddresses(userInfo?.id));
    }
  }, [userInfo?.id]);

  // if (isLoading) {
  // TODO Implement Spinner
  //   return (
  //     <main className="AccountWrapper">
  //       <div>Loading...</div>
  //     </main>
  //   );
  // }

  if (!isLoggedIn()) {
    return <Redirect to={locations.homepage} />;
  }

  return (
    <main className="AccountWrapper with-space-for-header">
      <div className="account-head">
        <div className="account-content-wrapper">
          <Title title="My Account" />
          <HelpText textAlign={'right'} />
        </div>
      </div>
      <div className="account-body account-content-wrapper">
        <Sidebar />
        <div className="account-content">
          <div className="account-welcome">
            Hi {userInfo?.firstName || 'User'} {userInfo?.lastName}
          </div>
          <Route
            path={locations.accountDashboard}
            component={(props: RouteChildrenProps) => (
              <DashboardPage
                {...props}
                showResultPopup={showResultPopup}
                setShowResultPopup={setShowResultPopup}
              />
            )}
          />
        </div>
      </div>
    </main>
  );
};
