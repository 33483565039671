import range from 'lodash/range';

import statesRaw from './countriesAndStates/states';
import { getProductsUrl } from './endpoints';
import locations from './locations';

const IS_PRODUCT_SERVER = true;

export const TIMEOUT_BEFORE_3RD_PARTY_SCRIPTS_EVALUATION_MS = 3000;

export const NO_FILTER = 'NO_FILTER';
export const IS_ACTIVE = 'IS_ACTIVE';
export const AUTHORIZATION = 'Authorization';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const LOGIN_PROVIDER = 'loginProvider';
export const CANCELLED_REQUEST = 'CANCELLED_REQUEST';
export const SHOULD_RELOAD = 'SHOULD_RELOAD';
export const USER_DATA_LS = 'userData';
export const SHIPPING_INFO_LS = 'shippingInfo';
export const SHOPPING_CART = 'shoppingCart';

export const facebookAppId = process.env.REACT_APP_FB_APP_ID;
export const facebookAppKey = process.env.REACT_APP_FB_APP_SECRET;
export const facebookRedirectURL = IS_PRODUCT_SERVER
  ? process.env.REACT_APP_FB_REDIRECT_URL
  : process.env.REACT_APP_MAIN_REDIRECT_URL_LOCAL;

export const googleAppId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const googleAppKey = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const googleAppScope = IS_PRODUCT_SERVER ? process.env.REACT_APP_GOOGLE_SCOPE : null;

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

export const googleRedirectURL = IS_PRODUCT_SERVER
  ? process.env.REACT_APP_FB_REDIRECT_URL
  : process.env.REACT_APP_MAIN_REDIRECT_URL_LOCAL;

export const imageSizes = {
  '40x40': { width: 40, height: 40 },
  '110x110': { width: 110, height: 110 },
  '200x350': { width: 200, height: 350 },
};

export const inputTypes = {
  email: 'email',
  password: 'password',
  checkbox: 'checkbox',
  button: 'button',
};

export const loginOptions = {
  email: 'email',
  facebook: 'facebook',
  google: 'google',
};

export const stateStatuses = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};

export const activeLoginProviders = {
  [loginOptions.email]: stateStatuses.success,
  [loginOptions.google]: stateStatuses.loading,
  [loginOptions.facebook]: stateStatuses.loading,
};

export const userMenuItems = {
  account: {
    title: 'Info',
    route: locations.accountDashboard,
  },
  preferences: {
    title: 'Preferences',
    route: locations.notificationPreferences,
  },
  myOrders: {
    title: 'My Orders',
    route: locations.myOrders,
  },
  wishlist: {
    title: 'My Wishlist',
    route: locations.wishlist,
  },
  coupons: {
    title: 'My Coupons',
    route: locations.myCoupons,
  },
  logout: {
    title: 'Sign Out',
    route: '',
    signedInOnly: true,
  },
};

export const colorsList = ['Black', 'Blue', 'Gray', 'Green', 'Red', 'Pink', 'White', 'Yellow'];

export const boardWidthList = ['Regular', 'Midwide', 'Wide'];

export const rockerProfilesList = [
  'Camber',
  'Rocker',
  'Flat',
  'Flat with Rocker',
  'Rocker with Camber',
  'Flat/Camber/Flat',
];

export const shapesList = ['Directional', 'True Twin', 'Directional - Twin'];

// This list is based on current brand names of products.
// TO-DO: Edit when actual brand list will be known.
export const brandsList = [
  'Coop',
  'Burton',
  'WestB',
  'LibTech',
  'TTL-Boards',
  'Season',
  'SuperBoards',
  'EndeavorSnowboards',
];

export const usageList = ['Powder riding', 'All Mountain', 'Freestyle', 'Freeride', 'Splitboard'];

export const riderLevelsList = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];

export const flexesList = ['Very soft', 'Soft', 'Medium', 'Stiff', 'Very stiff'];

export const paymentMethods = {
  creditCard: 0,
  payPal: 1,
  amazonPay: 2,
};

export const shippingOptions = [
  {
    title: 'Economy',
    timeEstimate: '4-8 Business Days',
  },
  {
    title: 'Standard',
    timeEstimate: '1-2 Business Days',
  },
  {
    title: 'TwoDays',
    timeEstimate: '1-2 Luxury Business Days',
  },
  {
    title: 'Overnight',
    timeEstimate: '1 Luxury Business Day',
  },
];

export const itemsPerPage = [24, 48, 72];

export const sortByOptions = [
  {
    value: { sort: 'price', sorting: 1 },
    name: '$$$-$',
  },
  {
    value: { sort: 'price', sorting: 0 },
    name: '$-$$$',
  },
  {
    value: { sort: 'dateAdded', sorting: 1 },
    name: 'Old first',
  },
  {
    value: { sort: 'dateAdded', sorting: 0 },
    name: 'New first',
  },
  {
    value: { sort: 'popularity', sorting: 1 },
    name: 'More popular first',
  },
  {
    value: { sort: 'popularity', sorting: 0 },
    name: 'Less popular first',
  },
];

export const boarderParameters = {
  height: {
    min: 140,
    max: 180,
    step: 4,
    unit: 'cm',
  },
  weight: {
    min: 40,
    max: 100,
    step: 4,
    unit: 'kg',
  },
};

export const boardSizeParameters = {
  min: 80,
  max: 200,
  step: 5,
  unit: 'cm',
};

export const priceRangeParameters = {
  min: 0,
  max: 1000,
  step: 100,
  unit: '$',
};

export const filtersList = {
  Color: 'Color',
  MinPrice: 'MinPrice',
  MaxPrice: 'MaxPrice',
  Height: 'Height',
  Weight: 'Weight',
  Sort: 'Sort',
  Sorting: 'Sorting',
  Usage: 'Usage',
  MinLength: 'MinLength',
  MaxLength: 'MaxLength',
  PageIndex: 'PageInfo.Page',
  PageSize: 'PageInfo.ItemsPerPage',
  Gender: 'Gender',
  RidingLevel: 'RidingLevel',
  BoardWidth: 'BoardWidth',
  Flex: 'Flex',
  RockerProfile: 'RockerProfile',
  Shape: 'Shape',
  Brand: 'Brand',
};

export const defaultFiltersValues = {
  [filtersList.Color]: NO_FILTER,
  [filtersList.Usage]: NO_FILTER,
  [filtersList.MinLength]: boardSizeParameters.min,
  [filtersList.MaxLength]: boardSizeParameters.max,
  [filtersList.MinPrice]: priceRangeParameters.min,
  [filtersList.MaxPrice]: priceRangeParameters.max,
  [filtersList.Height]: 0,
  [filtersList.Weight]: 0,
};

export const defaultMetaInfoValues = {
  [filtersList.PageIndex]: 1,
  [filtersList.PageSize]: itemsPerPage[0],
  [filtersList.Sort]: 'popularity',
  [filtersList.Sorting]: 1,
};

//  @ts-ignore
export const joinedFiltersValues = [filtersList.terrains];

export const navigationItems = {
  Men: 'Men',
  Women: 'Women',
  Kids: 'Kids',
  Accessories: 'Accessories',
  Kits: 'Kits',
  Trips: 'Trips',
};

export const genders = {
  [navigationItems.Men]: 'Men',
  [navigationItems.Women]: 'Women',
  [navigationItems.Kids]: 'Kids',
};

export const navigationItemsTitles = {
  [navigationItems.Men]: 'Men',
  [navigationItems.Women]: 'Women',
  [navigationItems.Kids]: 'Kids',
  [navigationItems.Accessories]: 'Accessories',
  [navigationItems.Kits]: 'Kits',
  [navigationItems.Trips]: 'Trips',
};

export const categoriesDivisions = {
  Snowboarding: 'Snowboarding',
  Apparel: 'Apparel',
  Accessories: 'Accessories',
  Bags: 'Bags',
  Men: 'Men',
  Women: 'Women',
  Kids: 'Kids',
};

export const categoriesSubdivisions = {
  Snowboards: 'Snowboards',
  SnowboardBoots: 'SnowboardBoots',
  SnowboardBindings: 'SnowboardBindings',
  SnowboardJackets: 'SnowboardJackets',
  ProtectionHelmets: 'ProtectionHelmets',
  JacketsOuterwear: 'JacketsOuterwear',
  Fleece: 'Fleece',
  BaseLayer: 'BaseLayer',
  ShirtsFlannels: 'ShirtsFlannels',
  HoodiesSweatshirts: 'HoodiesSweatshirts',
  Helmets: 'Helmets',
  Protection: 'Protection',
  GogglesSunglasses: 'GogglesSunglasses',
  Masks: 'Masks',
  GlovesMittens: 'GlovesMittens',
  HatsBeanies: 'HatsBeanies',
  Scarfs: 'Scarfs',
  TravelBags: 'TravelBags',
  Backpacks: 'Backpacks',
  BeltBags: 'BeltBags',
  Pro: 'Pro',
  Beginner: 'Beginner',
  Pants: 'Pants',
};

export const genderCategories = {
  [categoriesDivisions.Snowboarding]: 'Snowboarding',
  [categoriesDivisions.Apparel]: 'Apparel',
};

export const accessoriesCategories = {
  [categoriesDivisions.Accessories]: 'Accessories',
  [categoriesDivisions.Bags]: 'Bags',
};

export const kitsCategories = {
  [categoriesDivisions.Men]: 'Men',
  [categoriesDivisions.Women]: 'Women',
  [categoriesDivisions.Kids]: 'Kids',
};

export const genderSubCategories = {
  [categoriesDivisions.Snowboarding]: {
    [categoriesSubdivisions.Snowboards]: { title: 'Snowboards', route: getProductsUrl },
    [categoriesSubdivisions.SnowboardBoots]: { title: 'Boots' },
    [categoriesSubdivisions.SnowboardBindings]: { title: 'Bindings' },
    [categoriesSubdivisions.ProtectionHelmets]: { title: 'Helmets' },
    [categoriesSubdivisions.GogglesSunglasses]: { title: 'Goggles' },
  },
  [categoriesDivisions.Apparel]: {
    [categoriesSubdivisions.JacketsOuterwear]: { title: 'Jackets' },
    [categoriesSubdivisions.Pants]: { title: 'Pants' },
    [categoriesSubdivisions.Fleece]: { title: 'Fleece' },
    [categoriesSubdivisions.BaseLayer]: { title: 'Base Layer' },
    [categoriesSubdivisions.ShirtsFlannels]: { title: 'Shirts & Flannels' },
    [categoriesSubdivisions.HoodiesSweatshirts]: { title: 'Hoodies & Sweatshirts' },
  },
};

export const accessoriesSubCategories = {
  [categoriesDivisions.Accessories]: {
    [categoriesSubdivisions.Helmets]: { title: 'Helmets' },
    [categoriesSubdivisions.Protection]: { title: 'Protection' },
    [categoriesSubdivisions.GogglesSunglasses]: { title: 'Goggles & Helmets' },
    [categoriesSubdivisions.GlovesMittens]: { title: 'Gloves & Mittens' },
    [categoriesSubdivisions.HatsBeanies]: { title: 'Hats & Beanies' },
    [categoriesSubdivisions.Scarfs]: { title: 'Scarfs' },
  },
  [categoriesDivisions.Bags]: {
    [categoriesSubdivisions.Backpacks]: { title: 'Backpacks' },
    [categoriesSubdivisions.TravelBags]: { title: 'Travel Bags' },
    [categoriesSubdivisions.BeltBags]: { title: 'Belt Bags' },
  },
};

export const kitsSubCategories = {
  [categoriesDivisions.Men]: {
    [categoriesSubdivisions.Beginner]: { title: "Snowboard Beginners Men's Kit" },
    [categoriesSubdivisions.Pro]: { title: "Snowboard Pro Men's Kit" },
  },
  [categoriesDivisions.Women]: {
    [categoriesSubdivisions.Beginner]: { title: "Snowboard Beginners Women's Kit" },
    [categoriesSubdivisions.Pro]: { title: "Snowboard Pro Women's Kit" },
  },
  [categoriesDivisions.Kids]: {
    [categoriesSubdivisions.Beginner]: { title: "Snowboard Beginners Kids' Kit" },
    [categoriesSubdivisions.Pro]: { title: "Snowboard Pro Kids' Kit" },
  },
};

export const categories = {
  [navigationItems.Men]: genderCategories,
  [navigationItems.Women]: genderCategories,
  [navigationItems.Kids]: genderCategories,
  [navigationItems.Accessories]: accessoriesCategories,
  [navigationItems.Kits]: kitsCategories,
  [navigationItems.Trips]: undefined,
};

export const subCategories = {
  [navigationItems.Men]: genderSubCategories,
  [navigationItems.Women]: genderSubCategories,
  [navigationItems.Kids]: genderSubCategories,
  [navigationItems.Accessories]: accessoriesSubCategories,
  [navigationItems.Kits]: kitsSubCategories,
  [navigationItems.Trips]: undefined,
};

export const locationStateFields = {
  section: 'section',
  category: 'category',
  subCategory: 'subCategory',
};

export const defaultLocationStateObject = {
  [locationStateFields.section]: navigationItems.Men,
  [locationStateFields.category]: categoriesDivisions.Snowboarding,
  [locationStateFields.subCategory]: categoriesSubdivisions.Snowboards,
};

export const notificationTypes = {
  newNotification: 'newNotification',
  clearNotification: 'clearNotification',
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const fieldNames = {
  firstName: 'firstName',
  lastName: 'lastName',
  address1: 'address1',
  address2: 'address2',
  zipCode: 'zipCode',
  city: 'city',
  state: 'state',
  country: 'country',
  countryId: 'countryId',
  phone: 'phone',
  email: 'email',
  birthday: 'birthday',
  mailingList: 'mailingList',
  cardName: 'cardName',
  cardNumber: 'cardNumber',
  cardMonth: 'cardMonth',
  cardYear: 'cardYear',
  cardCode: 'cardCode',
  password: 'password',
  passwordConfirmation: 'passwordConfirmation',
  newPassword: 'newPassword',
  newPasswordConfirmation: 'newPasswordConfirmation',
};

const monthsList = [
  '01 - January',
  '02 - February',
  '03 - March',
  '04 - April',
  '05 - May',
  '06 - June',
  '07 - July',
  '08 - August',
  '09 - September',
  '10 - October',
  '11 - November',
  '12 - December',
].map((item, index) => {
  return {
    name: item,
    value: index + 1,
  };
});

export const months = [
  {
    name: 'Select...',
    value: false,
  },
  ...monthsList,
];

const yearsList = range(new Date().getFullYear(), new Date().getFullYear() + 10, 1).map((item) => ({
  name: item,
  value: item,
}));

export const years = [
  {
    name: 'Select...',
    value: false,
  },
  ...yearsList,
];

export const states = [
  {
    name: 'Select...',
    value: false,
  },
  ...statesRaw.map((item) => ({
    name: item,
    value: item,
  })),
];

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const facebookAccessToken = 'fblst_4397339637004110';

export const MAX_ITEMS_PER_PAGE = 10;

export const MODAL_TEXT = 'Are you sure you want to delete the product from your Wishlist?';

// Some products have measurement of there size by length e.g. 'Snowboard'.
// The array of value those product are present here.
export const LIST_OF_CATEGORIES_WITH_LENGTH = [5];
