import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';

import GoogleIcon from '../../../assets/images/google.png';
import { googleAppId, googleAppScope, googleRedirectURL } from '../../../utils/constants';

const GoogleButton = ({ label, handleGoogleTokenId }) => {
  const responseGoogle = (response) => {
    if (response.tokenId) {
      handleGoogleTokenId(response);
    }
  };

  return (
    <GoogleLogin
      clientId={googleAppId}
      scope={googleAppScope}
      redirectUri={googleRedirectURL}
      autoLoad={false}
      render={({ onClick, isDisabled }) => (
        <button
          onClick={onClick}
          type="button"
          className="sign-in-sign-up-with-google"
          disabled={isDisabled}
        >
          <img src={GoogleIcon} alt={label} />
          <span>{label}</span>
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

GoogleButton.propTypes = {
  label: PropTypes.string,
  handleGoogleTokenId: PropTypes.func,
};

GoogleButton.defaultProps = {
  label: '',
  handleGoogleTokenId: () => {},
};

export default GoogleButton;
