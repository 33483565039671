import React, { useEffect, useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { filtersList, itemsPerPage, sortByOptions } from '../../../../utils/constants';
import './MetaInfoSection.scss';

interface IMetaInfo {
  [x: string]: string | number;
}

interface IMetaInfoProps {
  metaInfo: IMetaInfo;
  handleMetaInfoChange: (value: IMetaInfo) => void;
}

const selectOptions = sortByOptions.map((item, index) => (
  <MenuItem key={index} value={item.name}>
    {item.name}
  </MenuItem>
));

const MetaInfoSection = ({ metaInfo, handleMetaInfoChange }: IMetaInfoProps) => {
  const [currentMetaInfo, setCurrentMetaInfo] = useState<IMetaInfo>({
    ...metaInfo,
    name: 'More popular first',
  });

  // { value: unknown } is used because if material-ui types documentation; if it is not used there will be TS error.
  const handleNumberChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentMetaInfo({
      ...currentMetaInfo,
      [filtersList.PageSize]: parseInt(e.target.value as string, 10),
    });
  };

  const handleSortByChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = sortByOptions.find((item) => item.name === e.target.value);

    setCurrentMetaInfo({
      ...currentMetaInfo,
      [filtersList.Sorting]: value!.value.sorting,
      [filtersList.Sort]: value!.value.sort,
      name: value!.name,
    });
  };

  useEffect(() => {
    handleMetaInfoChange({
      [filtersList.Sort]: currentMetaInfo[filtersList.Sort],
      [filtersList.Sorting]: currentMetaInfo[filtersList.Sorting],
      [filtersList.PageSize]: currentMetaInfo[filtersList.PageSize],
    });
  }, [currentMetaInfo.name, currentMetaInfo[filtersList.PageSize]]);

  return (
    <div className="sorting-block">
      <div className="sorting-block-element">
        <FormLabel>Sort by:</FormLabel>
        <FormControl variant="outlined">
          <Select value={currentMetaInfo.name} onChange={handleSortByChange}>
            {selectOptions}
          </Select>
        </FormControl>
      </div>
      <div className="sorting-block-element">
        <FormLabel>Products on page:</FormLabel>
        <RadioGroup value={currentMetaInfo[filtersList.PageSize]} onChange={handleNumberChange}>
          {itemsPerPage.map((item) => (
            <FormControlLabel value={item} key={item} control={<Radio />} label={item} />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default MetaInfoSection;
