import React from 'react';
import { Link } from 'react-router-dom';

import locations from '../../utils/locations';
import { categories, subCategories, SHOULD_RELOAD } from '../../utils/constants';
import './NavigationDropdown.scss';
import CreateKitBanner from './CreateKitBanner/CreateKitBanner';

interface INavigationDropdownProps {
  activeFilter: string;
}

interface IMapSubCategoryToLinkArgs {
  key: string;
  value: string;
  activeFilter: string;
  division: string;
}

const mapSubCategoryToLink = ({
  key,
  value,
  activeFilter,
  division,
}: IMapSubCategoryToLinkArgs) => (
  <Link
    key={value}
    to={{
      pathname: locations.subCategoryPage(activeFilter, division, key),
      state: {
        section: activeFilter,
        [SHOULD_RELOAD]: true,
      },
    }}
  >
    {value}
  </Link>
);

export const NavigationDropdown = ({ activeFilter }: INavigationDropdownProps) => (
  <div className="NavigationDropdown">
    <div className="NavigationDropdownContent">
      {Object.entries(categories[activeFilter]!).map((category, index) => (
        <div className="NavigationDropdownCol" key={index}>
          <div className="NavigationDropdownTitle">
            <Link to={{ pathname: locations.categoryPage(activeFilter, category[0]) }}>
              {category[0]}
            </Link>
          </div>
          {Object.entries(subCategories[activeFilter]![category[0]]).map((item) =>
            mapSubCategoryToLink({
              key: item[0],
              value: item[1]!.title,
              activeFilter: activeFilter,
              division: category[0],
            })
          )}
        </div>
      ))}
      <div className="ProductsSliderWrapper">
        <CreateKitBanner />
      </div>
    </div>
  </div>
);
