import React, { Dispatch, SetStateAction } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PaymentForm } from './PaymentForm';
import { stripePublicKey } from '../../../../../../utils/constants';
import { IOrderInfo } from '../../../../../../TypesAndInterfaces/Interfaces';

const stripePromise = loadStripe(stripePublicKey);

interface IStripeContainerProps {
  order: IOrderInfo | null;
  clearShoppingCartAndOrder: () => void;
  setShowResultPopup: Dispatch<SetStateAction<string>>;
  userEmail: string;
}

export const StripeContainer = ({
  order,
  clearShoppingCartAndOrder,
  setShowResultPopup,
  userEmail,
}: IStripeContainerProps) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        order={order}
        clearShoppingCartAndOrder={clearShoppingCartAndOrder}
        setShowResultPopup={setShowResultPopup}
        userEmail={userEmail}
      />
    </Elements>
  );
};
