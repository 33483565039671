import React, { useContext, useState } from 'react';
import { Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getStoredUser } from '../../../redux/reducers/selectors';
import { EditProfileForm } from './Forms/EditProfileForm';
import { ResetPasswordForm } from './Forms/ResetPasswordForm';
import { convertDate } from '../../../utils';
import { ModalWrapper } from '../../../Components/ModalWrapper/ModalWrapper';
import { AUTH_API } from '../../../services/auth.services';
import { ConfirmationPopup } from '../../../Components/Popup/ConfirmationPopup';
import { Popup } from '../../../Components/Popup/Popup';
import { NotificationsContext } from '../../../contexts/contexts';
import { notificationTypes } from '../../../utils';
import { IUserOrders } from '../../../TypesAndInterfaces/Interfaces/User';

interface IInfoProps {
  setShowResultPopup: (value: boolean) => void;
}

export const Info = (props: IInfoProps) => {
  const user = useSelector(getStoredUser);
  const { userInfo } = user;
  const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showActiveOrdersModal, setActiveOrdersModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { notifications, setNotifications } = useContext(NotificationsContext);
  const birthDate = userInfo.birthday ? convertDate(userInfo.birthday) : '';
  const hasActiveOrders = userInfo.orders
    ? userInfo.orders.some(
        (order: IUserOrders) => order.state != 'Delivered' && order.state != 'Cancelled'
      )
    : false;

  const closeModal = () => {
    if (showEditProfileModal) {
      setShowEditProfileModal(false);
    }
    if (showResetPasswordModal) {
      setShowResetPasswordModal(false);
    }
    if (showActiveOrdersModal) {
      setActiveOrdersModal(false);
    }
    if (isDeleteModalOpen) {
      setIsDeleteModalOpen(false);
    }
  };

  const onEditProfileClick = () => {
    setShowEditProfileModal(true);
  };

  const onResetPasswordClick = () => {
    setShowResetPasswordModal(true);
  };

  const onDeleteAccountClick = () => {
    hasActiveOrders ? setActiveOrdersModal(true) : setIsDeleteModalOpen(true);
  };

  const onSubmitDelete = async () => {
    setIsDeleteModalOpen(false);
    if (!hasActiveOrders) {
      const result = await AUTH_API.sendDeleteAccountConfirmEmail();
      setNotifications([
        ...notifications,
        {
          type: notificationTypes.info,
          message: result.data,
          id: Date.now(),
        },
      ]);
    } else {
      setActiveOrdersModal(true);
    }
  };

  const modalProps = {
    open: showEditProfileModal || showResetPasswordModal,
    onClose: closeModal,
  };

  let deleteModalProps = {
    open: false,
    onClose: () => {},
    children: <></>,
  };

  let activeOrdersProps = {
    open: false,
    onClose: () => {},
    children: <></>,
  };

  if (isDeleteModalOpen) {
    deleteModalProps = {
      open: isDeleteModalOpen,
      onClose: closeModal,
      children: (
        <>
          <ConfirmationPopup
            closeModal={closeModal}
            handleClick={onSubmitDelete}
            isLoading={false}
            title={'Are you sure you want to delete your account?'}
            confirmText={'confirm'}
            cancelText={'cancel'}
          />
        </>
      ),
    };
  }

  if (showActiveOrdersModal) {
    const activeOrdersModalText =
      'Your account cannot be deleted as you have an active order. You will be able to delete your account once all the orders are delivered';

    activeOrdersProps = {
      open: showActiveOrdersModal,
      onClose: closeModal,
      children: (
        <>
          <Popup
            onClose={closeModal}
            open={showActiveOrdersModal}
            title={'Warning!'}
            text={activeOrdersModalText}
          />
        </>
      ),
    };
  }

  return (
    <div className="profile-block">
      <div className="profile-block-header">
        <p>Info</p>
        <span className="span-as-link" onClick={onEditProfileClick}>
          Edit
        </span>
      </div>
      <div className="profile-block-content">
        <div className="profile-description">
          <p>
            <b>{fullName}</b>
          </p>
          <p>
            <b>{userInfo.email}</b>
          </p>
          <p>
            <b>{birthDate}</b>
          </p>
        </div>
        <div className="profile-menu">
          <ul>
            <li>
              <Link onClick={onResetPasswordClick}>Reset your password?</Link>
            </li>
            <li>
              <Link onClick={onDeleteAccountClick}>Delete your account</Link>
            </li>
          </ul>
        </div>
      </div>

      <ModalWrapper {...modalProps}>
        <>
          {showEditProfileModal && (
            <EditProfileForm setShowResultPopup={props.setShowResultPopup} />
          )}
          {showResetPasswordModal && <ResetPasswordForm closeModal={closeModal} />}
        </>
      </ModalWrapper>

      {showActiveOrdersModal && <ModalWrapper {...activeOrdersProps} />}
      {isDeleteModalOpen && <ModalWrapper {...deleteModalProps} />}
    </div>
  );
};
