import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';

import Logo from '../../assets/images/logo.png';
import CardWrapper from '../CardWrapper/CardWrapper';
import SearchComponent from '../SearchComponent/SearchComponent';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';
import UserLinkHeader from '../UserLinkHeader/UserLinkHeader';
import Icon from '../Icon/Icon';
import locations from '../../utils/locations';
import { BurgerButton } from '../BurgerButton/BurgerButton';

import './Header.scss';

export const Header = ({ shoppingCartProductsCount }) => {
  const { pathname } = useLocation();

  const showHeader =
    pathname.match(locations.passwordReset) || pathname.match(locations.confirmEmail);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onBurgerClick = (value) => {
    setIsMenuOpen(value);
  };

  return (
    <header className="Header">
      <CardWrapper>
        <BurgerButton onClick={onBurgerClick} />
        <Link to={locations.homepage} className={'Logo' + (showHeader ? ' centered' : '')}>
          <img src={Logo} alt="BOARDZONE" />
        </Link>
        {!pathname.match(locations.signInAndUpWrapper) && !showHeader && (
          <>
            <div className="NavigationMenuWrapper">
              <NavigationMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            </div>

            <nav className="NavigationIconMenu">
              <SearchComponent />
              <Link to={locations.shoppingCart} className="CardLink">
                <Badge
                  color="secondary"
                  badgeContent={shoppingCartProductsCount}
                  invisible={!shoppingCartProductsCount}
                >
                  <Icon className="NavigationIcon" icon="basket" />
                </Badge>
              </Link>
              <UserLinkHeader />
            </nav>
          </>
        )}
      </CardWrapper>
    </header>
  );
};
