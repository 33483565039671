import {
  ON_GET_SHIPPING_INFO_SUCCESS,
  ON_UPDATE_SHIPPING_METHOD_SUCCESS,
  ON_UPDATE_SHIPPING_ADDRESS_SUCCESS,
  CLEAR_SHIPPING_INFO,
} from '../actions/shippingInfo/shippingInfoTypes';
import {
  IShippingAddress,
  IShippingInfo,
  IShippingMethodInfo,
} from '../../TypesAndInterfaces/Interfaces';
import { SHIPPING_INFO_LS } from '../../utils/constants';

export interface IShippingReducer {
  shippingMethod: IShippingMethodInfo;
  shippingAddress: IShippingAddress;
}

const initialState: IShippingReducer = {
  shippingMethod: {
    price: 0,
    shippingMethod: '',
    timeEstimate: '',
    title: '',
  },
  shippingAddress: {
    firstName: '',
    lastName: '',
    city: '',
    zipCode: '',
    country: null,
    phone: '',
    email: '',
    address1: '',
    address2: '',
    state: null,
  },
};

export const shippingInfoReducer = (
  state = initialState,
  action: { type: string; payload: Partial<IShippingInfo> }
) => {
  switch (action.type) {
    case ON_GET_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ON_UPDATE_SHIPPING_METHOD_SUCCESS:
      localStorage.setItem(
        SHIPPING_INFO_LS,
        JSON.stringify({
          ...state,
          shippingMethod: { ...action.payload },
        })
      );

      return {
        ...state,
        shippingMethod: { ...action.payload },
      };
    case ON_UPDATE_SHIPPING_ADDRESS_SUCCESS:
      localStorage.setItem(
        SHIPPING_INFO_LS,
        JSON.stringify({
          ...state,
          shippingAddress: {
            ...state.shippingAddress,
            ...action.payload,
          },
        })
      );

      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          ...action.payload,
        },
      };
    case CLEAR_SHIPPING_INFO:
      return {
        shippingMethod: {},
        shippingAddress: {},
      };
    default:
      return state;
  }
};
