import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import { updateShippingMethod } from '../../../../redux/actions/shippingInfo/shippingInfoActions';
import { ShoppingCartContext, NotificationsContext } from '../../../../contexts/contexts';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import { AddressDetails } from './AddressDetails/AddressDetails';
import { ModalWrapper } from '../../../../Components/ModalWrapper/ModalWrapper';
import { getUserSavedAddresses, saveUserAddress } from '../../../../redux/actions/user/userActions';
import {
  getStoredUser,
  getStoredCountries,
  getStoredShippingInfo,
} from '../../../../redux/reducers/selectors';
import { SavedAddresses } from './SavedAddresses/SavedAddresses';
import locations from '../../../../utils/locations';
import {
  handlePrice,
  makeRequest,
  notificationTypes,
  getOrdersUrl,
  isLoggedIn,
  compareAddresses,
} from '../../../../utils';
import {
  IShippingMethodInfo,
  IShoppingCartProduct,
  IAddress,
  IOrderInfo,
} from '../../../../TypesAndInterfaces/Interfaces';

import './Shipping.scss';

const YOUR_ORDER_HAS_BEEN_SUCCESSFULLY_PLACED = 'Your order has been successfully placed.';

const USADeliveryOptions = ['TwoDays', 'Overnight'];

interface IShippingProps extends RouteComponentProps {
  handleShippingChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedShipping: IShippingMethodInfo | null;
  setSelectedShipping: Dispatch<SetStateAction<IShippingMethodInfo | null>>;
  availableShippingOptions: IShippingMethodInfo[];
  setAvailableShippingOptions: Dispatch<SetStateAction<IShippingMethodInfo[] | []>>;
  setOrder: Dispatch<SetStateAction<null | IOrderInfo>>;
  setShowResultPopup: Dispatch<SetStateAction<string>>;
  setShowEditAddressForm: Dispatch<SetStateAction<Partial<IAddress> | null>>;
}

export const Shipping = ({
  handleShippingChange,
  selectedShipping,
  setSelectedShipping,
  availableShippingOptions,
  setAvailableShippingOptions,
  setOrder,
  setShowResultPopup,
  history,
  setShowEditAddressForm,
}: IShippingProps) => {
  const dispatch = useDispatch();
  const {
    userInfo: { id },
    savedAddresses,
  } = useSelector(getStoredUser);
  const { countries } = useSelector(getStoredCountries);
  const { shippingMethod, shippingAddress } = useSelector(getStoredShippingInfo);

  const { shoppingCartProducts } = useContext(ShoppingCartContext);
  const { notifications, setNotifications } = useContext(NotificationsContext);

  const [isValidForm, setIsValidForm] = useState(false);
  const [shippingRadioOptions, setShippingRadioOptions] = useState<JSX.Element[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddressConfirmation, setShowAddressConfirmation] = useState(false);

  const {
    firstName,
    lastName,
    email,
    phone,
    country,
    state,
    city,
    address1,
    address2,
    zipCode,
  } = shippingAddress;

  const orderBody: IOrderInfo = {
    shippingMethod: shippingMethod?.shippingMethod,
    shippingPrice: shippingMethod?.price,
    paymentMethod: 0,
    fullName: `${firstName} ${lastName}`,
    phone,
    email,
    addressInfo: {
      countryId: country?.id,
      city,
      address: address1,
      postalCode: zipCode,
      usaState: state,
    },
    orderItems: shoppingCartProducts.map((item: IShoppingCartProduct) => ({
      productDetailsId: item.boardDetailId,
      price: item.price,
      quantity: item.amount,
    })),
  };

  const optionToRadio = ({ shippingMethod, title, price, timeEstimate }: IShippingMethodInfo) => {
    return (
      <FormControlLabel
        key={shippingMethod}
        value={shippingMethod}
        //React is smart enough to not pass down props with falsy values, such as undefined
        //so it will only pass down this prop to USA-exclusive radio buttons
        disabled={
          USADeliveryOptions.includes(shippingMethod) ? !orderBody.addressInfo.usaState : undefined
        }
        control={<Radio />}
        label={`${title}: ${handlePrice(price)} ${timeEstimate}`}
      />
    );
  };

  useEffect(() => {
    if (availableShippingOptions.length) {
      const newShipping = availableShippingOptions.find(
        (shipping) => shipping.shippingMethod === shippingMethod.shippingMethod
      );
      setShippingRadioOptions(availableShippingOptions.map(optionToRadio));
      setSelectedShipping(
        newShipping && orderBody.addressInfo.usaState ? newShipping : availableShippingOptions[0]
      );

      !orderBody.addressInfo.usaState &&
        dispatch(updateShippingMethod(availableShippingOptions[0]));
      !shippingMethod.title && dispatch(updateShippingMethod(availableShippingOptions[0]));
    }
  }, [availableShippingOptions, setSelectedShipping, orderBody.addressInfo.usaState]);

  useEffect(() => {
    dispatch(getUserSavedAddresses(id));
  }, [id]);

  const redirectToOrderReview = () => history.push(locations.orderReview);

  const onOpenAddressConfirmation = () => {
    setShowAddressConfirmation(true);
  };

  const onCloseAddressConfirmation = () => {
    setShowAddressConfirmation(false);
  };

  const placeOrder = () => {
    setOrder(orderBody);

    setNotifications([
      ...notifications,
      {
        type: notificationTypes.success,
        message: YOUR_ORDER_HAS_BEEN_SUCCESSFULLY_PLACED,
        id: Date.now(),
      },
    ]);

    if (compareAddresses(savedAddresses, shippingAddress) && savedAddresses.length >= 3) {
      console.log('too much adresses');
      savedAddresses.shift();
      dispatch(
        saveUserAddress({
          address1,
          address2,
          city,
          zipCode,
          countryId: country?.id,
          state,
          customerId: id,
        })
      );
    } else if (compareAddresses(savedAddresses, shippingAddress)) {
      dispatch(
        saveUserAddress({
          address1,
          address2,
          city,
          zipCode,
          countryId: country?.id,
          state,
          customerId: id,
        })
      );
    }
    redirectToOrderReview();
  };

  const modalProps = {
    open: showAddressConfirmation,
    onClose: onCloseAddressConfirmation,
    children: (
      <>
        <div className="shipping-address-confirmation">
          <div className="title">Please confirm your shipping address:</div>
          <div className="shipping-address-content">
            <div>{`${firstName} ${lastName}`}</div>
            <div>{address1}</div>
            <div>{address2}</div>
            <div>{`${city}, ${state ? `${state} ${zipCode}` : `${zipCode}`}, ${
              country?.name
            }`}</div>
          </div>
          <div className="buttons-wrapper">
            <MainButton className="btn-confirm" onClick={placeOrder}>
              Confirm
            </MainButton>
            <button type="button" onClick={onCloseAddressConfirmation}>
              Cancel
            </button>
          </div>
        </div>
      </>
    ),
  };

  if (!countries.length) return null;

  return (
    <>
      {isLoggedIn() && <SavedAddresses setShowEditAddressForm={setShowEditAddressForm} />}
      <AddressDetails
        setIsValidForm={setIsValidForm}
        setAvailableShippingOptions={setAvailableShippingOptions}
      />
      {shippingRadioOptions.length && selectedShipping && (
        <div className="ShippingMethod">
          <div className="ShippingMethodTitle">Shipping method</div>
          <FormControl>
            <RadioGroup
              key={selectedShipping.title}
              value={selectedShipping.shippingMethod}
              onChange={handleShippingChange}
            >
              {shippingRadioOptions}
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <MainButton
        className="sign-in-sign-up-submit-button"
        onClick={onOpenAddressConfirmation}
        isLoading={isLoading}
        isDisabled={!isValidForm}
      >
        Place an order
      </MainButton>
      {showAddressConfirmation && <ModalWrapper {...modalProps} />}
    </>
  );
};
