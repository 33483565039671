import * as Yup from 'yup';
import { expirationMonth } from 'card-validator';
import { debounce } from 'lodash/function';

import { fieldNames } from './constants';
import { makeRequest } from './utils';
import { getCheckUserExistsUrl } from './endpoints';

const disallowedChars = 'Do not allow to enter special characters';
const mustStartWith = 'This field must start with latin letter';

const invalidEmailMsg = 'Please enter Email in format name@domain.end';
const pswMustContain =
  'Password must be 8 to 20 characters, contain at least one uppercase, one lowercase, one special character, and one number';
const invalidFirstNameMsg =
  'First Name must be 2 to 50 characters, cannot contain special characters and numbers';
const invalidLastNameMsg =
  'Last Name must be 2 to 50 characters, cannot contain special characters and numbers';
const invalidPhoneNumMsg = 'Phone number must start from digits or "+" and must be 9 to 25 digits ';
const invalidAddressMsg = (num) =>
  `Address ${num} must be 1 to 50 characters, contain at least one of English or international characters`;
const invalidCityMsg =
  'City must be 2 to 50 characters, contain at least one of English or international characters';
const invalidZipcodeMsg =
  'Zip Code must be 2 to 11 characters, contain at least one of English or international characters';

const g = 'g';
const i = 'i';

const engChars = 'a-z';
const UppEngChars = 'A-Z';
const interChars =
  'àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð';
const digits = '0-9';
const space = ' ';

const handleMin = (name, value) => `${name} must be at least ${value} characters long`;
const handleMax = (name, value) => `${name} must be no more than ${value} characters long`;

const add = (a, b) => a + b;
const allowChars = (flags) => (...vals) => new RegExp(`^[${vals.reduce(add)}]*$`, flags);
const startWith = (flags) => (...vals) => new RegExp(`^[${vals.reduce(add)}]`, flags);
const contain = (flags) => (...vals) => new RegExp(`[${vals.reduce(add)}]`, flags);

const allowCharsGI = allowChars(g + i);
const startWithGI = startWith(g + i);
const containGI = contain(g + i);

const containG = contain(g);

const phoneRegExp = /^\+?[0-9\s().-]{9,25}$/;

const specChars = {
  [fieldNames.firstName]: ",.'-",
  [fieldNames.lastName]: ",.'-",
  [fieldNames.phone]: '.()-+',
  [fieldNames.address1]: ",.'\\-#",
  [fieldNames.address2]: ",.'\\-#",
  [fieldNames.city]: ",.'\\-#",
  [fieldNames.zipCode]: '\\-',
  [fieldNames.cardName]: "-.'",
  [fieldNames.password]: '!"#$%&\'()*+,-./:;<=>?[\\]^_{|}~`@',
};

const firstNameRules = Yup.string()
  .required('Please enter First Name')
  .min(2, () => invalidFirstNameMsg)
  .max(50, () => invalidFirstNameMsg)
  .matches(/^([^0-9]*)$/, invalidFirstNameMsg)
  .matches(allowCharsGI(engChars, interChars, specChars[fieldNames.firstName]), invalidFirstNameMsg)
  .matches(startWithGI(engChars, interChars), invalidFirstNameMsg);

const lastNameRules = Yup.string()
  .required('Please enter Last Name')
  .min(2, () => invalidLastNameMsg)
  .max(50, () => invalidLastNameMsg)
  .matches(/^([^0-9]*)$/, invalidLastNameMsg)
  .matches(
    allowCharsGI(space, engChars, interChars, specChars[fieldNames.lastName]),
    invalidLastNameMsg
  )
  .matches(startWithGI(engChars, interChars), invalidLastNameMsg);

const hasAtSign = (value) => /@/.test(value);

const checkEmailExists = async (value, resolve) => {
  makeRequest('get', getCheckUserExistsUrl(), {}, { userEmail: value })
    .then((response) => {
      resolve(!response.data);
    })
    .catch(() => {
      resolve(false);
    });
};

const debouncedCheckUserEmail = debounce(checkEmailExists, 500);

const emailRules = Yup.string()
  .required('Please enter Email')
  .email(invalidEmailMsg)
  .test('The localpart length', invalidEmailMsg, (value) => {
    return hasAtSign(value) ? value.split('@')[0].length <= 64 : false;
  })
  .test('The localpart validation', invalidEmailMsg, (value) => {
    // The localpart can only contain the following characters
    return /^[a-z0-9!#$%&'*+-/=?^_`{|}~]+$/i.test(value?.split('@')[0]);
  })
  .test('The domain length', invalidEmailMsg, (value) => {
    return hasAtSign(value) ? value.split('@')[1].length < 254 : false;
  })
  .test('The dots validation for domain', invalidEmailMsg, (value) => {
    // Checking if is here more than a one dot
    return hasAtSign(value) ? !/\.{2,}/.test(value.split('@')[1]) : false;
  })
  .test('The domain labels validation', invalidEmailMsg, (value) => {
    // The domain labels can only contain the following characters below and max domain label length is 64 symbols
    return hasAtSign(value)
      ? value
          .split('@')[1]
          .split('.')
          .every((label) => /^[a-z0-9-]+$/i.test(label) && label.length < 64)
      : false;
  })
  .test('The hyphen validation for domain labels', invalidEmailMsg, (value) => {
    if (hasAtSign(value)) {
      const labels = value.split('@')[1].split('.');
      return !labels.some((item) => /^-|-$/.test(item));
    }
    return false;
  })
  .test('The hyphen validation for domain', invalidEmailMsg, (value) => {
    // Checking if is here more than a one hyphen
    return hasAtSign(value) ? !/-{2,}/.test(value.split('@')[1]) : false;
  })
  .test('The top-level domain validation', invalidEmailMsg, (value) => {
    if (hasAtSign(value)) {
      const labels = value.split('@')[1].split('.');
      return /^[a-z]{2,}$/i.test(labels[labels.length - 1]);
    }
    return false;
  });

const passwordRules = Yup.string()
  .required('Please enter Password')
  .min(8, () => pswMustContain)
  .max(20, () => pswMustContain)
  .matches(allowCharsGI(engChars, digits, specChars[fieldNames.password]), pswMustContain)
  .matches(containG(UppEngChars), pswMustContain)
  .matches(containG(engChars), pswMustContain)
  .matches(containG(digits), pswMustContain)
  .matches(containG(specChars[fieldNames.password]), pswMustContain);

const passwordConfirmationRules = Yup.string()
  .required('Please enter Confirm Password')
  .oneOf([Yup.ref(fieldNames.password), null], 'Passwords do not match');

const newPasswordConfirmationRules = Yup.string()
  .required('Please enter Confirm Password')
  .oneOf([Yup.ref(fieldNames.newPassword), null], 'Passwords do not match');

export const editProfileValidationSchema = Yup.object({
  [fieldNames.firstName]: firstNameRules,
  [fieldNames.lastName]: lastNameRules,
  [fieldNames.email]: emailRules,
});

export const changePasswordValidationSchema = Yup.object({
  [fieldNames.password]: passwordRules,
  [fieldNames.newPassword]: passwordRules,
  [fieldNames.newPasswordConfirmation]: newPasswordConfirmationRules,
});

export const editAddressValidationSchema = Yup.object({
  [fieldNames.address1]: Yup.string()
    .required('Please enter Address 1')
    .min(1, () => invalidAddressMsg(1))
    .max(50, () => invalidAddressMsg(1))
    .matches(
      allowCharsGI(space, digits, engChars, interChars, specChars[fieldNames.address1]),
      invalidAddressMsg(1)
    )
    .matches(containGI(engChars, interChars), invalidAddressMsg(1)),

  [fieldNames.address2]: Yup.string()
    .min(1, () => invalidAddressMsg(2))
    .max(50, () => invalidAddressMsg(2))
    .matches(
      allowCharsGI(space, digits, engChars, interChars, specChars[fieldNames.address2]),
      invalidAddressMsg(2)
    )
    .matches(containGI(engChars, interChars), invalidAddressMsg(2)),

  [fieldNames.city]: Yup.string()
    .required('Please enter City')
    .min(2, () => invalidCityMsg)
    .max(50, () => invalidCityMsg)
    .matches(allowCharsGI(space, engChars, interChars, specChars[fieldNames.city]), invalidCityMsg)
    .matches(containGI(engChars, interChars), invalidCityMsg),

  [fieldNames.zipCode]: Yup.string()
    .required('Please enter Zip Code')
    .min(2, () => invalidZipcodeMsg)
    .max(11, () => invalidZipcodeMsg)
    .matches(
      allowCharsGI(space, digits, engChars, specChars[fieldNames.zipCode]),
      invalidZipcodeMsg
    )
    .matches(containGI(digits, engChars), invalidZipcodeMsg),

  [fieldNames.countryId]: Yup.mixed().when((_value, schema) =>
    schema.notOneOf([undefined, false], 'Country is required')
  ),

  [fieldNames.state]: Yup.mixed().when([fieldNames.countryId], (value, schema) =>
    value === 232 ? schema.notOneOf([undefined, false, null], 'State is required') : schema
  ),
});

export const signInValidationSchema = Yup.object({
  [fieldNames.email]: emailRules,
  [fieldNames.password]: passwordRules,
});

export const signUpValidationSchema = Yup.object({
  [fieldNames.firstName]: firstNameRules,
  [fieldNames.lastName]: lastNameRules,
  [fieldNames.email]: emailRules,
  [fieldNames.password]: passwordRules,
  [fieldNames.passwordConfirmation]: passwordConfirmationRules,
});

export const forgotPasswordValidationSchema = Yup.object({
  [fieldNames.email]: emailRules,
});

export const resetPasswordValidationSchema = Yup.object({
  [fieldNames.password]: passwordRules,
  [fieldNames.passwordConfirmation]: passwordConfirmationRules,
});

export const addressValidationSchema = Yup.object({
  [fieldNames.email]: emailRules.test(
    'The unique email',
    'This email address is already taken. Please log into your account.',
    (value) => {
      const user = localStorage.getItem('userData');
      if (user !== null) {
        return true;
      }
      return new Promise((resolve) => debouncedCheckUserEmail(value, resolve));
    }
  ),

  [fieldNames.firstName]: firstNameRules,

  [fieldNames.lastName]: lastNameRules,

  [fieldNames.phone]: Yup.string()
    .required('Please enter Phone Number')
    .min(9, () => invalidPhoneNumMsg)
    .max(25, () => invalidPhoneNumMsg)
    .matches(phoneRegExp, invalidPhoneNumMsg),

  [fieldNames.address1]: Yup.string()
    .required('Please enter Address 1')
    .min(1, () => invalidAddressMsg(1))
    .max(50, () => invalidAddressMsg(1))
    .matches(
      allowCharsGI(space, digits, engChars, interChars, specChars[fieldNames.address1]),
      invalidAddressMsg(1)
    )
    .matches(containGI(engChars, interChars), invalidAddressMsg(1)),

  [fieldNames.address2]: Yup.string()
    .min(1, () => invalidAddressMsg(2))
    .max(50, () => invalidAddressMsg(2))
    .matches(
      allowCharsGI(space, digits, engChars, interChars, specChars[fieldNames.address2]),
      invalidAddressMsg(2)
    )
    .matches(containGI(engChars, interChars), invalidAddressMsg(2)),

  [fieldNames.city]: Yup.string()
    .required('Please enter City')
    .min(2, () => invalidCityMsg)
    .max(50, () => invalidCityMsg)
    .matches(allowCharsGI(space, engChars, interChars, specChars[fieldNames.city]), invalidCityMsg)
    .matches(containGI(engChars, interChars), invalidCityMsg),

  [fieldNames.countryId]: Yup.mixed().when((_value, schema) =>
    schema.notOneOf([undefined, false], 'Country is required')
  ),

  [fieldNames.state]: Yup.mixed()
    .when([fieldNames.countryId], (value, schema) => {
      {
        if (!value) {
          return schema;
        }
        return value === 232 || value === false ? schema.required('State is required') : schema;
      }
    })
    .when((_value, schema) => schema.notOneOf([undefined, false], 'State is required')),

  [fieldNames.zipCode]: Yup.string()
    .required('	Please enter Zip Code')
    .min(2, (info) => invalidZipcodeMsg)
    .max(11, (info) => invalidZipcodeMsg)
    .matches(
      allowCharsGI(space, digits, engChars, specChars[fieldNames.zipCode]),
      invalidZipcodeMsg
    )
    .matches(containGI(digits, engChars), invalidZipcodeMsg),
});

export const creditCardValidationSchema = Yup.object({
  [fieldNames.cardName]: Yup.string()
    .required("Cardholder's name is required")
    .matches(allowCharsGI(space, engChars, specChars[fieldNames.cardName]), disallowedChars)
    .matches(startWithGI(engChars), mustStartWith),

  [fieldNames.cardNumber]: Yup.string()
    .required('Card number is required')
    .min(13, (info) => handleMin('Card number', info.min))
    .max(19, (info) => handleMax('Card number', info.max))
    .matches(allowCharsGI(digits), disallowedChars),

  [fieldNames.cardYear]: Yup.mixed()
    .required('Expiration year is required')
    .notOneOf([false, undefined], 'Year is required'),

  [fieldNames.cardMonth]: Yup.mixed()
    .required('Expiration month is required')
    .notOneOf([false, undefined], 'Month is required')
    .when(fieldNames.cardYear, {
      is: (val) => val === new Date().getFullYear(),
      then: Yup.mixed().test('valid current month', 'This card is expired', (val) => {
        return expirationMonth(val.toString()).isValidForThisYear;
      }),
      otherwise: Yup.mixed().test('valid month', 'This card is expired', (val) => {
        return expirationMonth(val.toString()).isValid;
      }),
    }),

  [fieldNames.cardCode]: Yup.string()
    .required('Security code is required')
    .min(3, (info) => handleMin('Security code', info.min))
    .max(3, (info) => handleMax('Security code', info.max))
    .matches(allowCharsGI(digits), disallowedChars),
});
