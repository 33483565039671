import './SimpleForm.scss';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { getStoredUser } from '../../../../redux/reducers/selectors';
import { updateUserInfo } from '../../../../redux/actions/user/userActions';
import { editProfileValidationSchema } from '../../../../utils/validationRules';
import { fieldNames, inputTypes, notificationTypes } from '../../../../utils/constants';
import SimpleFormWrapper from '../../../../Components/SimpleFormWrapper/SimpleFormWrapper';
import { DatePicker } from './DatePicker';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import {
  IEditProfileFormData,
  IEditProfileFormFields,
} from '../../../../TypesAndInterfaces/Interfaces';
import { NotificationsContext } from '../../../../contexts/contexts';

const USER_INFO_UPDATE_FAILURE = 'User info update failure.';

interface IEditProfileFormProps {
  setShowResultPopup: (value: boolean) => void;
}

export const EditProfileForm = ({ setShowResultPopup }: IEditProfileFormProps) => {
  const dispatch = useDispatch();

  const user = useSelector(getStoredUser);
  const { userInfo, isLoading } = user;

  const [isValid, setIsValid] = useState(false);
  const { firstName, lastName, email, birthday } = userInfo;
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate | string | null>(birthday);
  const { notifications, setNotifications } = useContext(NotificationsContext);

  const fields: IEditProfileFormFields = {
    [fieldNames.firstName]: { title: 'First Name' },
    [fieldNames.lastName]: { title: 'Last Name' },
    [fieldNames.email]: { title: 'Email', type: inputTypes.email },
  };

  const [formData, setFormData] = useState<IEditProfileFormData>({
    [fieldNames.firstName]: firstName,
    [fieldNames.lastName]: lastName,
    [fieldNames.email]: email,
  });

  const handleValueChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setFormData((data) => ({ ...data, [field]: e.target.value }));
  };

  const handleSubmit = async () => {
    const userUpdatedInfo = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      birthday: selectedDate,
    };

    dispatch(
      updateUserInfo({
        ...userUpdatedInfo,
        handleError,
        handleSuccess: setShowResultPopup,
      })
    );
  };

  const handleError = () => {
    setNotifications([
      ...notifications,
      {
        type: notificationTypes.error,
        message: USER_INFO_UPDATE_FAILURE,
        id: Date.now(),
      },
    ]);
  };

  return (
    <div className="profile-popup-form">
      <div className="profile-popup-form-header">Info &amp; Preferences</div>
      <SimpleFormWrapper
        fields={fields}
        initialValues={formData}
        handleValueChange={handleValueChange}
        setIsValidForm={setIsValid}
        validationSchema={editProfileValidationSchema}
      />
      <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <MainButton
        className="sign-in-sign-up-submit-button my-account-buttons"
        onClick={handleSubmit}
        isDisabled={!isValid || !(selectedDate === null || typeof selectedDate === 'string')}
        isLoading={isLoading}
      >
        Update
      </MainButton>
    </div>
  );
};
