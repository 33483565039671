import React from 'react';
import { Link } from 'react-router-dom';

import { IProduct } from '../../TypesAndInterfaces/Interfaces';
import { getImagesUrl } from '../../utils/endpoints';
import locations from '../../utils/locations';
import './ProductLinkContent.scss';

export interface IProductLinkContentProps
  extends Omit<IProduct, 'totalQuantity' | 'categoryId' | 'description'> {
  oldPrice?: number;
  showContent?: boolean;
}

const ProductLinkContent = ({
  id,
  primaryImageId,
  model,
  brand,
  price,
  oldPrice,
  showContent = false,
}: IProductLinkContentProps) => (
  <span className="ProductLink" style={showContent ? {} : { display: 'none' }}>
    {showContent && (
      <Link to={locations.productPage(String(id))}>
        <div className="ProductLinkContent">
          <div className="ProductLinkImgOuter">
            <img src={getImagesUrl({ id: primaryImageId })} className="ProductLinkImg" alt="" />
          </div>
          <div className="ProductLinkDesc">
            <div className="ProductLinkTitle">{model}</div>
            <div className="ProductLinkSubTitle">{brand}</div>
            <div className="ProductLinkPrice">{price}</div>
            <div className="ProductLinkOldPrice">{oldPrice}</div>
          </div>
        </div>
      </Link>
    )}
  </span>
);

export default ProductLinkContent;
