import './BurgerButton.scss';
import React from 'react';

interface IBurgerButtonProps {
  onClick: () => void;
}

export const BurgerButton = ({ onClick }: IBurgerButtonProps) => {
  return (
    <div className="burgerContainer" onClick={onClick}>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};
