import React from 'react';
import { useHistory } from 'react-router-dom';

import './DeleteDataFacebook.scss';
import { MainButton } from '../../Components/Buttons/MainButton';
import { scrollToTop } from '../../utils/utils';

const TEXT_DELETION_DATA_INSTRUCTION = (
  <div className="container-text">
    <div className="title-text">Data deletion!</div>
    <div className="header-text">Facebook Data Deletion Instructions URL</div>
    <section className="content-text">
      <p>
        BoardZone website has an option for Facebook login. According to Facebook policy, below
        Deletion Instructions. If you want to delete your activities for BoardZone, remove remove
        remove your information by following these steps:
      </p>
      <ol>
        <li>
          Go to your Facebook Account&apos;s Setting & Privacy. Click “Settings”&nbsp;&nbsp;
          <a
            className="link-text"
            href="https://www.facebook.com/privacy/checkup/?source=settings_and_privacy"
          >
            TO FACEBOOK
          </a>
        </li>
        <li>
          Look for “Apps and Websites” and you will see all of the apps and websites you linked
          Facebook.
        </li>
        <li>Search and Click “BoardZone” in the search bar.</li>
        <li>Scroll and click “Remove”.</li>
        <li>Congratulations, you have successfully removed your website activities.</li>
      </ol>
    </section>
    <div className="header-text">Boardzone Data Deletion Instructions</div>
    <section className="content-text">
      <ol>
        <li>Go to your BoardZone My Account page </li>
        <li>On Info page and tap on “Delete Your Account”</li>
        <li>
          Pop-up window appears with the text &quot;Are you sure you want to delete your
          account?&quot; and there are 2 buttons &quot;CONFIRM&quot; and &quot;CANCEL&quot;
        </li>
        <li>Press &quot;CONFIRM&quot;</li>
        <li>
          The message &quot;Account deletion complete&quot; is shown and the button &quot;GO TO
          BOARDZONE&quot; is available
        </li>
        <li>User clicks on &quot;GO TO BOARDZONE&quot; </li>
        <li>User is redirected to Home page</li>
      </ol>
    </section>

    <p className="content-text">* If you have active orders, Your Account can&quot;t be deleted.</p>
  </div>
);

const DeleteDataFacebook = () => {
  const history = useHistory();

  const redirectToHomePage = () => {
    history.goBack();
  };

  scrollToTop();

  return (
    <div className="delete-data-facebook">
      <div className="delete-data-facebook-container">
        <div className="delete-data-facebook-button">
          <MainButton className="back-button" onClick={redirectToHomePage}>
            Back to Previous page
          </MainButton>
        </div>
        <div className="delete-data-text">{TEXT_DELETION_DATA_INSTRUCTION}</div>
      </div>
    </div>
  );
};

export default DeleteDataFacebook;
