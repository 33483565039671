import React, { useState, useEffect, cloneElement } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import useLocationChange from '../../hooks/useLocationChange';

const PopperWrapper = ({
  anchorEl,
  isOpenBooleans,
  handleLocationChange,
  placement = 'bottom-end',
  className = '',
  children,
}) => {
  const { state, search } = useLocation();
  const history = useHistory();
  const [{ state: initialState, search: initialSearch }] = useState({
    state,
    search,
  });
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  useEffect(() => {
    return history.listen(() => {
      handleLocationChange();
    });
  }, [history]);

  const isOpenFromParent = isOpenBooleans.reduce((acc, val) => val || acc, false);

  const handleClickAway = () => {
    if (!isOpenFromParent) setIsPopperOpen(false);
  };

  // useLocationChange(
  //   () => {
  //     console.log('initialState', initialState);
  //     console.log('state', state);
  //     if (initialState !== state) handleLocationChange();
  //   },
  //   { state, search }
  // );

  useEffect(() => {
    if (isOpenFromParent) setIsPopperOpen(true);
  }, [isOpenFromParent]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        open={isPopperOpen || isOpenFromParent}
        anchorEl={anchorEl}
        placement={placement}
        className={className}
      >
        {cloneElement(children, { setIsPopperOpen: setIsPopperOpen })}
      </Popper>
    </ClickAwayListener>
  );
};

export default PopperWrapper;
