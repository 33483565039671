import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { brandsList, filtersList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '../CheckboxStyle.scss';

const BrandCheckbox = ({
  filters,
  clearFilters,
  defaultFilters,
  handleFiltersChange,
}: IFiltersProps) => {
  const values = brandsList.map((value, index) => {
    return {
      index,
      title: value,
      isChecked: false,
    };
  });

  const [currentValuesState, setCurrentValuesState] = useState(values);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValues = currentValuesState.map((item) => {
      if (item.title === event.target.value) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });
    setCurrentValuesState(changedValues);
  };

  useEffect(() => {
    const filterTitles = currentValuesState
      .filter((item) => item.isChecked === true)
      .map((value) => value.title);
    if (filterTitles.length > 0) {
      const brandsFilters = { [filtersList.Brand]: filterTitles };
      handleFiltersChange({ ...filters, ...brandsFilters });
    } else {
      handleFiltersChange(defaultFilters!);
    }
  }, [currentValuesState]);

  useEffect(() => {
    if (clearFilters) {
      const changedValues = currentValuesState.map((item) => {
        return {
          ...item,
          isChecked: false,
        };
      });
      setCurrentValuesState(changedValues);
    }
  }, [clearFilters]);

  return (
    <div className="CheckboxContainer CheckboxContainerWide">
      <div className="CheckboxTitle">Brands:</div>
      <FormControl>
        {currentValuesState.map(({ title, isChecked, index }) => (
          <FormControlLabel
            control={
              <div>
                <Checkbox checked={isChecked} onChange={handleChange} value={title} />
              </div>
            }
            key={index}
            label={title}
          />
        ))}
      </FormControl>
    </div>
  );
};

export default BrandCheckbox;
