import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_ALL_CATEGORIES_SUCCESS,
} from '../actions/categories/categoriesTypes';
import { ICategory } from '../../TypesAndInterfaces/Interfaces';

interface ICategoriesReducer {
  categories: ICategory[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: ICategoriesReducer = {
  categories: [],
  isLoading: false,
  isError: false,
};

export const categoriesReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ON_FETCHING_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ON_FETCHING_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case ON_GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        categories: [
          ...action.payload.map((category: ICategory) => ({
            ...category,
            value: category.id,
          })),
        ],
      };
    default:
      return state;
  }
};
