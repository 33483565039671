import React from 'react';

const SavedPaymentSettings = () => {
  return (
    <div className="profile-block">
      <div className="profile-block-header">
        <p>Saved payment settings</p>
        <span className="span-as-link">Add a credit card</span>
      </div>
    </div>
  );
};

export default SavedPaymentSettings;
