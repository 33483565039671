import { IUserInfo, IAddress, IUserPreferences } from './../../TypesAndInterfaces/Interfaces';
import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_USER_INFO_SUCCESS,
  ON_UPDATE_USER_INFO_SUCCESS,
  CLEAR_USER_INFO,
  USER_TABS_COUNT,
  ON_GET_USER_SAVED_ADDRESSES_SUCCESS,
  ON_EDIT_USER_SAVED_ADDRESS_SUCCESS,
  ON_SAVE_USER_ADDRESS_SUCCESS,
  ON_DELETE_USER_SAVED_ADDRESS_SUCCESS,
  ON_GET_USER_PREFERENCES_SUCCESS,
  ON_EDIT_USER_PREFERENCES_SUCCESS,
  ON_SAVE_PREFERENCES_SUCCESS,
} from '../actions/user/userTypes';
import {
  ON_ADD_WISHLIST_ITEM,
  ON_REMOVE_WISHLIST_ITEM,
} from '../actions/wishListItem/wishListItemTypes';

export interface IUserReducer {
  userInfo: IUserInfo;
  isError: boolean;
  isLoading: boolean;
  tabsCount: number;
  savedAddresses: IAddress[];
  notificationPreferences: IUserPreferences | null;
}

const initialState: IUserReducer = {
  userInfo: {} as IUserInfo,
  isError: false,
  isLoading: false,
  tabsCount: 1,
  savedAddresses: [],
  notificationPreferences: {} as IUserPreferences,
};

export const userReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ON_FETCHING_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ON_FETCHING_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case ON_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userInfo: action.payload,
      };
    case ON_UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: {},
        isError: false,
        isLoading: false,
      };
    case USER_TABS_COUNT:
      return {
        ...state,
        tabsCount: action.payload,
      };
    case ON_ADD_WISHLIST_ITEM:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          /* @ts-ignore */
          wishlistItems: [action.payload, ...state.userInfo.wishlistItems],
        },
      };
    case ON_REMOVE_WISHLIST_ITEM:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          wishlistItems: state.userInfo.wishlistItems.filter((item: { id: number }) => {
            return item.id !== action.payload;
          }),
        },
      };
    case ON_GET_USER_SAVED_ADDRESSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        savedAddresses: action.payload,
      };
    case ON_EDIT_USER_SAVED_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        savedAddresses: state.savedAddresses.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case ON_SAVE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        savedAddresses: [...state.savedAddresses, action.payload],
      };
    case ON_DELETE_USER_SAVED_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        savedAddresses: state.savedAddresses.filter((item) => item.id !== action.payload.addressId),
      };
    case ON_GET_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notificationPreferences: action.payload,
      };
    case ON_SAVE_PREFERENCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notificationPreferences: action.payload,
      };
    case ON_EDIT_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        notificationPreferences: action.payload,
      };
    default:
      return state;
  }
};
