import React, { Dispatch, SetStateAction } from 'react';

import ProductSlider from '../ProductSlider/ProductSlider';
import BoardLogo from '../../../../assets/images/board-logo.png';
import { TableGeneralInfo } from '../TableGeneralInfo/TableGeneralInfo';
import ProductCharacteristics from '../ProductCharacteristics/ProductCharacteristics';
import SelectsProduct from '../SelectsProduct/SelectsProduct';
import { IImage, IProductData, ISizes } from '../../../../TypesAndInterfaces/Interfaces';

import './GeneralIhfo.scss';

interface IGeneralInfoProps {
  info: IProductData;
  sizes: ISizes;
  setSizes: Dispatch<SetStateAction<ISizes | null>>;
  images: IImage[];
}

export const GeneralInfo = ({ info, sizes, setSizes, images }: IGeneralInfoProps) => {
  const { brand, model, price, productDetails } = info;
  const color = productDetails[0]?.productAttributes?.find((attr) => attr.attributeName === 'Color')
    ?.attributeValue;
  const usage = productDetails[0]?.productAttributes
    ?.filter((attr) => attr.attributeName === 'Usage')
    ?.map((attr) => attr.attributeValue);
  const riderLevel = productDetails[0]?.productAttributes
    ?.filter((attr) => attr.attributeName === 'RidingLevel')
    ?.map((attr) => attr.attributeValue);
  const flex = productDetails[0]?.productAttributes?.find((attr) => attr.attributeName === 'Flex')
    ?.attributeValue;

  return (
    <section className="GeneralInfo">
      <div className="GeneralInfoInner">
        <ProductSlider images={images} />
        <div className="ProductMoreInfo">
          <img className="BoardLogo" src={BoardLogo} alt="world greenest snowboard" />
          <SelectsProduct sizes={sizes} setSizes={setSizes} info={info} />
          <TableGeneralInfo
            generalInfo={{
              brand,
              model,
              color,
              price,
            }}
          />
          <ProductCharacteristics flex={flex} riderLevel={riderLevel} usage={usage} />
        </div>
      </div>
    </section>
  );
};
