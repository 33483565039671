import React from 'react';

import './Loader.scss';

export const Loader = () => {
  return (
    <div className="loader-big">
      <img src="/loader_user.svg" alt="loading" className="loader-img" />
    </div>
  );
};
