import React, { useEffect, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { FormControlLabel, FormControl, Select, Checkbox } from '@material-ui/core';

import { getStoredCountries, getStoredShippingInfo } from '../../../../../redux/reducers/selectors';
import { updateShippingAddress } from '../../../../../redux/actions/shippingInfo/shippingInfoActions';
import { Form } from './Form/Form';
import {
  getShippingsUrl,
  fieldNames,
  states,
  addressValidationSchema,
  arrayToSelectOptions,
  initialFormValues,
  makeRequest,
  shippingOptions,
} from '../../../../../utils';
import { IShippingMethodInfo } from '../../../../../TypesAndInterfaces/Interfaces';
import './AddressDetails.scss';

const constantFields = {
  [fieldNames.firstName]: {
    title: 'First Name',
    placement: 0,
    classNames: 'FirstName',
  },
  [fieldNames.lastName]: {
    title: 'Last Name',
    placement: 1,
    classNames: 'LastName',
  },
  [fieldNames.address1]: {
    title: 'Address 1',
    placement: 2,
    classNames: 'Address1',
  },
  [fieldNames.address2]: {
    title: 'Address 2',
    placement: 3,
    isOptional: true,
    classNames: 'Address2',
  },
  [fieldNames.zipCode]: {
    title: 'Zip Code',
    placement: 4,
    classNames: 'ZipCode',
  },
  [fieldNames.city]: { title: 'City', placement: 5, classNames: 'City' },
  [fieldNames.state]: {
    title: 'State',
    placement: 6,
    initialValue: false,
    selectFrom: arrayToSelectOptions(states),
    disabledValue: '-',
    classNames: 'State',
  },
  [fieldNames.email]: {
    title: 'Email for Order Confirmation',
    placement: 9,
    classNames: 'Email',
  },
  [fieldNames.phone]: { title: 'Phone', placement: 8, classNames: 'Phone' },
};

interface IAddressDetailsProps {
  setIsValidForm: Dispatch<SetStateAction<boolean>>;
  setAvailableShippingOptions: Dispatch<SetStateAction<IShippingMethodInfo[] | []>>;
}

export const AddressDetails = ({
  setIsValidForm,
  setAvailableShippingOptions,
}: IAddressDetailsProps) => {
  const { countries } = useSelector(getStoredCountries);
  const { shippingAddress } = useSelector(getStoredShippingInfo);
  const { country } = shippingAddress;
  const dispatch = useDispatch();

  const fields = {
    ...constantFields,
    [fieldNames.countryId]: {
      title: 'Country',
      placement: 7,
      initialValue: false,
      selectFrom: countries,
      classNames: 'Country',
    },
  };

  const americanId = countries.find((elem: { name: string }) => elem.name === 'United States')?.id;

  const handleValueChange = (field: string) => (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value } = e.target;
    dispatch(updateShippingAddress({ [field]: value }));
  };

  const handleCountryChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = e.target;

    dispatch(
      updateShippingAddress({
        state: null,
        country: {
          id: value,
          name: value ? countries[value as number].name : '',
        },
      })
    );
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    dispatch(
      updateShippingAddress({
        mailingList: checked,
      })
    );
  };

  useEffect(() => {
    (async () => {
      const { data } = await makeRequest(
        'get',
        getShippingsUrl({
          parameters: {
            countryId: country?.id || americanId,
          },
        })
      );

      if (data) {
        setAvailableShippingOptions(
          data.map((option: Partial<IShippingMethodInfo>) => ({
            ...option,
            ...shippingOptions.find((item) => item.title === option.shippingMethod),
          }))
        );
      }
    })();
  }, [country?.id, americanId, setAvailableShippingOptions]);

  const initialValues = initialFormValues(
    fields,
    { ...shippingAddress, countryId: shippingAddress.country?.id },
    true
  );

  if (!initialValues || !countries.length) return null;

  return (
    <div className="AddressDetails">
      <div className="FieldsRequired">All fields required unless marked as optional</div>
      <div className="AddressDetailsFrom">
        <Formik
          initialValues={initialValues}
          validationSchema={addressValidationSchema}
          onSubmit={() => {}}
        >
          {(props) => (
            <Form
              {...props}
              fields={fields}
              values={initialValues}
              handleValueChange={handleValueChange}
              handleCountryChange={handleCountryChange}
              setIsValidForm={setIsValidForm}
              country={country}
            />
          )}
        </Formik>
        <FormControlLabel
          className="AddEmailToPromo"
          label={`Please add me to your email list so I can receive
           special promotions and product updates
          `}
          control={
            <Checkbox
              checked={shippingAddress.mailingList || false}
              onChange={handleCheckboxChange}
            />
          }
        />
      </div>
    </div>
  );
};
