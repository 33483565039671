import React from 'react';
import Paper from '@material-ui/core/Paper';

import './NoMatchComponent.scss';

interface INoMatchProps {
  message: string;
}

const NoMatchComponent = ({ message }: INoMatchProps) => {
  return (
    <Paper elevation={3} className="no-match-container">
      <span className="no-match-text">{message}</span>
    </Paper>
  );
};

export default NoMatchComponent;
