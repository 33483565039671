import 'date-fns';
import React, { Dispatch } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

interface IDatePickerProps {
  selectedDate: MaterialUiPickersDate | string | null;
  setSelectedDate: Dispatch<React.SetStateAction<MaterialUiPickersDate | string | null>>;
}

export const DatePicker = ({ selectedDate, setSelectedDate }: IDatePickerProps) => {
  const handleDateChange = (date: MaterialUiPickersDate | null) => {
    setSelectedDate(date);

    if (moment(date).isValid() && +moment(date).format('X') < +moment().format('X')) {
      const ISOStringDate = moment(date).set({ h: 16, m: 0 }).toISOString();

      setSelectedDate(ISOStringDate);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="date-picker-heading">
        <span>Birth Date</span>
      </div>
      <KeyboardDatePicker
        className="date-picker"
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        id="date-picker-inline"
        value={selectedDate}
        onChange={handleDateChange}
        disableFuture={true}
        autoOk
        openTo="year"
        maxDate={new Date()}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
