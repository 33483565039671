import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const middleware = [thunkMiddleware];

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

export type RootStoreType = ReturnType<typeof store.getState>;
