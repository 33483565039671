import React from 'react';
import upperFirst from 'lodash/upperFirst';

import { colorsList } from '../../../../utils/constants';
import './TableGeneralInfo.scss';

interface IGeneralTableInfoProps {
  generalInfo: IGeneralTableInfo;
}

interface IGeneralTableInfo {
  brand: string;
  model: string;
  price: number;
  color?: string;
}

const priceColor = '#daff17';

export const TableGeneralInfo = ({ generalInfo }: IGeneralTableInfoProps) => {
  const tableContent = Object.entries(generalInfo).map(([key, value]) => {
    if (key === 'price') return [key, `$${value.toFixed(2)}`];
    if (key === 'color')
      return [
        key,
        colorsList.find((color) => color === generalInfo.color) || upperFirst(generalInfo.color),
      ];
    return [key, value];
  });

  return (
    <div>
      <table className="Tablet">
        <tbody>
          <tr>
            <th className="TabletHeading">General Info</th>
          </tr>
          <tr>
            <td>
              <table className="TabletContent">
                <tbody>
                  {tableContent.map(([key, value]) => (
                    <tr key={key}>
                      <td className="TableTitle">{upperFirst(key)}</td>
                      <td
                        className="TableDesc"
                        style={key === 'price' ? { color: priceColor } : undefined}
                      >
                        {value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
