import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS,
  ON_ADD_WISHLIST_ITEM,
  ON_REMOVE_WISHLIST_ITEM,
} from './wishListItemTypes';
import { MAX_ITEMS_PER_PAGE } from '../../../utils/constants';
import { makeRequest } from '../../../utils/utils';
import { getWishListUrl } from '../../../utils/endpoints';
import { IWishListData } from '../../../TypesAndInterfaces/Interfaces/Product';

export interface Parameters {
  Page: number;
  ItemsPerPage: number;
}

export interface ItemData {
  customerId: number;
  productDetailId?: number;
  quantity: number;
}

export const getWishListPage = (page: number) => async (
  dispatch: (arg0: { type: string; payload?: IWishListData }) => void
) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest(
    'get',
    getWishListUrl({
      parameters: {
        Page: page,
        ItemsPerPage: MAX_ITEMS_PER_PAGE,
      },
    })
  );

  if (data) {
    localStorage.setItem('wishList', JSON.stringify(data));
    return dispatch({
      type: ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const addToWishlistItem = (
  customerId: number,
  productDetailId: number,
  quantity: number
) => async (dispatch: (arg0: { type: string; payload?: ItemData }) => void) => {
  const { data, err } = await makeRequest('post', getWishListUrl(), {
    customerId,
    productDetailId,
    quantity,
  });

  //TODO
  if (data) {
    return dispatch({
      type: ON_ADD_WISHLIST_ITEM,
      payload: data,
    });
  }
};

export const removeWishlistItem = (id: number | undefined) => async (
  dispatch: (arg0: { type: string; payload?: number }) => void
) => {
  const { err } = await makeRequest('delete', getWishListUrl({ id }));

  if (!err) {
    return dispatch({
      type: ON_REMOVE_WISHLIST_ITEM,
      payload: id,
    });
  }
};
