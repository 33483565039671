import { makeRequest } from '../utils';
import {
  getSendResetPasswordEmailUrl,
  getLoginUrl,
  getRegisterUrl,
  getPasswordResetUrl,
  getCheckResetPasswordTokenUrl,
  getChangePasswordUrl,
  getRegisterConfirmUrl,
  getSendRegisterConfirmEmailUrl,
  getFacebookLoginUrl,
  getGoogleLoginUrl,
  getDeleteAccountConfirmEmailUrl,
  getDeleteAccountUrl,
} from '../utils/endpoints';

export const AUTH_API = {
  async signIn(payload) {
    return makeRequest('post', getLoginUrl(), payload);
  },
  async signUp(payload) {
    return makeRequest('post', getRegisterUrl(), payload);
  },
  async confirmRegistration(payload) {
    return makeRequest('post', getRegisterConfirmUrl(), payload);
  },
  async sendRegisterConfirmEmail(email) {
    return makeRequest('post', getSendRegisterConfirmEmailUrl(), {}, { email });
  },
  async sendDeleteAccountConfirmEmail() {
    return makeRequest('post', getDeleteAccountConfirmEmailUrl(), {});
  },
  async deleteAccount(token) {
    return makeRequest('delete', getDeleteAccountUrl(), {}, token);
  },
  async sendOrderInformationEmail(payload) {
    return makeRequest('post', getOrderInformationEmail(), {}, payload);
  },
  async sendResetPasswordEmail(payload) {
    return makeRequest('post', getSendResetPasswordEmailUrl(), {}, payload);
  },
  async resetPassword(payload) {
    return makeRequest('post', getPasswordResetUrl(), payload);
  },
  async checkPasswordResetToken(payload) {
    return makeRequest(
      'get',
      getCheckResetPasswordTokenUrl({
        parameters: payload,
      })
    );
  },
  async changePassword(payload) {
    return makeRequest('post', getChangePasswordUrl(), payload);
  },
  async loginFacebook(accessToken) {
    return makeRequest('post', getFacebookLoginUrl(), { accessToken });
  },
  async loginGoogle(tokenId) {
    return makeRequest('post', getGoogleLoginUrl(), { tokenId });
  },
};
