import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { filtersList, boardWidthList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '@material-ui/core/FormGroup';

const SelectBoardWidth = ({ filters, clearFilters, handleFiltersChange }: IFiltersProps) => {
  const [chosenWidth, setChosenWidth] = useState<string[]>([]);

  const widthSelectOptions = boardWidthList.map((level: string, index) => (
    <MenuItem key={index} value={level}>
      <Checkbox checked={chosenWidth.indexOf(level) > -1} />
      <ListItemText primary={level} />
    </MenuItem>
  ));

  // { value: unknown } is used because if material-ui types documentation; if it is not used there will be TS error.
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    setChosenWidth(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    const levelsReq = {
      [filtersList.BoardWidth]: chosenWidth,
    };

    if (chosenWidth.length !== boardWidthList.length) {
      handleFiltersChange({ ...filters, ...levelsReq });
    }
  }, [chosenWidth]);

  useEffect(() => {
    if (clearFilters) {
      setChosenWidth([]);
    }
  }, [clearFilters]);

  return (
    <div className="SelectRigthWrapper">
      <div className="SelectRigthContainer">
        <div className="SelectTitle">
          <span>Board Width:</span>
        </div>
        <FormControl variant="outlined">
          <Select
            value={chosenWidth}
            input={<OutlinedInput />}
            multiple
            displayEmpty
            onChange={(e: React.ChangeEvent<{ value: unknown }>): void => handleChange(e)}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <span>All</span>;
              }
              if ((selected as string[]).length === boardWidthList.length) {
                return <span>All</span>;
              }
              return (selected as string[]).join(', ');
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {widthSelectOptions}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectBoardWidth;
