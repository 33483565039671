import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getUserInfo } from '../../redux/actions/user/userActions';
import { getStoredUser } from '../../redux/reducers/selectors';
import { loginOptions } from '../../utils/constants';
import { setLoginProvider, setTokens, clearTokens, clearLoginProvider } from '../../utils/utils';
import { AUTH_API } from '../../services/auth.services';
import FacebookButton from './FacebookButton/FacebookButton';
import './LoginWithFacebook.scss';

const LoginWithFacebook = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector(getStoredUser);
  const { userInfo } = user;

  const loggedWithFacebook = userInfo.loggedWith === loginOptions.facebook;

  const label = loggedWithFacebook ? 'Sign out with Facebook' : 'Sign in with Facebook';

  const handleFacebookAccessToken = async ({ accessToken }) => {
    const { data, err } = await AUTH_API.loginFacebook(accessToken);

    if (data) {
      setTokens(data.token, data.refreshToken);
      setLoginProvider(loginOptions.facebook);
      closeModal();
      dispatch(getUserInfo(loginOptions.facebook));
    }

    if (err) {
      clearTokens();
      clearLoginProvider();
    }
  };

  return <FacebookButton label={label} handleFacebookAccessToken={handleFacebookAccessToken} />;
};

LoginWithFacebook.propTypes = {
  closeModal: PropTypes.func,
};
LoginWithFacebook.defaultProps = {
  closeModal: () => {},
};

export default LoginWithFacebook;
