import React from 'react';
import Snow from 'react-snow-effect';

import './PostHomePage.scss';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import '../../../../Components/Icon/Icon';
import CardWrapper from '../../../../Components/CardWrapper/CardWrapper';

const PostHomePage = (props) => (
  <section className="PostHomePage">
    <Snow />
    <CardWrapper>
      <h2>Our Crew At Sunny Days in Aspen</h2>
      <div className="PostDescription">
        <p>
          Aspen’s ski history has more forgotten details than all the closets in town. Nevertheless,
          in this timeline of ski-related events and anecdotal tidbits — from dipping floor boards
          in pig slop to fashion curved ski tips to the opening of the Silver Queen gondola — we
          have come a long way.
        </p>
        <p>
          This compilation comes from old newspapers, native Aspenites’ and locals’ recollections,
          archived cassette-taped interviews, history books, graduate theses, Aspen Skiing Co.
          records, and the indefatigable help of the Aspen Historical Society. Where discrepancies
          occurred, a consensus of sources pointed toward accuracy.
        </p>
      </div>
      <MainButton textColor="#000" fontSize="16px" ProductImg="images/rectangle-small.png">
        read more
      </MainButton>
    </CardWrapper>
  </section>
);

export default PostHomePage;
