import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import {
  editNotificationPreferences,
  getUserPreferences,
  saveNotificationPreferences,
} from '../../redux/actions/user/userActions';
import { getStoredUser } from '../../redux/reducers/selectors';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import HelpText from '../Account/Components/HelpText/HelpText';
import Title from '../Account/Components/Title';
import StyledSwitch from './StyledSwitch/StyledSwitch';
import locations from '../../utils/locations';
import { isLoggedIn } from '../../utils';

import './PreferencesPage.scss';

const PreferencesPage = () => {
  const dispatch = useDispatch();

  const user = useSelector(getStoredUser);
  const { userInfo, notificationPreferences } = user;

  if (!isLoggedIn()) {
    return <Redirect to={locations.homepage} />;
  }

  useEffect(() => {
    userInfo!.id && dispatch(getUserPreferences(userInfo!.id));
  }, [userInfo!.id]);

  let reqBody = notificationPreferences;

  const handleChange = (toggleId: string, toggleValue: boolean) => {
    if (notificationPreferences.id) {
      reqBody = {
        ...reqBody,
        [toggleId]: toggleValue,
        id: notificationPreferences.id,
      };

      dispatch(editNotificationPreferences(reqBody));
    } else {
      delete reqBody.id;
      reqBody = {
        ...reqBody,
        [toggleId]: toggleValue,
        customerId: userInfo.id,
      };

      dispatch(saveNotificationPreferences(reqBody));
    }
  };

  return (
    <main className="preferences-page with-space-for-header">
      <div className="preferences-header">
        <div className="preferences-content-wrapper">
          <div className="preferences-title">
            <Title title="My Account" />
          </div>
          <HelpText textAlign={'right'} />
        </div>
      </div>
      <div className="preferences-content-wrapper">
        <Sidebar />
        <div className="preferences-content">
          <div className="preferences-account-welcome">
            Hi {userInfo?.firstName || 'User'} {userInfo?.lastName}
          </div>
          <div className="preferences-toggle-wrapper">
            <p className="preferences-toggle-title">Notification settings</p>
            <div className="preferences-toggles">
              <p className="preferences-toggle-subtitle">Upcoming trips or events</p>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">Email</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="upcomingTripsOrEventsInfoWithEmail"
                  checked={notificationPreferences!.upcomingTripsOrEventsInfoWithEmail || false}
                />
              </FormControl>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">SMS</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="upcomingTripsOrEventsInfoWithSms"
                  checked={
                    (userInfo!.phoneNumber &&
                      notificationPreferences!.upcomingTripsOrEventsInfoWithSms) ||
                    false
                  }
                  disabled={!userInfo.phoneNumber}
                />
              </FormControl>
            </div>
            <div className="preferences-toggles">
              <p className="preferences-toggle-subtitle">Discounts, promotions and sales</p>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">Email</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="discountsPromotionsAndSalesInfoWithEmail"
                  checked={
                    notificationPreferences!.discountsPromotionsAndSalesInfoWithEmail || false
                  }
                />
              </FormControl>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">SMS</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="discountsPromotionsAndSalesInfoWithSms"
                  checked={
                    (userInfo!.phoneNumber &&
                      notificationPreferences!.discountsPromotionsAndSalesInfoWithSms) ||
                    false
                  }
                  disabled={!userInfo.phoneNumber}
                />
              </FormControl>
            </div>
            <div className="preferences-toggles">
              <p className="preferences-toggle-subtitle">New products in the store</p>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">Email</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="newProductsInfoWithEmail"
                  checked={notificationPreferences!.newProductsInfoWithEmail || false}
                />
              </FormControl>
              <FormControl className="preferences-form-control">
                <FormLabel className="preferences-form-label">SMS</FormLabel>
                <StyledSwitch
                  onChange={(e) => handleChange(e.target.id, e.target.checked)}
                  id="newProductsInfoWithSms"
                  checked={
                    (userInfo!.phoneNumber && notificationPreferences!.newProductsInfoWithSms) ||
                    false
                  }
                  disabled={!userInfo.phoneNumber}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PreferencesPage;
