import React from 'react';
import Slider from 'react-slick';

import { Product } from '../Product/Product';
import './ProductsSlider.scss';
import Magnatrection from '../../assets/images/magnatrection.png';
import { sliderSettings } from '../../utils/constants';

export default () => {
  const productsSliderSettings = { ...sliderSettings, slidesToShow: 6 };
  // to be removed
  const gallery = [
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: '/static/media/bitmap-01.e2cb8f6d.png',
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: '/static/media/bitmap-01.e2cb8f6d.png',
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: Magnatrection,
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
    {
      primaryImageId: '/static/media/bitmap-01.e2cb8f6d.png',
      brand: 'Romw',
      price: '$ 320.00',
      model: 'SDS',
    },
  ];

  /* const { data, isLoading, error } = useGetData(
    getBindingsUrl({ parameters: { ItemsPerPage: 6 } }),
  ) */

  return (
    <div className="SlickSlider ProductsSlider">
      {/* {error && <div>{error}</div>} TODO
      {isLoading ? (
        <div>loading</div>
      ) : ( */}
      <Slider {...productsSliderSettings}>
        {gallery.map((item, index) => {
          const { primaryImageId, brand, model, price } = item;

          return (
            <div className="ShowProduct" key={index}>
              <Product
                img={primaryImageId}
                brand={brand}
                model={model}
                price={`${price}`}
                delay={`${index + 0.2}s`}
                imgIsALink
              />
            </div>
          );
        })}
      </Slider>
      {/* )} */}
    </div>
  );
};
