import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import DeleteAccountMessage from './DeleteAccountMessage';
import { getParamsFromQueryString } from '../../utils';
import { deleteUserAccount } from '../../redux/actions/user/userActions';
import { subtitles } from '../,,/../../Pages/EmailRedirectPage/EmailRedirectPage';
import { titles } from '../,,/../../Pages/EmailRedirectPage/EmailRedirectPage';
import { USER_API } from '../../services/user.services';
import { logout } from '../../utils/logout';

const DeleteAccount = () => {
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);

  const { pathname, search } = useLocation();

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { token } = getParamsFromQueryString(search);

  let errorMsg = '';

  useEffect(() => {
    (async () => {
      const { err } = await dispatch(deleteUserAccount({ token }));
      if (err) {
        errorMsg = err.response.data.message;
        setIsUrlInvalid(true);
      }

      await USER_API.logout();
      logout(dispatch);
    })();
  }, [token, pathname]);

  const redirectToHomePage = () => {
    push('/');
  };

  if (isUrlInvalid) {
    return (
      <DeleteAccountMessage
        title={titles.invalidLink}
        subtitle={errorMsg ? errorMsg : subtitles.deletedAccountLink}
        redirectToHomePage={redirectToHomePage}
      />
    );
  } else {
    return (
      <DeleteAccountMessage
        title={titles.accountDeletionComplete}
        subtitle={subtitles.accountDeletion}
        redirectToHomePage={redirectToHomePage}
      />
    );
  }
};

export default DeleteAccount;
