import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { filtersList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '../RangeStyle.scss';

const BoardLengthRange = ({
  filters,
  clearFilters,
  handleFiltersChange,
  setClearFilters,
}: IFiltersProps) => {
  const defaultErrorsState = {
    [filtersList.MinLength]: false,
    [filtersList.MaxLength]: false,
  };

  const [chosenLength, setChosenLength] = useState({
    [filtersList.MinLength]: '',
    [filtersList.MaxLength]: '',
  });
  const [errors, setErrors] = useState(defaultErrorsState);

  const handleChangeMinLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenLength({
      ...chosenLength,
      [filtersList.MinLength]: event.target.value,
    });
  };

  const handleChangeMaxLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) > 200) {
      setErrors({
        ...errors,
        [filtersList.MaxLength]: true,
      });
    } else {
      setErrors({
        ...errors,
        [filtersList.MaxLength]: false,
      });
      setChosenLength({
        ...chosenLength,
        [filtersList.MaxLength]: event.target.value,
      });
    }
  };

  useEffect(() => {
    if (parseInt(chosenLength.MinLength) < 80) {
      setErrors({
        ...errors,
        [filtersList.MinLength]: true,
      });
    } else {
      setErrors({
        ...errors,
        [filtersList.MinLength]: false,
      });
      setClearFilters!(false);
      handleFiltersChange({ ...filters, ...chosenLength });
    }
  }, [chosenLength]);

  useEffect(() => {
    if (clearFilters) {
      setChosenLength({
        [filtersList.MinLength]: '',
        [filtersList.MaxLength]: '',
      });
      setErrors(defaultErrorsState);
    }
  }, [clearFilters]);

  return (
    <div className="Range RangeRight">
      <div className="RangeWrapper">
        <div className="RangeTitle">Length range (cm):</div>
        <div className="RangeInputWrapper">
          <div className="RangeInputContainer">
            <span className="RangeTitle">From: </span>
            <TextField
              error={errors.MinLength}
              onChange={handleChangeMinLength}
              value={chosenLength.MinLength}
              variant="outlined"
              size="small"
              type="number"
              placeholder="80"
              helperText={errors.MinLength && 'min 80 cm'}
            />
          </div>
          <div className="RangeInputContainer">
            <span className="RangeTitle">To: </span>
            <TextField
              error={errors.MaxLength}
              onChange={handleChangeMaxLength}
              value={chosenLength.MaxLength}
              variant="outlined"
              size="small"
              type="number"
              placeholder="200"
              helperText={errors.MaxLength && 'max 200 cm'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardLengthRange;
