import React from 'react';

import { Info } from '../Components/Info';
import { SavedAddress } from '../Components/SavedAddress';
import SavedPaymentSettings from '../Components/SavedPaymentSettings';
import { ModalWrapper } from '../../../Components/ModalWrapper/ModalWrapper';
import { Popup } from '../../../Components/Popup/Popup';

import './DashboardPage.scss';

interface IDashboardPageProps {
  showResultPopup: boolean;
  setShowResultPopup: (value: boolean) => void;
}

export const DashboardPage = ({ showResultPopup, setShowResultPopup }: IDashboardPageProps) => {
  const closeModal = () => {
    setShowResultPopup(false);
  };

  const modalProps = {
    open: showResultPopup,
    onClose: closeModal,
  };

  return (
    <div className="AccountDashboard">
      <Info setShowResultPopup={setShowResultPopup} />
      <SavedAddress setShowResultPopup={setShowResultPopup} />
      <SavedPaymentSettings />
      <ModalWrapper {...modalProps}>
        <Popup title={null} text="Your data has been changed successfully" {...modalProps} />
      </ModalWrapper>
    </div>
  );
};
