import React from 'react';
import { useHistory } from 'react-router-dom';

import { AUTH_API } from '../../services/auth.services';
import { getParamsFromQueryString } from '../../utils';
import { subtitles } from './EmailRedirectPage';
import { MainButton } from '../../Components/Buttons/MainButton';

interface IEmailRedirectMessageProps {
  title: string;
  subtitle: string;
}

export const EmailRedirectMessage = ({ title, subtitle }: IEmailRedirectMessageProps) => {
  const {
    push,
    location: { search },
  } = useHistory();

  const onButtonClick = async () => {
    const { email } = getParamsFromQueryString(search);

    if (subtitle === subtitles.expiredLink) {
      await AUTH_API.sendResetPasswordEmail(email);
    }

    if (subtitle === subtitles.invalidLink) {
      await AUTH_API.sendRegisterConfirmEmail(email);
    }

    push('/');
  };

  return (
    <div className="password-page-container">
      <div className="reset-form-container">
        <div className="reset-form">
          <div className="successful-reset-container">
            <div className="title">{title}</div>
            <span className="subtitle">{subtitle}</span>
          </div>
          <MainButton className="sign-in-sign-up-submit-button confirm-btn" onClick={onButtonClick}>
            {subtitle === subtitles.expiredLink ? 'Resend email' : 'Go to boardzone'}
          </MainButton>
        </div>
      </div>
    </div>
  );
};
