/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { FormHelperText, MenuItem, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { fieldNames, setFormLabel } from '../../../../../../utils';
import { getStoredShippingInfo, getStoredUser } from '../../../../../../redux/reducers/selectors';

const USA = 'United States';
export const DEFAULT_COUNTRY_OPTION = 'Change country';

export const Form = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  setFieldTouched,
  handleValueChange,
  handleCountryChange,
  validateForm,
  fields,
  country,
  setIsValidForm,
  setFieldValue,
}) => {
  const { shippingAddress } = useSelector(getStoredShippingInfo);
  const { userInfo } = useSelector(getStoredUser);
  const isEmailFieldDisabled = !!userInfo.email;

  const onChange = (fieldName) => (e) => {
    e.persist();
    handleChange(e);
    if (fieldName === 'countryId') {
      handleCountryChange(e);
    } else {
      handleValueChange(fieldName)(e);
    }
    validateForm();
  };

  const handleFocus = (name) => (e) => {
    setFieldTouched(name, false, false);
  };

  const handleBlur = (name) => (e) => {
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    setIsValidForm(isValid && country !== DEFAULT_COUNTRY_OPTION);
  }, [isValid, country]);

  useEffect(() => {
    validateForm();
  }, [validateForm, country]);

  // Trick below need for case when user click button 'Ship to this address'.
  // SetTimeout used due to issue with Formik setFieldValue. Approach recommended on Formik issues gitHub page.
  // Values from stored shipping address set and validated.
  // Trick or component in general must be refactored.
  useEffect(() => {
    Object.keys(fields).forEach((fieldName) =>
      setTimeout(() => {
        if (fieldName != 'countryId') {
          setFieldValue(fieldName, shippingAddress[fieldName]);
        } else {
          setFieldValue(fieldName, country?.id);
        }
      }, 0)
    );
  }, [shippingAddress]);

  return (
    <>
      {Object.entries(fields)
        .sort(([keyA, itemA], [keyB, itemB]) => itemA.placement - itemB.placement)
        .map(([key, item]) =>
          //Rendering country select as TextField with select attribute is necessary
          //to validate it with Yup
          //Otherwise Yup would return an object and bring the whole page down
          key === 'countryId' ? (
            <FormControl
              variant="outlined"
              key={key}
              classes={item.classNames ? { root: item.classNames } : {}}
            >
              <span>
                <span className="redAsterisk">* </span>
                {setFormLabel(fields[key])}
              </span>
              <TextField
                name={key}
                select
                value={values[key]}
                variant="outlined"
                error={touched[key] && !!errors.countryId}
                helperText={touched[key] && errors.countryId}
                onChange={onChange(key)}
                onFocus={handleFocus(key)}
                onBlur={handleBlur(key)}
              >
                {fields.countryId.selectFrom.map((option, index) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          ) : key === 'state' && country?.name === USA ? (
            <FormControl
              variant="outlined"
              key={key}
              classes={item.classNames ? { root: item.classNames } : {}}
            >
              <span>
                <span className="redAsterisk">* </span>
                {setFormLabel(fields[key])}
              </span>
              <Select
                value={country ? values[key] : false}
                name={key}
                error={touched[key] && Boolean(errors[key])}
                children={item.selectFrom}
                onChange={onChange(key)}
                onFocus={handleFocus([key])}
                onBlur={handleBlur(key)}
              />
              {touched[key] ? <FormHelperText error={true}>{errors[key]}</FormHelperText> : null}
            </FormControl>
          ) : (
            <Tooltip
              title={
                key === fieldNames.email && isEmailFieldDisabled
                  ? "Email can be changed in User's info & preferences"
                  : ''
              }
              placement="bottom-end"
              arrow
            >
              <FormControl
                variant="outlined"
                key={key}
                classes={item.classNames ? { root: item.classNames } : {}}
              >
                {
                  <span>
                    {item.title === 'Address 2' || item.title === 'State' ? (
                      ''
                    ) : (
                      <span className="redAsterisk">* </span>
                    )}
                    {setFormLabel(fields[key])}
                  </span>
                }
                <TextField
                  variant="outlined"
                  disabled={
                    (item.selectFrom && item.selectFrom.length > 0) ||
                    (key === fieldNames.email && isEmailFieldDisabled)
                  }
                  value={
                    item.selectFrom
                      ? key === 'state'
                        ? item.disabledValue
                        : country || '-'
                      : values[key]
                  }
                  name={key}
                  helperText={touched[key] ? errors[key] : ''}
                  error={touched[key] && Boolean(errors[key])}
                  onChange={onChange(key)}
                  onFocus={handleFocus([key])}
                  onBlur={handleBlur(key)}
                />
              </FormControl>
            </Tooltip>
          )
        )}
    </>
  );
};
