import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setUserTabsCount } from '../redux/actions/user/userActions';

const useTabsCount = () => {
  const dispatch = useDispatch();

  const getTabs = () => {
    return JSON.parse(localStorage.getItem('TabsOpen') || '{}');
  };

  const [tabsCount, setTabsCount] = useState(Object.keys(getTabs()).length || 1);

  const saveTabs = (tabs) => {
    let openTabs = {};

    for (const [key, value] of Object.entries(tabs)) {
      if (value) {
        openTabs[key] = value;
      }
    }

    localStorage.setItem('TabsOpen', JSON.stringify(openTabs));
  };

  const hashExists = () => {
    return sessionStorage.getItem('TabHash');
  };

  const removeTab = () => {
    const hash = hashExists();
    sessionStorage.removeItem('TabHash');
    const tabs = getTabs();
    tabs[hash] = false;
    saveTabs(tabs);
  };

  const handleLoad = () => {
    if (!hashExists()) {
      const hash = `tab_${+new Date()}`;
      sessionStorage.setItem('TabHash', hash);
      const tabs = getTabs();
      tabs[hash] = true;
      saveTabs(tabs);
    }
  };

  const handleStorage = (event) => {
    if (event.key === 'TabsOpen') {
      const count = Object.keys(getTabs()).length;
      if (count !== tabsCount) {
        setTabsCount(count);
      }
    }
  };

  window.addEventListener('storage', handleStorage);

  window.addEventListener('beforeunload', removeTab);

  window.addEventListener('unload', removeTab);

  useEffect(() => {
    handleLoad();
    const count = Object.keys(getTabs()).length;
    setTabsCount(count);
    dispatch(setUserTabsCount(tabsCount));

    return () => {
      window.removeEventListener('beforeunload', removeTab);
      window.removeEventListener('unload', removeTab);
      window.removeEventListener('storage', handleStorage);
    };
  }, [dispatch, tabsCount]);
};

export default useTabsCount;
