import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_ALL_CATEGORIES_SUCCESS,
} from './categoriesTypes';
import { makeRequest } from '../../../utils/utils';
import { getCategoriesUrl } from '../../../utils/endpoints';
import { ICategory } from '../../../TypesAndInterfaces/Interfaces';

export const getAllCategories = () => async (
  dispatch: (arg0: { type: string; payload?: ICategory[] }) => void
) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('get', getCategoriesUrl());

  if (data) {
    return dispatch({
      type: ON_GET_ALL_CATEGORIES_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};
