import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { getStoredUser, getStoredCountries } from '../../../../redux/reducers/selectors';
import { editUserSavedAddress, saveUserAddress } from '../../../../redux/actions/user/userActions';
import Form from './Form';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import { editAddressValidationSchema, fieldNames, states } from '../../../../utils';
import './EditAddressForm.scss';

export const EditAddressForm = ({
  isEdit,
  formData,
  setFormData,
  editAddressID,
  closeModal,
  setShowResultPopup = (value) => {},
}) => {
  const { userInfo } = useSelector(getStoredUser);
  const { countries } = useSelector(getStoredCountries);

  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fields = {
    [fieldNames.address1]: { title: 'Address 1', placement: 0, classNames: 'Address1' },
    [fieldNames.address2]: {
      title: 'Address 2 (optional)',
      placement: 1,
      classNames: 'Address2',
    },
    [fieldNames.city]: { title: 'City', placement: 2, classNames: 'City' },
    [fieldNames.zipCode]: { title: 'Zip Code', placement: 3, classNames: 'ZipCode' },
    [fieldNames.countryId]: {
      title: 'Country',
      placement: 4,
      initialValue: false,
      selectFrom: countries,
      classNames: 'Country',
    },
    [fieldNames.state]: {
      title: 'State',
      placement: 5,
      initialValue: false,
      selectFrom: states,
      classNames: 'State',
    },
  };

  const unitedStatesID = countries?.find((elem) => elem.name === 'United States')?.id;

  const initialValues = formData;

  const { address1, address2, city, zipCode, countryId } = formData;
  let { state } = formData;

  const handleValueChange = (field) => (e) => {
    setFormData((data) => ({ ...data, [field]: e.target.value }));
  };

  const handleSubmit = async () => {
    if (isValid) {
      setIsLoading(true);

      if (isEdit) {
        state = countryId === unitedStatesID ? state : null;

        dispatch(
          editUserSavedAddress({
            id: editAddressID,
            address1,
            address2,
            city,
            zipCode,
            countryId,
            state,
            customerId: userInfo.id,
          })
        );
      } else {
        dispatch(
          saveUserAddress({
            address1,
            address2,
            city,
            zipCode,
            countryId,
            state,
            customerId: userInfo.id,
          })
        );
      }

      setShowResultPopup(true);
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <div className="profile-popup-form">
      <div className="profile-popup-form-header">Saved Addresses</div>
      <Formik initialValues={initialValues} validationSchema={editAddressValidationSchema}>
        {(props) => (
          <Form
            {...props}
            fields={fields}
            handleValueChange={handleValueChange}
            setIsValidForm={setIsValid}
            unitedStatesID={unitedStatesID}
          />
        )}
      </Formik>
      <MainButton
        className="sign-in-sign-up-submit-button my-account-buttons"
        onClick={handleSubmit}
        isDisabled={!isValid}
        isLoading={isLoading}
      >
        {isEdit ? 'Edit' : 'Save'}
      </MainButton>
    </div>
  );
};
