import { useState, useCallback } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';

import { googleAppId, googleAppScope, googleRedirectURL } from '../utils/constants';

const useGoogle = () => {
  const [successLoginResponse, setSuccessLoginResponse] = useState(null);
  const [successLogoutResponse, setSuccessLogoutResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);

  const onSuccess = useCallback(
    (response) => {
      setSuccessLoginResponse(response);
    },
    [setSuccessLoginResponse]
  );

  const onLogoutSuccess = useCallback(() => {
    setSuccessLogoutResponse('Logged out Success');
  }, [setSuccessLogoutResponse]);

  const onFailure = useCallback(
    (error) => {
      setErrorResponse(error);
    },
    [setErrorResponse]
  );
  const onRequest = () => {};

  const { signIn } = useGoogleLogin({
    uxMode: 'popup',
    prompt: 'consent',
    clientId: googleAppId,
    scope: googleAppScope,
    redirectUri: googleRedirectURL,
    onSuccess,
    onFailure,
    onRequest,
  });

  const { signOut } = useGoogleLogout({
    clientId: googleAppId,
    onLogoutSuccess,
    onFailure,
  });

  const onGoogleSignin = useCallback(() => signIn(), [signIn]);

  const onGoogleLogout = useCallback(() => {
    // Revoke All Scopes
    gapi.auth2.getAuthInstance().disconnect();

    signOut();
  }, [signOut]);

  return {
    successLoginResponse,
    successLogoutResponse,
    errorResponse,
    onGoogleSignin,
    onGoogleLogout,
  };
};

export default useGoogle;
