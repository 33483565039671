import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';

import { Payment } from './Sections/Payment/Payment';
import { AddressInfo } from './Sections/AddressInfo/AddressInfo';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import locations from '../../../../utils/locations';
import { scrollToTop } from '../../../../utils';
import { getStoredShippingInfo } from '../../../../redux/reducers/selectors';
import { IOrderInfo } from '../../../../TypesAndInterfaces/Interfaces';

interface IWrapperProps extends RouteComponentProps {
  order: IOrderInfo | null;
  clearShoppingCartAndOrder: () => void;
  setShowResultPopup: Dispatch<SetStateAction<string>>;
}

export const Wrapper = ({
  order,
  clearShoppingCartAndOrder,
  setShowResultPopup,
  history,
}: IWrapperProps) => {
  const { shippingAddress } = useSelector(getStoredShippingInfo);

  const addressInfoTitle =
    history.location.pathname === locations.orderReview ? 'Order Details' : 'Payment';

  const redirectToPayment = () => history.push(locations.payment);

  scrollToTop();

  return (
    <>
      <AddressInfo addressInfoTitle={addressInfoTitle} />
      <Route
        path={locations.payment}
        render={(props) => (
          <Payment
            {...props}
            order={order}
            clearShoppingCartAndOrder={clearShoppingCartAndOrder}
            setShowResultPopup={setShowResultPopup}
            userEmail={shippingAddress.email}
          />
        )}
      />

      {history.location.pathname === locations.orderReview && (
        <MainButton className="sign-in-sign-up-submit-button" onClick={redirectToPayment}>
          Proceed with payment
        </MainButton>
      )}
    </>
  );
};
