import React from 'react';

import { AvailableSize } from '../AvailableSize/AvailableSize';
import './ProductWrapper.scss';

interface IProductWrapperProps {
  product: React.ReactNode;
}

const ProductWrapper = ({ product }: IProductWrapperProps) => (
  <div className="ProductWrapper">
    <AvailableSize sizes={[150, 155, 160, 170]} />
    {product}
  </div>
);

export default ProductWrapper;
