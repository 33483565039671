import React from 'react';

import { MainButton } from '../Buttons/MainButton';
import './ConfirmationPopup.scss';

interface IConfirmationPopupProps {
  handleClick: () => void;
  closeModal: () => void;
  isLoading: boolean;
  title: string;
  confirmText: string;
  cancelText: string;
}

export const ConfirmationPopup = ({
  handleClick,
  closeModal,
  isLoading,
  title,
  confirmText,
  cancelText,
}: IConfirmationPopupProps) => {
  return (
    <div className="delete-confirm-popup">
      <div className="delete-confirm-popup-header">{title}</div>
      <div className="delete-confirm-popup-content">
        <MainButton
          className="sign-in-sign-up-submit-button"
          isLoading={isLoading}
          onClick={() => handleClick()}
        >
          {confirmText}
        </MainButton>
        <button type="button" onClick={closeModal}>
          {cancelText.toUpperCase()}
        </button>
      </div>
    </div>
  );
};
