import React from 'react';
import { Link } from 'react-router-dom';

import HelpText from '../../Pages/Account/Components/HelpText/HelpText';
import LeftMenu from '../../Pages/Account/Components/LeftMenu';
import './Sidebar.scss';

export const Sidebar = () => {
  return (
    <div className="account-sidebar">
      <LeftMenu />
      <HelpText split={true} textAlign="left" />
      <div className="account-sidebar-actions">
        <div className="account-sidebar-action">
          <i className="icon-location" /> <Link to="#">Find a store</Link>
        </div>
        <div className="account-sidebar-action">
          <i className="icon-chat" /> <Link to="#">Live chat</Link>
        </div>
        <div className="account-sidebar-action">
          <i className="icon-mail" /> <Link to="#">Email us</Link>
        </div>
      </div>
    </div>
  );
};
