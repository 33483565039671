import React from 'react';

import './ErrorPlaceholder.scss';

export const ErrorPlaceholder = () => {
  return (
    <div className="error-placeholder">
      <span className="error-placeholder-text">Sorry! Something went wrong.</span>
    </div>
  );
};
