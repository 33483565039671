import { useDispatch } from 'react-redux';
import React, { useContext, Dispatch, SetStateAction } from 'react';

import { ShoppingCartContext } from '../../../../../../contexts/contexts';
import { updateShippingAddress } from '../../../../../../redux/actions/shippingInfo/shippingInfoActions';
import {
  IAddress,
  IShippingAddress,
  ICountry,
} from '../../../../../../TypesAndInterfaces/Interfaces';
import './Address.scss';

interface IAddressProps extends IAddress {
  country: ICountry;
  firstName: string;
  lastName: string;
  setShowEditAddressForm: Dispatch<SetStateAction<Partial<IAddress> | null>>;
}

export const Address = ({
  id,
  address1,
  address2,
  city,
  zipCode,
  countryId,
  state,
  customerId,
  country,
  firstName,
  lastName,
  setShowEditAddressForm,
}: IAddressProps) => {
  const dispatch = useDispatch();

  const address = `${city}, ${state ? `${state} ${zipCode}` : `${zipCode}`}`;
  const fullName = `${firstName} ${lastName}`;

  const onShipToThisAddress = (currentAddress: Partial<IShippingAddress>) => {
    dispatch(
      updateShippingAddress({
        ...currentAddress,
      })
    );
  };

  const onEdit = () => {
    setShowEditAddressForm({
      address1,
      address2,
      city,
      zipCode,
      countryId,
      state,
      customerId,
      id,
    });
  };

  return (
    <div className="saved-address-wrapper">
      <div className="user-fullname-and-address-info">
        <div>{fullName}</div>
        <div>{address1}</div>
        <div>{address}</div>
        <div>{country.name}</div>
      </div>
      <div className="buttons">
        <span className="span-as-link" onClick={onEdit}>
          Edit
        </span>
        <span
          className="span-as-link"
          onClick={() =>
            onShipToThisAddress({
              firstName,
              lastName,
              address1,
              address2,
              zipCode,
              city,
              state,
              country,
            })
          }
        >
          Ship to this address
        </span>
      </div>
    </div>
  );
};
