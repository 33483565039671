import React, { useContext } from 'react';

import Subheader from './Sections/Subheader/Subheader';
import SummarySection from '../ShoppingPagesWrapper/SummarySection/SummarySection';
import ProductsSection from './Sections/ProductsSection/ProductsSection';
import CardWrapper from '../../Components/CardWrapper/CardWrapper';
import { ShoppingCartContext } from '../../contexts/contexts';
import './ShoppingCardPage.scss';

const ShoppingCardPage = () => {
  const { shoppingCartProducts } = useContext(ShoppingCartContext);

  return (
    <section className="ShoppingCardPage">
      <Subheader />
      <div className="ProductDetailsWrapper">
        <div className="ProductsSectionTitle">Product details</div>
        <CardWrapper>
          <div className="ProductDetailsContent">
            <ProductsSection />
          </div>
          <SummarySection shoppingCartProducts={shoppingCartProducts} />
        </CardWrapper>
      </div>
    </section>
  );
};

export default ShoppingCardPage;
