import React from 'react';

import BoardArrivals from './Sections/BoardArrivals/BoardArrivals';
import Boards from './Sections/Boards/Boards';
import PostHomePage from './Sections/PostHomePage/PostHomePage';
import UpcomingEvents from './Sections/UpcomingEvents/UpcomingEvents';
import SkullEdition from './Sections/SkullEdition/SkullEdition';

const HomePage = () => (
  <main className="with-space-for-header">
    <BoardArrivals />
    <Boards />
    <PostHomePage />
    <UpcomingEvents />
    <SkullEdition />
  </main>
);

export default HomePage;
