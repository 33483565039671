import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';

import locations from '../../../utils/locations';
import { getBoardByIdUrl } from '../../../utils/endpoints';
import { IProduct, IProductDetails } from '../../../TypesAndInterfaces/Interfaces';
import { getImagesUrl, imageSizes } from '../../../utils';
import { MainButton } from '../../../Components/Buttons/MainButton';
import { stateStatuses } from '../../../utils/constants';
import { makeRequest } from '../../../utils';

import './WishlistWidget.scss';

interface IWishlistWidgetProps
  extends Pick<IProduct, 'model' | 'price' | 'id' | 'primaryImageId' | 'brand'> {
  isDisabled: boolean;
  wishlistId: number;
  creationDate: string;
  onDelete: () => void;
  onAddToShipping: (id: number, wishlistId: number) => void;
  quantityIntoStock: number;
  productDetailId: number;
}

const mapDetailsToSize = (details: IProductDetails[], detailId: number) => {
  let size: string | undefined = '';
  details.filter((item) => {
    if (item.id === detailId && item.productAttributes.length) {
      size = item.productAttributes.find((o) => o.attributeName === 'Length')?.attributeValue;
    }
  });
  return !!size ? size : 'no info';
};

export const WishlistWidget = ({
  isDisabled,
  wishlistId,
  creationDate,
  price,
  model,
  id,
  primaryImageId,
  brand,
  onDelete,
  onAddToShipping,
  quantityIntoStock,
  productDetailId,
}: IWishlistWidgetProps) => {
  const mappedDate = new Date(creationDate).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const [size, setSize] = useState<string>('No info');
  const [pageStatus, setPageStatus] = useState(stateStatuses.loading);

  useEffect(() => {
    (async () => {
      const { data, err } = await makeRequest('get', getBoardByIdUrl({ id }));
      if (err) {
        setPageStatus(() => stateStatuses.failure);
      }

      if (data) {
        setSize(mapDetailsToSize(data.productDetails, productDetailId));

        setPageStatus(() => stateStatuses.success);
      }
    })();
  }, [productDetailId]);

  if (pageStatus === stateStatuses.failure) {
    return <Redirect to={locations.homepage} />;
  }
  if (pageStatus !== stateStatuses.success) return null;

  return (
    <div className={`wish-list-widget ${!quantityIntoStock ? 'wish-list-widget__disabled' : ''}`}>
      <div className="wish-list-widget__product">
        <div className="wish-list-widget__img-wrapper">
          <img
            className="wish-list-widget__img"
            src={getImagesUrl({
              id: primaryImageId,
              parameters: imageSizes['110x110'],
            })}
            alt="product img"
          />
        </div>
        <div className="wish-list-widget__item">
          <div className="wish-list-widget__category-price">
            <Link className="wish-list-widget__category" to={locations.productPage(String(id))}>
              Model:{model}
            </Link>

            <div className="wish-list-widget__desc">
              <p className="wish-list-widget__category">
                Brand: <span>{brand}</span>
              </p>
              <p className="wish-list-widget__size">
                ID: <span>{`${id} - ${size}`}</span>
              </p>
              <div className="wish-list-widget__price-date">
                <p className="wish-list-widget__date">{mappedDate}</p>
                <p className="wish-list-widget__price">{price}$</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {quantityIntoStock === 0 && (
        <div className="wish-list-widget__sold-out">
          <div className="wish-list-widget__sold-out--label">Temporarily out of stock</div>
        </div>
      )}
      <div className="wish-list-widget__buttons">
        <IconButton aria-label="delete" onClick={onDelete} className="wish-list-widget__delete">
          <DeleteIcon fontSize="small" />
        </IconButton>
        <MainButton
          isDisabled={isDisabled || !quantityIntoStock}
          className="btn-pink-default"
          onClick={() => onAddToShipping(id, wishlistId)}
          fontSize="12px"
        >
          add to cart
        </MainButton>
      </div>
    </div>
  );
};
