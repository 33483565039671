import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_USER_INFO_SUCCESS,
  ON_UPDATE_USER_INFO_SUCCESS,
  CLEAR_USER_INFO,
  USER_TABS_COUNT,
  ON_GET_USER_SAVED_ADDRESSES_SUCCESS,
  ON_EDIT_USER_SAVED_ADDRESS_SUCCESS,
  ON_SAVE_USER_ADDRESS_SUCCESS,
  ON_DELETE_USER_SAVED_ADDRESS_SUCCESS,
  ON_GET_USER_PREFERENCES_SUCCESS,
  ON_EDIT_USER_PREFERENCES_SUCCESS,
  ON_SAVE_PREFERENCES_SUCCESS,
} from './userTypes';
import { makeRequest } from '../../../utils/utils';
import {
  getUserProfile,
  updateUserProfile,
  getGetAddressUrl,
  getEditAddressUrl,
  getSaveAddressUrl,
  getDeleteAddressUrl,
  getUserPreferencesUrl,
  getNotificationSettingsUrl,
} from '../../../utils/endpoints';
import { clearTokens, clearLoginProvider, getSelectWishListItems } from '../../../utils/utils';
import { AUTH_API } from '../../../services/auth.services';
import { updateShippingAddress } from '../shippingInfo/shippingInfoActions';

export const getUserInfo = (loginProvider) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('get', getUserProfile());

  if (data) {
    const dataSelectedWishListItems = {
      ...data,
      wishlistItems: getSelectWishListItems(data.wishlistItems),
    };
    localStorage.setItem('userData', JSON.stringify(dataSelectedWishListItems));
    dispatch(updateShippingAddress({ email: data.email }));

    return dispatch({
      type: ON_GET_USER_INFO_SUCCESS,
      payload: { ...dataSelectedWishListItems, loggedWith: loginProvider },
    });
  }

  if (err) {
    clearTokens();
    clearLoginProvider();

    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const updateUserInfo = ({
  firstName,
  lastName,
  email,
  birthday,
  handleError,
  handleSuccess,
}) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { status, err } = await makeRequest('put', updateUserProfile(), {
    firstName,
    lastName,
    email,
    birthday,
  });

  if (status === 202) {
    handleSuccess(true);
    return dispatch({
      type: ON_UPDATE_USER_INFO_SUCCESS,
      payload: { firstName, lastName, email, birthday, wishlistItems },
    });
  }

  if (err) {
    handleError();
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const clearUserInfo = () => (dispatch) => {
  return dispatch({
    type: CLEAR_USER_INFO,
  });
};

export const setUserTabsCount = (tabsCount) => async (dispatch) => {
  return dispatch({
    type: USER_TABS_COUNT,
    payload: tabsCount,
  });
};

export const getUserSavedAddresses = (customerId) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('get', getGetAddressUrl(), {}, { customerId });

  if (data) {
    return dispatch({
      type: ON_GET_USER_SAVED_ADDRESSES_SUCCESS,
      payload: [...data],
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const editUserSavedAddress = ({
  id,
  address1,
  address2,
  city,
  zipCode,
  countryId,
  state,
  customerId,
}) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('post', getEditAddressUrl(), {
    id,
    address1,
    address2,
    city,
    zipCode,
    countryId,
    state,
    customerId,
  });

  if (data) {
    return dispatch({
      type: ON_EDIT_USER_SAVED_ADDRESS_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const saveUserAddress = ({
  address1,
  address2,
  city,
  zipCode,
  countryId,
  state,
  customerId,
}) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('post', getSaveAddressUrl(), {
    address1,
    address2: address2 || '',
    city,
    zipCode,
    countryId,
    state,
    customerId,
  });

  if (data) {
    dispatch({
      type: ON_SAVE_USER_ADDRESS_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const deleteUserSavedAddress = ({ customerId, addressId }) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { status } = await makeRequest(
    'post',
    getDeleteAddressUrl(),
    {},
    {
      customerId,
      addressId,
    }
  );

  if (status === 200) {
    return dispatch({
      type: ON_DELETE_USER_SAVED_ADDRESS_SUCCESS,
      payload: { addressId },
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const deleteUserAccount = ({ token }) => async (dispatch) => {
  return await AUTH_API.deleteAccount({ token });
};

export const getUserPreferences = (customerId) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('get', getUserPreferencesUrl(customerId));

  if (data) {
    return dispatch({
      type: ON_GET_USER_PREFERENCES_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const saveNotificationPreferences = (reqBody) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { status, data, err } = await makeRequest('post', getNotificationSettingsUrl(), reqBody);

  if (status === 201) {
    const result = await makeRequest(
      'get',
      getNotificationSettingsUrl({
        id: data.notificationSettingsId,
      })
    );

    if (result.data) {
      return dispatch({
        type: ON_SAVE_PREFERENCES_SUCCESS,
        payload: result.data,
      });
    }

    if (result.err) {
      return dispatch({
        type: ON_FETCHING_DATA_ERROR,
      });
    }
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};

export const editNotificationPreferences = (reqBody) => async (dispatch) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { status, data, err } = await makeRequest('put', getNotificationSettingsUrl(), reqBody);

  if (status === 202) {
    return dispatch({
      type: ON_EDIT_USER_PREFERENCES_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};
