import React from 'react';
import { isArray, uniq } from 'lodash';

import { flexesList, riderLevelsList, usageList } from '../../../../utils/constants';
import './ProductCharacteristics.scss';

interface IProductsCharacteristicsProps {
  flex?: string;
  riderLevel?: string[];
  usage?: string[];
}

const VISIBLE_NUM = 3;

const ProductCharacteristics = React.memo(
  ({ flex, riderLevel, usage }: IProductsCharacteristicsProps) => {
    const arrayedUsage = isArray(usage) ? usage : [usage];
    const usageUniqArray = uniq([...arrayedUsage, ...usageList]);
    const splittedRiderLevel = riderLevel!.map((level) => level.split('-'));

    const characteristics = [
      {
        title: 'Usage :',
        arr: usageUniqArray.slice(0, VISIBLE_NUM),
        selectedIndexes: arrayedUsage,
      },
      {
        title: 'Rider Level :',
        arr: riderLevelsList,
        selectedIndexes: splittedRiderLevel.flat(),
      },
      {
        title: 'Flex :',
        arr: flexesList,
        selectedIndexes: [flex],
      },
    ];

    return (
      <div className="ProductCharacteristics">
        <div className="ProductCharacteristics">
          <strong className="ProductCharTitle">CHARACTERISTICS</strong>
          {characteristics.map((item) => {
            const blockClassNames = item.arr.map((param) =>
              item.selectedIndexes!.map((selectedIndex) =>
                selectedIndex?.toLowerCase() === param?.toLowerCase() ? 'green-' : 'red-'
              )
            );
            const blockClassNamesUnique = blockClassNames.map((name) =>
              name.includes('green-') ? 'green-' : 'red-'
            );
            const blockClassName = `${blockClassNamesUnique.join('')}gradient`;

            return (
              <div key={item.title} className="ProductCharDesc">
                <div className="ParamTitle">{item.title}</div>
                <div className={`ProductParams ${blockClassName}`}>
                  {item.arr.map((param, index) => (
                    <div key={index} className="ProductParam">
                      {param}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default ProductCharacteristics;
