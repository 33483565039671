import React from 'react';
import './FooterLinkList.scss';
import { Link } from 'react-router-dom';

interface IFooterLinkListData {
  src: string;
  linkTitel: string;
  external?: boolean;
}

interface IFooterLinkListProps {
  links: {
    title: string;
    data: IFooterLinkListData[];
  };
}

const FooterLinkList = (props: IFooterLinkListProps) => (
  <div className="FooterLinkList">
    <h4>{props.links.title}</h4>
    <ul>
      {props.links.data.map((item, index) => {
        const linkNode = item.external ? (
          <a href={item.src}>{item.linkTitel}</a>
        ) : (
          <Link to={item.src}>{item.linkTitel}</Link>
        );

        return <li key={index}>{linkNode}</li>;
      })}
    </ul>
  </div>
);

export default FooterLinkList;
