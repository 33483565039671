import React, { useState, useEffect, ChangeEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './EmailRedirectPage.scss';

import SimpleFormWrapper from '../../Components/SimpleFormWrapper/SimpleFormWrapper';
import {
  fieldNames,
  getParamsFromQueryString,
  initialFormValues,
  inputTypes,
  resetPasswordValidationSchema,
} from '../../utils';
import { AUTH_API } from '../../services/auth.services';
import locations from '../../utils/locations';
import { EmailRedirectMessage } from './EmailRedirectMessage';
import { MainButton } from '../../Components/Buttons/MainButton';

const resetPasswordFields = {
  [fieldNames.password]: { title: 'New password', type: inputTypes.password },
  [fieldNames.passwordConfirmation]: { title: 'Confirm password', type: inputTypes.password },
};
const initialResetPasswordValues = initialFormValues(resetPasswordFields);

export const titles = {
  activationComplete: 'Activation Complete!',
  invalidActivationLink: 'Invalid Activation Link!',
  invalidLink: 'Invalid Link!',
  accountDeletionComplete: 'Account deletion complete!',
  congratulations: 'Congratulations!',
};
export const subtitles = {
  accountDeletion: 'Your account was successfully deleted.',
  accountActivated:
    'Your account was successfully activated. You can now sign in using the email and password you indicated during the registration.',
  alreadyActivated:
    'Your account is already activated. Please sign in using the email and password you indicated during the registration.',
  expiredLink:
    'The activation code has expired. Please press the button below to resend the activation link.',
  deletedAccountLink:
    'The link has expired. Please log in to your account and click on the DELETE YOUR ACCOUNT link to proceed with the account deletion.',
  invalidLink: 'The link is not valid. Please make sure that you have typed the link correctly.',
  changedPassword:
    'Your password has been changed successfuly. Go to BoardZone page and Sign In with new password.',
};

const EmailRedirectPage = () => {
  const [passwordResetTitle, setPasswordResetTitle] = useState('');
  const [passwordResetSubtitle, setPasswordResetSubtitle] = useState('');

  const { pathname, search } = useLocation();

  let isResetPasswordPage: boolean = pathname === locations.passwordReset;

  const [isUrlInvalid, setIsUrlInvalid] = useState(false);

  const { email, token, key } = getParamsFromQueryString(search);

  const [isValid, setIsValid] = useState(false);

  const [initialErrors, setInitialErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState(initialResetPasswordValues);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useHistory();

  const handleValueChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((data) => ({
      ...data,
      [field]: value,
    }));

    if (field === 'email') {
      setInitialErrors({});
    }
  };

  const handleSubmit = async () => {
    setInitialErrors({});
    setIsSubmitted(() => true);
    setIsLoading(true);

    if (isValid) {
      const payload = {
        newPassword: formData.password,
        email,
        token,
      };

      const { err }: any = await AUTH_API.resetPassword(payload);

      if (err) {
        const response = err.response || {};
        const errors = response.data?.errors;
        if (errors) setInitialErrors(errors);
        setIsLoading(false);
        return;
      }
      setPasswordChanged(true);
    }
    setIsLoading(false);
  };

  const redirectToHomePage = () => {
    push('/');
  };

  useEffect(() => {
    (async () => {
      isResetPasswordPage = pathname === locations.passwordReset;
      const payload = { email: email, token: token };
      let message = '';
      const { data, err } = await (isResetPasswordPage
        ? AUTH_API.checkPasswordResetToken(payload)
        : AUTH_API.confirmRegistration({ key }));

      console.log(payload);
      console.log(
        'data and err: ',
        await (isResetPasswordPage
          ? AUTH_API.checkPasswordResetToken(payload)
          : AUTH_API.confirmRegistration({ key }))
      );
      console.log('data and err: ', data, err);

      if (data === false || err) {
        setIsUrlInvalid(true);
      }

      if (!isResetPasswordPage) {
        setPasswordResetTitle(titles.activationComplete);
        setPasswordResetSubtitle(subtitles.accountActivated);
        if (message === 'TODO: already activated') {
          setPasswordResetTitle(titles.invalidActivationLink);
          setPasswordResetSubtitle(subtitles.alreadyActivated);
        } else if (message === 'TODO: expired') {
          setPasswordResetTitle(titles.invalidActivationLink);
          setPasswordResetSubtitle(subtitles.expiredLink);
        }
      }
    })();
  }, [email, token, pathname, isResetPasswordPage]);

  if (isUrlInvalid) {
    return <EmailRedirectMessage title={titles.invalidLink} subtitle={subtitles.invalidLink} />;
  } else if (isResetPasswordPage) {
    return (
      <div className="password-page-container with-space-for-header">
        <div className="reset-form-container">
          <div className="reset-form">
            {!passwordChanged ? (
              <>
                <span className="reset-form_title">Reset Password</span>
                <span className="reset-form_subtitle">Enter your new password</span>
                <div id="sign-in-sign-up-form-elements">
                  <SimpleFormWrapper
                    key="reset-password-component"
                    fields={resetPasswordFields}
                    initialValues={formData}
                    initialErrors={initialErrors}
                    handleValueChange={handleValueChange}
                    validationSchema={resetPasswordValidationSchema}
                    showAllErrors={isSubmitted}
                    setIsValidForm={setIsValid}
                    textFieldClassName="sign-in-sign-up-input"
                    formControlClassName="sign-in-sign-up-control"
                  />
                </div>
                <MainButton
                  className="sign-in-sign-up-submit-button confirm-btn"
                  isDisabled={!isValid}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  CONFIRM PASSWORD
                </MainButton>
              </>
            ) : (
              <>
                <div className="successful-reset-container">
                  <div className="title">{titles.congratulations}</div>
                  <span className="subtitle">{subtitles.changedPassword}</span>
                </div>
                <MainButton
                  className="sign-in-sign-up-submit-button confirm-btn"
                  onClick={redirectToHomePage}
                >
                  Go to Boardzone
                </MainButton>
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <EmailRedirectMessage title={passwordResetTitle} subtitle={passwordResetSubtitle} />;
  }
};

export default EmailRedirectPage;
