import React from 'react';
import './SoldOutLabel.scss';

const SoldOutLabel = () => {
  return (
    <span className="sold-out-label">
      <span className="sold-out-label-text">Sold out</span>
    </span>
  );
};

export default SoldOutLabel;
