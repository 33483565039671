import {
  ON_FETCHING_PRDCTS_SUCCESS,
  ON_FETCHING_PRDCTS_ERROR,
  ON_FETCHING_PRDCTS_INIT,
} from '../actions/products/productsConstants';
import { IProduct } from '../../TypesAndInterfaces/Interfaces/Product';

interface IProductsReducer {
  products: IProduct[];
}

const initialState: IProductsReducer = {
  products: [],
};

export const productsReducer = (state = initialState, action: { type: String; payload: any }) => {
  switch (action.type) {
    case ON_FETCHING_PRDCTS_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ON_FETCHING_PRDCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case ON_FETCHING_PRDCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        products: [
          ...action.payload.map((product: IProduct) => ({
            ...product,
            value: product.id,
          })),
        ],
      };
    default:
      return state;
  }
};
