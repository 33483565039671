import React from 'react';
import { Route } from 'react-router-dom';

import locations from '../../utils/locations';
import { CheckoutPage } from '../CheckoutPage/CheckoutPage';
import ShoppingCartPage from '../ShoppingCartPage/ShoppingCartPage';
import './ShoppingPagesWrapper.scss';

export const ShoppingPagesWrapper = () => (
  <main className="ShoppingPageWrapper with-space-for-header">
    <Route path={locations.shoppingCart} component={ShoppingCartPage} />
    <Route path={`${locations.checkoutPagesWrapper}`} component={CheckoutPage} />
  </main>
);
