import React from 'react';
import { Link } from 'react-router-dom';

import { getImagesUrl } from '../../utils/endpoints';
import { imageSizes } from '../../utils/constants';
import './Product.scss';

const Product = (props) => {
  const snowboardsCategory = props.categories?.find((category) => category.name === 'Snowboards');

  return (
    <Link to={`products/${props.id}`}>
      <div className="Product" style={{ animationDelay: props.delay }}>
        <div className="ProductHolder">
          <div className="ProductImgHolder">
            <img
              className="ProductImg"
              alt=""
              src={getImagesUrl({
                id: props.primaryImageId,
                parameters: imageSizes['120x160'],
              })}
            />
          </div>
          <div className="ProductDescription">
            <strong className="ProductBrand">
              {props.brand} {props.model}
            </strong>
            <span className="ProductModel">Color: {props.color}</span>
            <span className="ProductModel">
              {snowboardsCategory && props.categoryId === snowboardsCategory['id']
                ? 'Length'
                : 'Size'}
              : {props.size}
            </span>
            <span className="ProductModel">Quantity: {props.amount}</span>
            <span className="ProductPrice">Price: ${props.price}</span>
            <span className="ProductDiscountPrice">{props.oldPrice}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Product;
