import {
  ON_GET_SHIPPING_INFO_SUCCESS,
  ON_UPDATE_SHIPPING_METHOD_SUCCESS,
  ON_UPDATE_SHIPPING_ADDRESS_SUCCESS,
  CLEAR_SHIPPING_INFO,
} from './shippingInfoTypes';
import { SHIPPING_INFO_LS } from '../../../utils/constants';
import {
  IShippingMethodInfo,
  IShippingAddress,
  IShippingInfo,
} from '../../../TypesAndInterfaces/Interfaces';

export const getShippingInfo = () => (
  dispatch: (arg0: { type: string; payload: Partial<IShippingInfo> }) => any
) => {
  const shippingInfo = localStorage.getItem(SHIPPING_INFO_LS);

  if (shippingInfo) {
    const parsedShippingInfo = JSON.parse(shippingInfo);

    return dispatch({
      type: ON_GET_SHIPPING_INFO_SUCCESS,
      payload: { ...parsedShippingInfo },
    });
  }
};

export const updateShippingMethod = (shipping: IShippingMethodInfo) => (
  dispatch: (arg0: { type: string; payload: IShippingMethodInfo }) => any
) => {
  return dispatch({
    type: ON_UPDATE_SHIPPING_METHOD_SUCCESS,
    payload: { ...shipping },
  });
};

export const updateShippingAddress = (address: Partial<IShippingAddress>) => (
  dispatch: (arg0: { type: string; payload: Partial<IShippingAddress> }) => any
) => {
  return dispatch({
    type: ON_UPDATE_SHIPPING_ADDRESS_SUCCESS,
    payload: address,
  });
};

export const clearShippingInfo = () => (dispatch: (arg0: { type: string }) => any) => {
  return dispatch({
    type: CLEAR_SHIPPING_INFO,
  });
};
