import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getStoredCategories } from '../../../../../../redux/reducers/selectors';
import { Popup } from '../../../../../../Components/Popup/Popup';
import { imageSizes } from '../../../../../../utils/constants';
import { getImagesUrl } from '../../../../../../utils/endpoints';
import locations from '../../../../../../utils/locations';
import { IProduct } from '../../../../../../TypesAndInterfaces/Interfaces';

import './ShoppingCartProduct.scss';

interface ICategory {
  id: number;
  name: string;
  parentCategoryId: number | null;
}

interface IShoopingProductData extends IProduct {
  size: number;
  color: string;
  index: number;
  amount: number;
  quantityOnStock: number;
}

interface IShoppingCardProductProps {
  product: IShoopingProductData;
  index: number;
  removeProduct: (obj: { index: number }) => void;
  handleAmountOfProduct: (obj: { amount: number; index: number; quantityOnStock?: number }) => void;
  isNotificationOpen: boolean;
  setIsNotificationOpen: Dispatch<SetStateAction<boolean>>;
}

const ShoppingCartProduct = ({
  product,
  handleAmountOfProduct,
  removeProduct,
  isNotificationOpen,
  setIsNotificationOpen,
}: IShoppingCardProductProps) => {
  const {
    id,
    categoryId,
    amount,
    price,
    brand,
    model,
    size,
    primaryImageId,
    color,
    index,
    quantityOnStock,
  } = product;

  const { categories } = useSelector(getStoredCategories);

  const snowboardsCategory: ICategory | undefined = categories?.find(
    (category: ICategory) => category.name === 'Snowboards'
  );

  const handleIncrement = () =>
    handleAmountOfProduct({ index, amount: amount + 1, quantityOnStock });

  const handleDecrement = () => amount && handleAmountOfProduct({ index, amount: amount - 1 });

  const handleRemove = () => removeProduct({ index });

  const handleClosePopup = () => {
    setIsNotificationOpen(false);
  };

  return (
    <div className="CartProduct">
      <div className="CartProductImg">
        <img
          src={getImagesUrl({
            id: primaryImageId,
            parameters: imageSizes['110x110'],
          })}
          alt=""
        />
      </div>
      <div className="CartProductDesc">
        <Link to={locations.productPage(id.toString())}>
          <div className="CartProductModel">
            {brand} {model}
          </div>
          <div className="CartProductColor"> Color: {color}</div>
          <div className="CartProductSize">
            {snowboardsCategory && Number(categoryId) === snowboardsCategory['id']
              ? 'Length: '
              : 'Size: '}
            {size}
          </div>
          <div className="CartProductPrice">${price * amount}</div>
        </Link>
      </div>
      <div className="CartProductBtnWrapper">
        <div className="CartProductBtn">
          <button disabled={amount <= 1} onClick={handleDecrement}>
            -
          </button>
          <span className="ProductAmount">{amount}</span>
          <button disabled={quantityOnStock <= amount} onClick={handleIncrement}>
            +
          </button>
        </div>
        <button className="CartProductRemove" onClick={handleRemove}>
          Remove
        </button>
      </div>
      <Popup
        title="Warning!"
        text="Item sold out"
        open={isNotificationOpen}
        onClose={handleClosePopup}
      />
    </div>
  );
};

export default ShoppingCartProduct;
