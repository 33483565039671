import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_ALL_COUNTRIES_SUCCESS,
} from '../actions/countries/countriesTypes';
import { ICountry } from '../../TypesAndInterfaces/Interfaces';

interface ICoutriesReducer {
  countries: ICountry[];
}

const initialState: ICoutriesReducer = {
  countries: [],
};

export const countriesReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case ON_FETCHING_DATA_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ON_FETCHING_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case ON_GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        countries: [
          {
            name: 'Change country',
            value: false,
          },
          ...action.payload.map((country: ICountry) => ({
            ...country,
            value: country.id,
          })),
        ],
      };
    default:
      return state;
  }
};
