import React, { useContext } from 'react';

import { INotification } from '../../TypesAndInterfaces/Interfaces/Notification';
import Notification from '../Notification/Notification';
import { NotificationsContext } from '../../contexts/contexts';

const NotificationsList = () => {
  const { notifications, setNotifications } = useContext(NotificationsContext);

  const clearNotification = (id: number) =>
    setNotifications((state: Array<INotification>) =>
      state.filter((item: INotification) => item.id !== id)
    );

  if (!notifications.length) return null;
  return (
    <div>
      {notifications.map((item: INotification) => (
        <Notification {...item} key={item.id} clearNotification={clearNotification} />
      ))}
    </div>
  );
};

export default NotificationsList;
