import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getUserInfo } from '../../redux/actions/user/userActions';
import { getStoredUser } from '../../redux/reducers/selectors';
import { loginOptions, notificationTypes } from '../../utils/constants';
import { NotificationsContext } from '../../contexts/contexts';
import { setLoginProvider, setTokens, clearTokens, clearLoginProvider } from '../../utils/utils';
import GoogleButton from './GoogleButton/GoogleButton';
import { AUTH_API } from '../../services/auth.services';
import './LoginWithGoogle.scss';

const LoginWithGoogle = ({ closeModal }) => {
  const dispatch = useDispatch();
  const user = useSelector(getStoredUser);
  const { userInfo } = user;

  const { notifications, setNotifications } = useContext(NotificationsContext);

  const loggedWithGoogle = userInfo.loggedWith === loginOptions.google;

  const label = loggedWithGoogle ? 'Sign out with Google' : 'Sign in with Google';

  const handleGoogleTokenId = async ({ tokenId }) => {
    const { data, err } = await AUTH_API.loginGoogle(tokenId);

    if (data) {
      setTokens(data.token, data.refreshToken);
      setLoginProvider(loginOptions.google);
      closeModal();
      dispatch(getUserInfo(loginOptions.google));
    }

    if (err) {
      if (
        err.response?.data?.message?.includes(
          'Your account is blocked. Contact helpdesk@boardzone.com to further clarification'
        )
      ) {
        const popupText =
          'Your account is blocked. Contact helpdesk@boardzone.com to further clarification.';

        setNotifications([
          ...notifications,
          {
            type: notificationTypes.warning,
            message: popupText,
            id: Date.now(),
          },
        ]);
      }
      clearTokens();
      clearLoginProvider();
    }
  };

  return <GoogleButton label={label} handleGoogleTokenId={handleGoogleTokenId} />;
};

LoginWithGoogle.propTypes = {
  closeModal: PropTypes.func,
};
LoginWithGoogle.defaultProps = {
  closeModal: () => {},
};

export default LoginWithGoogle;
