import React, { useEffect } from 'react';
import { Modal } from '@material-ui/core';

import './Popup.scss';

interface IPopupProps {
  title: string | null;
  onClose: (value: boolean) => void;
  text: string;
  open: boolean;
}

export const Popup = ({ open, onClose, title, text }: IPopupProps) => {
  let timeout: number;

  if (text.length > 90) {
    timeout = 8000;
  } else {
    timeout = 5000;
  }

  const onCloseTimeout = () => {
    setTimeout(onClose, timeout);
  };

  useEffect(() => {
    onCloseTimeout();
  }, []);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div className="popup-container">
        {title && <div className="popup-title">{title}</div>}
        <div className="popup-text">{text}</div>
      </div>
    </Modal>
  );
};
