const routes = {
  homepage: '/',
  products: '/products',
  catalog: '/catalog',
  productNotFound: '/products/non-existing',
  trips: '/trips',
  shoppingCart: '/shopping-cart',
  shipping: '/shipping',
  payment: '/payment',
  orderReview: '/order-review',
  signUp: '/sign-up',
  signIn: '/sign-in',
  passwordReset: '/password-reset',
  confirmEmail: '/confirm-email',
  accountDashboard: '/account',
  notificationPreferences: '/preferences',
  myOrders: '/my-orders',
  orderDetails: '/order-details',
  myCoupons: '/coupons',
  wishlist: '/wishlist',
  deleteAccount: '/delete-account',
  deleteDataFacebook: '/delete-data-facebook',
};

const combineRoutes = (...args: string[]) => {
  const combination = args
    .map((item) => item.slice(1))
    .reduce((acc, val) => `${acc}${acc ? '|' : ''}${val}`, '');
  return new RegExp(`(${combination})`);
};

const locations = {
  homepage: routes.homepage,
  products: routes.products,
  productNotFound: routes.productNotFound,
  trips: routes.trips,
  shoppingCart: routes.shoppingCart,
  shipping: routes.shipping,
  payment: routes.payment,
  orderReview: routes.orderReview,
  signUp: routes.signUp,
  signIn: routes.signIn,
  passwordReset: routes.passwordReset,
  confirmEmail: routes.confirmEmail,
  accountDashboard: routes.accountDashboard,
  notificationPreferences: routes.notificationPreferences,
  myOrders: routes.myOrders,
  orderDetails: routes.orderDetails,
  myCoupons: routes.myCoupons,
  wishlist: routes.wishlist,
  deleteAccount: routes.deleteAccount,
  deleteDataFacebook: routes.deleteDataFacebook,

  shoppingPagesWrapper: combineRoutes(
    routes.shoppingCart,
    routes.shipping,
    routes.payment,
    routes.orderReview
  ),
  checkoutPagesWrapper: combineRoutes(routes.shipping, routes.payment, routes.orderReview),
  paymentAndOrderReviewWrapper: combineRoutes(routes.payment, routes.orderReview),
  signInAndUpWrapper: combineRoutes(routes.signIn, routes.signUp),

  sectionPage: (sectionName = ':sectionName') => `${routes.catalog}/${sectionName}`,
  categoryPage: (sectionName: string, categoryName = ':categoryName') =>
    `${locations.sectionPage(sectionName)}/${categoryName}`,
  subCategoryPage: (
    sectionName: string,
    categoryName: string,
    subCategoryName = ':subCategoryName'
  ) => `${locations.categoryPage(sectionName, categoryName)}/${subCategoryName}`,

  productPage: (id = ':id') => `${routes.products}/${id}`,

  orderDetailsPage: (id = ':id') => `${routes.orderDetails}/${id}`,

  account: combineRoutes(routes.accountDashboard),
};

export default locations;
