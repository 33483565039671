import React from 'react';

import ProductLinkContent, {
  IProductLinkContentProps,
} from '../ProductLinkContent/ProductLinkContent';
import './ProductLinkPrev.scss';
import './ProductLinkNext.scss';
import { IProduct } from '../../TypesAndInterfaces/Interfaces';

interface IProductLinkInfo extends Omit<IProduct, 'totalQuantity' | 'categoryId' | 'description'> {
  oldPrice?: number;
}

interface IProductLinkProps {
  direction: string;
  info: IProductLinkContentProps;
}

const ProductLink = ({ direction, info }: IProductLinkProps) => {
  const { primaryImageId, model, brand, price, oldPrice, id, value } = info || {};

  return (
    <div className={direction === 'left' ? 'ProductLinkPrev' : 'ProductLinkNext'}>
      <ProductLinkContent
        showContent={!!info}
        id={id}
        primaryImageId={primaryImageId}
        model={model}
        brand={brand}
        price={price}
        oldPrice={oldPrice}
        value={value}
      />
    </div>
  );
};

export default ProductLink;
