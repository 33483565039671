import React, { useContext, useState } from 'react';

import { ShoppingCartContext } from '../../../../../../contexts/contexts';
import ShoppingCartProduct from '../ShoppingCartProduct/ShoppingCartProduct';
import './ProductList.scss';

const ProductList = () => {
  const { shoppingCartProducts, setShoppingCartProducts } = useContext(ShoppingCartContext);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const removeProduct = ({ index }) => {
    setShoppingCartProducts(
      shoppingCartProducts.filter((_value, arrayIndex) => index !== arrayIndex)
    );
  };

  const handleAmountOfProduct = ({ index, amount, quantityOnStock }) => {
    if (quantityOnStock <= amount) {
      setIsNotificationOpen(true);
    }

    if (amount < 1) {
      removeProduct({ index });
    } else {
      setShoppingCartProducts([
        ...shoppingCartProducts.slice(0, index),
        {
          ...shoppingCartProducts[index],
          amount,
        },
        ...shoppingCartProducts.slice(index + 1),
      ]);
    }
  };

  if (!shoppingCartProducts.length) {
    return (
      <div>
        Your shopping cart is empty. Once you have added items to your shopping cart, you can check
        out from here.
      </div>
    );
  }
  return (
    <ul className="CartProductLists">
      {shoppingCartProducts.map((product, index) => (
        <li key={`${index}/${product.id}/${product.size}`} className="ShoppingCardProduct">
          <ShoppingCartProduct
            product={{ ...product, index }}
            handleAmountOfProduct={handleAmountOfProduct}
            removeProduct={removeProduct}
            isNotificationOpen={isNotificationOpen}
            setIsNotificationOpen={setIsNotificationOpen}
          />
        </li>
      ))}
    </ul>
  );
};

export default ProductList;
