import React, { useState } from 'react';

import { filtersList, defaultFiltersValues } from '../../../../utils/constants';
import CardWrapper from '../../../../Components/CardWrapper/CardWrapper';
import BoardLengthRange from './Components/BoardLengthRange/BoardLengthRange';
import SelectBoardWidth from './Components/SelectBoardWidth/SelectBoardWidth';
import SelectRiderLevel from './Components/SelectRiderLevel/SelectRiderLevel';
import SelectColor from './Components/SelectColor/SelectColor';
import PriceRange from './Components/PriceRangeSlider/PriceRange';
import UsageCheckbox from './Components/UsageCheckbox/UsageCheckbox';
import SelectFlex from './Components/SelectFlex/SelectFlex';
import SelectRockerProfile from './Components/SelectRockerProfile/SelectRockerProfile';
import SelectShape from './Components/SelectShape/SelectShape';
import BrandCheckbox from './Components/BrandCheckbox/BrandCheckbox';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import { IFilters } from '../../../../TypesAndInterfaces/Interfaces';

import './FiltersSection.scss';

interface IFiltersProps {
  filters: IFilters;
  setFilters: (value: IFilters) => void;
  setMetaInfo: (value: object) => void;
}

const FiltersSection = ({ filters, setFilters, setMetaInfo }: IFiltersProps) => {
  const [currentFilters, setCurrentFilters] = useState<IFilters>(defaultFiltersValues);
  const [clearFilters, setClearFilters] = useState(false);

  const handleFiltersChange = () => {
    setMetaInfo((info: object) => ({
      ...info,
      [filtersList.PageIndex]: 1,
    }));
    setFilters({
      ...defaultFiltersValues,
      ...currentFilters,
    });
    setClearFilters(false);
  };

  const handleFiltersClear = () => {
    setCurrentFilters(defaultFiltersValues);
    setFilters(defaultFiltersValues);
    setClearFilters(true);
  };

  return (
    <section className="FiltersSection">
      <CardWrapper>
        <PriceRange
          filters={filters}
          clearFilters={clearFilters}
          handleFiltersChange={setCurrentFilters}
          setClearFilters={setClearFilters}
        />
        <div className="FiltersSectionCenterCol">
          <UsageCheckbox
            filters={filters}
            clearFilters={clearFilters}
            defaultFilters={defaultFiltersValues}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <div className="FiltersSectionRightCol">
          <BoardLengthRange
            filters={filters}
            clearFilters={clearFilters}
            handleFiltersChange={setCurrentFilters}
            setClearFilters={setClearFilters}
          />
        </div>
        <div className="FiltersSectionCenterCol CheckboxContainerWide">
          <BrandCheckbox
            filters={filters}
            clearFilters={clearFilters}
            defaultFilters={defaultFiltersValues}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <SelectColor
          filters={filters}
          clearFilters={clearFilters}
          handleFiltersChange={setCurrentFilters}
        />
        <div className="FiltersSectionCenterCol">
          <SelectRiderLevel
            filters={filters}
            clearFilters={clearFilters}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <div className="FiltersSectionRightCol">
          <SelectBoardWidth
            filters={filters}
            clearFilters={clearFilters}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <SelectFlex
          filters={filters}
          clearFilters={clearFilters}
          handleFiltersChange={setCurrentFilters}
        />
        <div className="FiltersSectionCenterCol">
          <SelectRockerProfile
            filters={filters}
            clearFilters={clearFilters}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <div className="FiltersSectionRightCol">
          <SelectShape
            filters={filters}
            clearFilters={clearFilters}
            handleFiltersChange={setCurrentFilters}
          />
        </div>
        <div className="FiltersSectionButtons">
          <MainButton
            className="btn-pink-default FiltersSectionButton"
            onClick={handleFiltersChange}
          >
            Apply filters
          </MainButton>
          <MainButton
            className="btn-pink-default cancel FiltersSectionButton"
            onClick={handleFiltersClear}
          >
            Clear all filters
          </MainButton>
        </div>
      </CardWrapper>
    </section>
  );
};

export default FiltersSection;
