import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from 'react-redux';

import { getStoredCategories } from '../../../redux/reducers/selectors';
import ProductCheckout from '../../../Components/ProductCheckout/ProductCheckout';
import locations from '../../../utils/locations';
import { handlePrice } from '../../../utils/utils';
import PayPal from '../../../assets/images/pay-pal.png';
import AmazonPay from '../../../assets/images/amazon-pay.png';
import './SummarySection.scss';

const NUMBER_OF_ITEMS_TO_SHOW = 2;

const SummarySection = ({ shoppingCartProducts, selectedShipping }) => {
  const { categories } = useSelector(getStoredCategories);
  const location = useLocation();
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const totalQuantity = shoppingCartProducts
    .map((product) => product.amount)
    .reduce((a, b) => a + b, 0);
  const [promocode, setPromocode] = useState('');
  const [visibleItemsLength, setVisibleItemsLength] = useState(NUMBER_OF_ITEMS_TO_SHOW);
  const checkoutBtn = classNames('CheckoutBtn', { disabled: !shoppingCartProducts.length });

  const handlePromocodeInput = (e) => {
    setPromocode(e.target.value);
  };

  const handleVisibleItemsLength = () => setVisibleItemsLength(visibleItemsLength ? null : 2);

  useEffect(() => {
    const currentSubtotal = shoppingCartProducts
      .map((product) => product.price * product.amount)
      .reduce((a, b) => a + b, 0);

    setSubtotal(currentSubtotal);
    if (selectedShipping) {
      setTotal(currentSubtotal + selectedShipping.price);
    }
  }, [shoppingCartProducts, selectedShipping]);
  return (
    <section className="CheckoutSection">
      <div className="Checkout">
        {location.pathname === locations.shoppingCart && (
          <>
            <Link to={locations.shipping} className={checkoutBtn}>
              Checkout
            </Link>
            <div className="Separator">
              <span>or</span>
            </div>
            <button className="CheckoutPayPalBtn" disabled={!shoppingCartProducts.length}>
              <span>Checkout with</span>
              <img alt="" src={PayPal} />
            </button>
            <button className="CheckoutAmazonBtn" disabled={!shoppingCartProducts.length}>
              <img alt="" src={AmazonPay} />
            </button>
            <div className="UseAmazon">USE YOUR AMAZON ACCOUNT</div>
          </>
        )}
        <div className="OrderSummary">
          <div className=" OrderSummaryTitle"> Order Summary</div>
          <div className="Subtotal">
            <div className="SubtotalTitle">Subtotal:</div>
            <div className="SubtotalPrice">{handlePrice(subtotal)}</div>
          </div>
          <div>
            {selectedShipping && (
              <div className="Shipping">
                <div className="ShippingTitle">
                  Shipping
                  {selectedShipping.title}:
                </div>
                <div className="ShippingType">{handlePrice(selectedShipping.price)}</div>
              </div>
            )}
            {/*   NEED TO BE MADE CLEAR WHEN DO WE ALLOW USER TO DEFINE SHIPPING */}
            {/* <div className="SelectShipping">
              {location.pathname === locations.shoppingCart && (
                <FormControl variant="outlined">
                  <Select
                    value={shipping.index || 0}
                    onChange={handleShippingChange}
                  >
                    {shippingOptionsComponents}
                  </Select>
                </FormControl>
              )}
            </div> */}
          </div>
          <div className="OrderTotalWrapper">
            {selectedShipping && (
              <div className="OrderTotal">
                <div className="OrderTotalTitle">Order Total</div>
                <div className="OrderTotalPrice">{handlePrice(total)}</div>
              </div>
            )}
            <div className="ShippingDesc">
              Shipping and tax are calculated after the shipping step is completed.
            </div>
            <button className="EaseReturns">Ease returns</button>
            <button className="ShippingInformation">Shipping Information</button>
          </div>
        </div>
      </div>

      {location.pathname === locations.shoppingCart && (
        <div className="PromoCode">
          <InputBase
            placeholder="Use promo code"
            value={promocode}
            onChange={handlePromocodeInput}
            endAdornment={
              <InputAdornment position="start">
                <button>+</button>
              </InputAdornment>
            }
          />
        </div>
      )}

      {location.pathname.match(locations.checkoutPagesWrapper) && (
        <div className="CartSummary">
          <div className="CartSummaryHeading">
            <div className="CartSummaryTitle">Cart Summary</div>
            <div className="SummaryItems">
              {totalQuantity}
              &nbsp;
              {totalQuantity === 1 ? 'item' : 'items'}
            </div>
          </div>
          {shoppingCartProducts.map((item, index) => {
            if (!visibleItemsLength || index < visibleItemsLength) {
              return (
                <ProductCheckout {...item} categories={categories} key={`${item.id}${item.size}`} />
              );
            }
            return null;
          })}
          {shoppingCartProducts.length > 2 && (
            <button type="button" onClick={handleVisibleItemsLength}>
              {visibleItemsLength ? 'show full list' : 'hide list'}
            </button>
          )}
        </div>
      )}
    </section>
  );
};

export default SummarySection;
