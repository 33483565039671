import React, { useState } from 'react';

import UserMenu from '../../../Components/UserMenu/UserMenu';
import { ConfirmDialogWrapper } from '../../../Components/ConfirmDialogWrapper/ConfirmDialogWrapper';

const LeftMenu = () => {
  const [isOpened, setIsOpened] = useState(false);
  const closeDialog = () => {
    setIsOpened(false);
  };
  const openDialog = () => {
    setIsOpened(true);
  };

  return (
    <>
      <ConfirmDialogWrapper isOpened={isOpened} closeDialog={closeDialog} />
      <UserMenu handleLogout={openDialog} className="account-sidebar-menu" />
    </>
  );
};

export default LeftMenu;
