import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from '@material-ui/lab';

import { getStoredUser, getStoredCategories } from '../../redux/reducers/selectors';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import HelpText from '../Account/Components/HelpText/HelpText';
import Title from '../Account/Components/Title';
import { OrderWidget } from './OrderWidget/OrderWidget';
import { convertDate, getOrdersListUrl, isLoggedIn, makeRequest } from '../../utils';
import './MyOrdersPage.scss';
import { IOrdersData } from './interfaces';
import { IOrderItems, IUserOrders } from '../../TypesAndInterfaces/Interfaces/User';
import { MainButton } from '../../Components/Buttons/MainButton';

const MAX_ITEMS_PER_PAGE = 10;

export const MyOrdersPage = () => {
  const user = useSelector(getStoredUser);
  const { categories } = useSelector(getStoredCategories);
  const [page, setCurrentPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const [ordersItems, setOrders] = useState<IUserOrders[]>([]);
  const history = useHistory();

  const { isLoading } = useSelector(getStoredUser);
  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getOrders = (page: number) => {
    (async () => {
      const res = await makeRequest(
        'get',
        getOrdersListUrl({
          parameters: {
            Page: page,
            ItemsPerPage: MAX_ITEMS_PER_PAGE,
          },
        })
      );
      if (res.data) {
        setMaxCount(res.data.page.numberOfPages);
        setOrders(res.data.data);
      }
    })();
  };

  useEffect(() => {
    getOrders(page);
  }, [page, user]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const ordersData = (orders: [] | IUserOrders[] = []): IOrdersData[] => {
    return orders.map((order, index) => {
      const category = categories.find(
        (category) => category.id === order.orderItems[0]?.categoryId
      );

      return {
        primaryImageId: getPrimaryImage(order),
        orderId: order.id,
        orderState: order.state,
        category: category?.name,
        orderDate: convertDate(order.creationDate),
        totalPrice: order.totalPrice,
        orderItems: order.orderItems,
        rowVersion: order.rowVersion,
      };
    });
  };

  const redirectToMainPage = () => {
    history.push('/');
  };

  const getPrimaryImage = (order: IUserOrders) => {
    const orderItems = [...order.orderItems];
    orderItems.sort((a: IOrderItems, b: IOrderItems) => (a.price >= b.price ? 1 : -1));
    return orderItems[orderItems.length - 1].primaryImageId;
  };

  const orders = useMemo(() => ordersData(ordersItems), [ordersItems]);

  if (!isLoggedIn()) {
    return <Redirect to="/" />;
  }

  return (
    <main className="MyOrdersPage with-space-for-header">
      <div className="my-orders-head">
        <div className="account-content-wrapper">
          <div className="MyOrdersTitle">
            <Title title="My Orders" />
          </div>
          <HelpText textAlign={'right'} />
        </div>
      </div>
      <div className="my-orders-wrapper">
        <Sidebar />
        <div className="content">
          <div className="my-orders-welcome">
            Check the status and information regarding your online orders. You can also cancel the
            order or request a refund.
          </div>
          {isLoading ? (
            <div>Loading...</div>
          ) : orders.length > 0 ? (
            orders.map((order) => (
              <OrderWidget key={order.orderId} order={order} getOrders={getOrders} page={page} />
            ))
          ) : (
            <div className="my-content-empty">
              You haven&apos;t ordered anything yet. Your order history will be available here
              <MainButton fontSize="12px" className="btn-pink-default" onClick={redirectToMainPage}>
                SHOP NOW
              </MainButton>
            </div>
          )}
        </div>
      </div>
      <div className="my-orders-pagination">
        <Pagination count={maxCount} onChange={handlePageChange} />
      </div>
    </main>
  );
};
