import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getStoredUser } from '../../redux/reducers/selectors';
import { Sidebar } from '../../Components/Sidebar/Sidebar';
import HelpText from '../Account/Components/HelpText/HelpText';
import Title from '../Account/Components/Title';
import locations from '../../utils/locations';
import './MyCouponsPage.scss';

export const MyCouponsPage = () => {
  const user = useSelector(getStoredUser);
  const { userInfo } = user;

  if (!userInfo.loggedWith) {
    return <Redirect to={locations.homepage} />;
  }

  return (
    <main className="MyCouponsPage with-space-for-header">
      <div className="my-coupons-head">
        <div className="head-content-wrapper">
          <Title title="My Coupons" />
          <HelpText textAlign={'right'} />
        </div>
      </div>
      <div className="main-content">
        <Sidebar />
        <div className="content">
          <div className="my-content-welcome">Coming soon...</div>
        </div>
      </div>
    </main>
  );
};
