export const ON_FETCHING_DATA_INIT = '[user] ON_FETCHING_DATA_INIT';
export const ON_FETCHING_DATA_ERROR = '[user] ON_FETCHING_DATA_ERROR';

export const ON_GET_USER_INFO_SUCCESS = '[user] ON_GET_USER_INFO_SUCCESS';

export const ON_UPDATE_USER_INFO_SUCCESS = '[user] ON_UPDATE_USER_INFO_SUCCESS';

export const CLEAR_USER_INFO = '[user] CLEAR_USER_INFO';

export const USER_TABS_COUNT = '[user] USER_TABS_COUNT';

export const ON_GET_USER_SAVED_ADDRESSES_SUCCESS = '[user] ON_GET_USER_SAVED_ADDRESSES_SUCCESS';

export const ON_EDIT_USER_SAVED_ADDRESS_SUCCESS = '[user] ON_EDIT_USER_SAVED_ADDRESS_SUCCESS';

export const ON_SAVE_USER_ADDRESS_SUCCESS = '[user] ON_SAVE_USER_ADDRESS_SUCCESS';

export const ON_DELETE_USER_SAVED_ADDRESS_SUCCESS = '[user] ON_DELETE_USER_SAVED_ADDRESS_SUCCESS';

export const ON_GET_USER_PREFERENCES_SUCCESS = '[user] ON_GET_USER_PREFERENCES_SUCCESS';

export const ON_EDIT_USER_PREFERENCES_SUCCESS = '[user] ON_EDIT_USER_PREFERENCES_SUCCESS';

export const ON_SAVE_PREFERENCES_SUCCESS = '[user] ON_SAVE_PREFERENCES_SUCCESS';
