import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { priceRangeParameters, filtersList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '../RangeStyle.scss';
const PriceRange = ({
  filters,
  clearFilters,
  handleFiltersChange,
  setClearFilters,
}: IFiltersProps) => {
  const [chosenPrices, setChosenPrices] = useState({
    [filtersList.MinPrice]: '',
    [filtersList.MaxPrice]: '',
  });

  const [warning, setWarning] = useState('');

  const handleChangeMinPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (Number(value) >= 0) {
      setChosenPrices({
        ...chosenPrices,
        [filtersList.MinPrice]: value,
      });
      setWarning('');
    } else {
      setWarning('value>=0');
    }
  };

  const handleChangeMaxPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (Number(value) >= 0) {
      setChosenPrices({
        ...chosenPrices,
        [filtersList.MaxPrice]: value,
      });
      setWarning('');
    } else {
      setWarning('value>=0');
    }
  };

  useEffect(() => {
    handleFiltersChange({ ...filters, ...chosenPrices });
    setClearFilters!(false);
  }, [chosenPrices]);

  useEffect(() => {
    if (clearFilters) {
      setChosenPrices({
        [filtersList.MinPrice]: '',
        [filtersList.MaxPrice]: '',
      });
    }
  }, [clearFilters]);

  return (
    <div className="Range">
      <div className="RangeWrapper">
        <div className="RangeTitle">Price range ({priceRangeParameters.unit}):</div>
        <div className="RangeInputWrapper">
          <div className="RangeInputContainer">
            <span className="RangeTitle">From: </span>
            <TextField
              onChange={handleChangeMinPrice}
              value={chosenPrices.MinPrice}
              variant="outlined"
              size="small"
              type="number"
              placeholder="0"
            />
            <p>{warning}</p>
          </div>
          <div className="RangeInputContainer">
            <span className="RangeTitle">To: </span>
            <TextField
              onChange={handleChangeMaxPrice}
              value={chosenPrices.MaxPrice}
              variant="outlined"
              size="small"
              type="number"
              placeholder="1000+"
            />
            <p>{warning}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRange;
