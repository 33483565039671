import React from 'react';
import format from 'date-fns/format';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import imgTrip from '../../assets/images/trip.png';
import imgEvent from '../../assets/images/event.png';

import './EventsList.scss';

interface IDates {
  [key: string]: number;
}

interface ILocation {
  [key: string]: string;
}

interface IContent {
  videoId: number;
  date: Date;
  title: string;
  description: string;
}

interface IEvent {
  type: string;
  cost: number;
  name: string;
  participants: number;
  dates: IDates;
  content: IContent;
  location: ILocation;
}

interface IEventsListProps {
  eventsList: IEvent[];
}

const EventsList = ({ eventsList }: IEventsListProps) => {
  const formattedDate = (date: Date) => format(new Date(date), 'MM/yyyy');

  if (!eventsList.length) {
    return null;
  }

  return (
    <ul className="events-list">
      {eventsList.map((item) => {
        return (
          <li key={item.name} className="events-list-element">
            <img
              className="event-type-img"
              src={item.type === 'Event' ? imgEvent : imgTrip}
              alt=""
            />
            <Card>
              <CardContent>
                <p className="events-card-name">{item.name}</p>
                <p className="events-card-date">{formattedDate(item.content.date)}</p>
                <p className="events-card-description">{item.content.description}</p>
                <div className="events-card-details">
                  <p className="events-card-item">
                    <i className="icon-location" />
                    <span>
                      {item.location.country}, {item.location.city}
                    </span>
                  </p>
                  <p className="events-card-item">
                    <span className="icon-dolar"></span>
                    <span className="events-list-cost">{item.cost}</span>
                  </p>
                </div>
              </CardContent>
            </Card>
          </li>
        );
      })}
    </ul>
  );
};

export default EventsList;
