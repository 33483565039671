import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { getStoredUser } from '../../../../redux/reducers/selectors';
import { AUTH_API } from '../../../../services/auth.services';
import { inputTypes, fieldNames, notificationTypes } from '../../../../utils';
import { changePasswordValidationSchema } from '../../../../utils';
import SimpleFormWrapper from '../../../../Components/SimpleFormWrapper/SimpleFormWrapper';
import { MainButton } from '../../../../Components/Buttons/MainButton';
import { NotificationsContext } from '../../../../contexts/contexts';

import './SimpleForm.scss';

export const ResetPasswordForm = ({ closeModal }) => {
  const { notifications, setNotifications } = useContext(NotificationsContext);
  const user = useSelector(getStoredUser);
  const { userInfo } = user;

  const [isValid, setIsValid] = useState(false);
  const [initialErrors, setInitialErrors] = useState({ isError: false });
  const [passwordChanged, setIsPasswordChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fields = {
    [fieldNames.password]: { title: 'Current Password', type: inputTypes.password },
    [fieldNames.newPassword]: { title: 'New Password', type: inputTypes.password },
    [fieldNames.newPasswordConfirmation]: {
      title: 'Confirm New Password',
      type: inputTypes.password,
    },
  };
  const [formData, setFormData] = useState({
    [fieldNames.password]: '',
    [fieldNames.newPassword]: '',
    [fieldNames.newPasswordConfirmation]: '',
  });
  const handleValueChange = (field) => (e) => {
    setFormData((data) => ({ ...data, [field]: e.target.value }));
    setInitialErrors({});
  };
  const handleSubmit = async () => {
    if (isValid) {
      setIsLoading(true);
      const { err } = await AUTH_API.changePassword({
        email: userInfo.email,
        password: formData.password,
        newPassword: formData.newPassword,
        confirmNewPassword: formData.newPasswordConfirmation,
      });

      err
        ? setInitialErrors({ isError: true, message: err.response.data.message })
        : setIsPasswordChanged(true);
      setIsLoading(false);
    }
  };

  if (passwordChanged) {
    setNotifications([
      ...notifications,
      {
        type: notificationTypes.success,
        message: 'Your password has been changed successfully.',
        id: Date.now(),
      },
    ]);
  } else if (initialErrors.isError === true) {
    setNotifications([
      ...notifications,
      {
        type: notificationTypes.error,
        message: 'Something went wrong.',
        id: Date.now(),
      },
    ]);
  }
  return (
    <div className="profile-popup-form">
      <div className="profile-popup-form-header">Reset your password?</div>
      <SimpleFormWrapper
        key="reset-password-form"
        fields={fields}
        initialValues={formData}
        handleValueChange={handleValueChange}
        validationSchema={changePasswordValidationSchema}
        setIsValidForm={setIsValid}
        initialErrors={initialErrors}
      />
      <MainButton
        className="sign-in-sign-up-submit-button my-account-buttons"
        onClick={handleSubmit}
        isDisabled={!isValid}
        isLoading={isLoading}
      >
        Change Password
      </MainButton>
    </div>
  );
};
