import {
  ON_FETCHING_PRDCTS_SUCCESS,
  ON_FETCHING_PRDCTS_ERROR,
  ON_FETCHING_PRDCTS_INIT,
} from './productsConstants';
import { makeRequest } from '../../../utils/utils';
import { getProductsUrl } from '../../../utils/endpoints';
import { IProduct } from '../../../TypesAndInterfaces/Interfaces/Product';

export const getAllProducts = () => async (
  dispatch: (arg0: { type: string; payload?: IProduct[] }) => void
) => {
  dispatch({
    type: ON_FETCHING_PRDCTS_INIT,
  });

  const { data, err } = await makeRequest('get', getProductsUrl());

  if (data) {
    return dispatch({
      type: ON_FETCHING_PRDCTS_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_PRDCTS_ERROR,
    });
  }
};
