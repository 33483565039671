import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import CreateKit from '../../../assets/images/create-kit.png';
import CreateKitRectangle from '../../../assets/images/create-kit-rectangle.png';

const CreateKitBanner: FC = () => {
  return (
    <Box ml="40px" display="flex">
      <Box maxWidth="421px">
        <img src={CreateKit} alt="" />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" ml="-40px">
        <Box display="flex" flexDirection="column" alignItems={'flex-end'}>
          <Typography
            style={{
              fontSize: '24px',
              textTransform: 'uppercase',
              lineHeight: '36px',
              color: '#fff',
            }}
          >
            Create your
          </Typography>
          <Typography
            style={{
              fontSize: '24px',
              textTransform: 'uppercase',
              lineHeight: '36px',
              color: '#fff',
            }}
          >
            own kit
          </Typography>
        </Box>
        <img src={CreateKitRectangle} alt="" />
      </Box>
    </Box>
  );
};

export default CreateKitBanner;
