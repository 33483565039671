import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAllCountries } from './redux/actions/countries/countriesActions';
import { getShippingInfo } from './redux/actions/shippingInfo/shippingInfoActions';
import { getAllCategories } from './redux/actions/categories/categoriesActions';
import { ShoppingCartContext, NotificationsContext } from './contexts/contexts';
import useLocalStorage from './hooks/useLocalStorage';
import locations from './utils/locations';
import { Header } from './Components/Header/Header';
import HomePage from './Pages/HomePage/HomePage';
import ProductPage from './Pages/ProductPage/ProductPage';
import ProductsPage from './Pages/ProductsPage/ProductsPage';
import { ShoppingPagesWrapper } from './Pages/ShoppingPagesWrapper/ShoppingPagesWrapper';
import { AccountWrapper } from './Pages/Account/AccountWrapper';
import NotificationsList from './Components/NotificationsList/NotificationsList';
import { SignInSignUpPage } from './Pages/SignInSignUpPage/SignInSignUpPage';
import { Footer } from './Components/Footer/Footer';
import useAuthHandler from './hooks/useAuthHandler';
import useTabsCount from './hooks/useTabsCount';
import useUserInfo from './hooks/useUserInfo';
import PageNotFound from './Pages/Errors/PageNotFound';
import EmailRedirectPage from './Pages/EmailRedirectPage/EmailRedirectPage';
import { MyOrdersPage } from './Pages/MyOrdersPage/MyOrdersPage';
import { OrderDetailsPage } from './Pages/OrdersDetailsPage/OrderDetailsPage';
import { MyCouponsPage } from './Pages/MyCouponsPage/MyCouponsPage';
import { MyWishlistPage } from './Pages/MyWishlistPage/MyWishlistPage';
import './styles/main.scss';
import DeleteAccount from './Components/DeleteAccount/DeleteAccount';
import DeleteDataFacebook from './Pages/DeleteMessageFacebook/DeleteDataFacebook.tsx';
import { ProductNotFound } from './Pages/ProductPage/Sections/ProductNotFound/ProductNotFound';
import PreferencesPage from './Pages/PreferencesPage/PreferencesPage';
import SectionPage from './Pages/SectionPage/SectionPage';
import CategoryPage from './Pages/CategoryPage/CategoryPage';
import TripsPage from './Pages/TripsPage/TripsPage';
import ProductList from './Components/ProductList/ProductList';
import { getAllProducts } from './redux/actions/products/productsActions';

console.info('Application started!');

const App = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [shoppingCartProducts, setShoppingCartProducts] = useState([]);
  useLocalStorage('shoppingCart', shoppingCartProducts, setShoppingCartProducts);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    let total = 0;
    for (let i = 0, _len = shoppingCartProducts.length; i < _len; i++) {
      total += shoppingCartProducts[i].amount;
    }

    setQuantity(total);
  }, [setQuantity, shoppingCartProducts]);

  useTabsCount();
  useUserInfo(setNotifications);
  useAuthHandler();

  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllCountries());
    dispatch(getAllCategories());
    dispatch(getShippingInfo());
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      <ShoppingCartContext.Provider
        value={{
          shoppingCartProducts,
          setShoppingCartProducts,
        }}
      >
        <Router>
          <div className="App">
            <NotificationsList />
            <Header shoppingCartProductsCount={quantity} />

            <Switch>
              <Route path={locations.passwordReset} component={EmailRedirectPage} />
              <Route path={locations.confirmEmail} component={EmailRedirectPage} />
              <Route path={locations.deleteAccount} component={DeleteAccount} />
              <Route path={locations.deleteDataFacebook} component={DeleteDataFacebook} />
              <Route path={locations.homepage} exact component={HomePage} />
              <Route path={locations.subCategoryPage()} exact component={ProductsPage} />
              <Route path={locations.productNotFound} exact component={ProductNotFound} />
              <Route path={locations.sectionPage()} exact component={SectionPage} />
              <Route path={locations.trips} component={TripsPage} />
              <Route path={locations.categoryPage()} exact component={CategoryPage} />
              <Route path={locations.productPage()} component={ProductPage} />
              <Route path={`${locations.shoppingPagesWrapper}`} component={ShoppingPagesWrapper} />
              <Route path={`${locations.signInAndUpWrapper}`} component={SignInSignUpPage} />
              <Route path={`${locations.account}`} component={AccountWrapper} />
              <Route path={locations.notificationPreferences} component={PreferencesPage} />
              <Route path={`${locations.myOrders}`} exact component={MyOrdersPage} />
              <Route path={`${locations.orderDetailsPage()}`} exact component={OrderDetailsPage} />
              <Route path={`${locations.myCoupons}`} component={MyCouponsPage} />
              <Route path={`${locations.wishlist}`} component={MyWishlistPage} />
              <Route path={`${locations.products}`} component={ProductList} />
              <Route path="*" component={PageNotFound} />
            </Switch>

            <Footer />
          </div>
        </Router>
      </ShoppingCartContext.Provider>
    </NotificationsContext.Provider>
  );
};

export default App;
