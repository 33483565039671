import { useState, useEffect } from 'react';
import axios from 'axios';

const useGetData = (initialUrl, initialData = []) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await axios(initialUrl);
        setData(result.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [initialUrl]);

  return { data, isLoading, error };
};

export default useGetData;
