import React from 'react';
import ImageGallery from 'react-image-gallery';

import { getImagesUrl, imageSizes } from '../../../../utils';
import './ProductSlider.scss';
import { IImage } from '../../../../TypesAndInterfaces/Interfaces';

interface IProductSliderProps {
  images: IImage[];
}

const ProductSlider = ({ images }: IProductSliderProps) => {
  if (!images) {
    return null;
  }
  const srcArray = images
    .sort(({ isPrimary }) => (isPrimary ? -1 : 0))
    .map(({ id }) => ({
      original: getImagesUrl({ id }),
      // @ts-ignore
      // until Utils refactoring
      thumbnail: getImagesUrl({ id, parameters: imageSizes['120x160'] }),
    }));

  return <ImageGallery items={srcArray} />;
};

export default ProductSlider;
