import React, { useEffect, useState } from 'react';

import {
  makeRequest,
  REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER,
  REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER,
} from '../../utils/utils';
import { stateStatuses } from '../../utils/constants';
import { getEventsUrl } from '../../utils/endpoints';
import EventsList from './EventsList';

import './TripsPage.scss';

interface IDates {
  [key: string]: number;
}

interface ILocation {
  [key: string]: string;
}

interface IContent {
  videoId: number;
  date: Date;
  title: string;
  description: string;
}

interface IEvent {
  type: string;
  cost: number;
  name: string;
  participants: number;
  dates: IDates;
  content: IContent;
  location: ILocation;
}

const TripsPage = () => {
  const [pageStatus, setPageStatus] = useState(stateStatuses.loading);
  const [events, setEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await makeRequest('get', getEventsUrl());

        setEvents(data);
        setPageStatus(stateStatuses.success);
      } catch (err) {
        setPageStatus(stateStatuses.failure);
      }
    })();
  }, []);

  return (
    <main className="trips-page">
      <h2 className="trips-page-title">Trips &#38; Events</h2>
      {pageStatus === stateStatuses.loading && REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER}
      {pageStatus === stateStatuses.failure && REMOVE_WITH_MARKUP_ERROR_PLACEHOLDER}
      {pageStatus === stateStatuses.success && <EventsList eventsList={events} />}
    </main>
  );
};

export default TripsPage;
