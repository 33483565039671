import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';

import { getStoredUser } from '../../redux/reducers/selectors';
import Icon from '../Icon/Icon';
import PopperWrapper from '../PopperWrapper/PopperWrapper';
import UserIconPopup from '../UserIconPopup/UserIconPopup';
import { SignInSignUpWrapper } from '../SignInSignUpWrapper/SignInSignUpWrapper';
import { REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER } from '../../utils/utils';
import './UserLinkHeader.scss';
import { Popup } from '../Popup/Popup';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { ConfirmDialogWrapper } from '../../Components/ConfirmDialogWrapper/ConfirmDialogWrapper';

const UserLinkHeader = ({ sidebar }) => {
  sidebar = sidebar ? (sidebar = true) : false;

  const user = useSelector(getStoredUser);
  const { userInfo, isLoading } = user;

  const userLinkClass = classNames({ UserLink: !sidebar, 'UserLink sidebar': sidebar });

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegisterConfirmModalOpen, setIsRegisterConfirmModalOpen] = useState(false);
  const [
    pswrdResetConfirmEmailSentSuccessModal,
    setPswrdResetConfirmEmailSentSuccessModal,
  ] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const componentRef = useRef();

  const handleIconClick = (e) => {
    e.preventDefault();
    if (userInfo.loggedWith) {
      setIsPopperOpen(() => !isPopperOpen);
    } else {
      setIsModalOpen(() => true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const closeDialog = () => {
    setIsOpened(false);
  };
  const openDialog = () => {
    setIsOpened(true);
  };

  if (isLoading) {
    return REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER;
  }

  let popupProps;

  if (pswrdResetConfirmEmailSentSuccessModal) {
    popupProps = {
      open: pswrdResetConfirmEmailSentSuccessModal,
      onClose: setPswrdResetConfirmEmailSentSuccessModal,
      title: "Don't worry!",
      text: 'Your password reset email has been sent. Check your email.',
    };
  }

  if (isRegisterConfirmModalOpen) {
    popupProps = {
      open: isRegisterConfirmModalOpen,
      onClose: setIsRegisterConfirmModalOpen,
      title: 'We are almost there!',
      text:
        'We have sent an activation link to the email address you indicated during the registration process. Please check your inbox and follow the link to create an account.',
    };
  }

  return (
    <>
      <ConfirmDialogWrapper isOpened={isOpened} closeDialog={closeDialog} />
      <ClickAwayListener onClickAway={closePopper}>
        <Link to="#" className={userLinkClass} onClick={handleIconClick} ref={componentRef}>
          {userInfo.loggedWith ? <Icon className="NavigationIcon" icon="person" /> : 'SIGN IN'}
        </Link>
      </ClickAwayListener>

      <PopperWrapper
        isOpenBooleans={[isPopperOpen]}
        anchorEl={componentRef.current}
        handleLocationChange={closePopper}
        className="user-menu-popper-wrapper"
      >
        <UserIconPopup
          firstName={userInfo?.firstName}
          lastName={userInfo?.lastName}
          loggedWith={userInfo.loggedWith}
          isLoading={isLoading}
          setIsModalOpen={setIsModalOpen}
          openDialog={openDialog}
        />
      </PopperWrapper>

      <ModalWrapper open={isModalOpen} onClose={closeModal}>
        <>
          <SignInSignUpWrapper
            isModal
            closeModal={closeModal}
            setIsRegisterConfirmModalOpen={setIsRegisterConfirmModalOpen}
            setPswrdResetConfirmEmailSentSuccessModal={setPswrdResetConfirmEmailSentSuccessModal}
          />
        </>
      </ModalWrapper>

      {(pswrdResetConfirmEmailSentSuccessModal || isRegisterConfirmModalOpen) && (
        <Popup {...popupProps} />
      )}
    </>
  );
};

export default UserLinkHeader;
