import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { ClickAwayListener } from '@material-ui/core';

import { NavigationDropdown } from '../NavigationDropdown/NavigationDropdown';
import PopperWrapper from '../PopperWrapper/PopperWrapper';
import { navigationItemsTitles } from '../../utils/constants';
import locations from '../../utils/locations';

import './NavigationMenu.scss';

interface IMenuProps {
  mobileView: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export const NavigationMenu = ({ mobileView = true, isMenuOpen, setIsMenuOpen }: IMenuProps) => {
  const navigationRef = useRef<HTMLElement>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>();

  const closePopper = () => {
    setActiveFilter(null);
  };

  const handleHover = (item: string) => () => {
    setActiveFilter(navigationItemsTitles[item]);
    onDrawerCloseHandler();
  };

  const onDrawerCloseHandler = () => {
    setIsMenuOpen(false);
  };

  const navItems = Object.values(navigationItemsTitles).map((value, index) => {
    return (
      <Link
        key={index}
        to={{
          pathname:
            value !== 'Trips'
              ? locations.sectionPage(navigationItemsTitles[value])
              : locations.trips,
        }}
        onMouseEnter={handleHover(value)}
        className={activeFilter === value ? 'NavigationLink active' : 'NavigationLink'}
      >
        {navigationItemsTitles[value]}
      </Link>
    );
  });

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <nav
        className={'NavigationMenu' + (mobileView && ' mobileView')}
        ref={navigationRef}
        onMouseLeave={closePopper}
      >
        {navItems}
        {activeFilter && activeFilter !== 'Trips' && (
          <PopperWrapper
            isOpenBooleans={[!!activeFilter]}
            anchorEl={navigationRef.current}
            handleLocationChange={closePopper}
            className="NavigationDropdownWrapper"
          >
            <NavigationDropdown activeFilter={activeFilter} />
          </PopperWrapper>
        )}
        <Drawer
          anchor="left"
          open={Boolean(isMenuOpen)}
          onClose={onDrawerCloseHandler}
          classes={{ paper: 'paper' }}
        >
          {navItems}
        </Drawer>
      </nav>
    </ClickAwayListener>
  );
};
