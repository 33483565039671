import React, { useState, useContext, ChangeEvent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import findIndex from 'lodash/findIndex';

import { updateShippingMethod } from '../../redux/actions/shippingInfo/shippingInfoActions';
import { Shipping } from './Sections/Shipping/Shipping';
import { Wrapper } from './Sections/Wrapper/Wrapper';
import SummarySection from '../ShoppingPagesWrapper/SummarySection/SummarySection';
import { ModalWrapper } from '../../Components/ModalWrapper/ModalWrapper';
import { Popup } from '../../Components/Popup/Popup';
import { EditAddressForm } from '../Account/Components/Forms/EditAddressForm';
import { ShoppingCartContext } from '../../contexts/contexts';
import locations from '../../utils/locations';
import { IShippingMethodInfo, IAddress, IOrderInfo } from '../../TypesAndInterfaces/Interfaces';
import './CheckoutPage.scss';

// STYLE MOCKS

const activeStyleStep = {
  backgroundColor: '#1d1d1d',
  borderRadius: '50%',
  color: '#fff',
  padding: '5px 16px',
  display: 'inline-block',
  verticalAlign: 'middle',
  margin: '0 10px 0 0',
};

const activeStyleTitle = {
  fontSize: '16px',
  color: '#000',
};

const style = { margin: '15px' };

const stepsOfCheckout = [
  { step: '1', title: 'Shipping', path: locations.shipping },
  { step: '2', title: 'Order Details', path: locations.orderReview },
  { step: '3', title: 'Payment', path: locations.payment },
];

export const resultPopupState = {
  placingOrderFail: 'PLACING_ORDER_FAILED',
  sendingPaymentSuccess: 'SENDING_PAYMENT_SUCCESSFULLY',
  sendingPaymentFail: 'SENDING_PAYMENT_FAILED',
  default: '',
};

interface ICheckoutPageProps extends RouteComponentProps {}

export const CheckoutPage = ({ history, location }: ICheckoutPageProps) => {
  const { shoppingCartProducts, setShoppingCartProducts } = useContext(ShoppingCartContext);

  const dispatch = useDispatch();

  const [selectedShipping, setSelectedShipping] = useState<IShippingMethodInfo | null>(null);
  const [availableShippingOptions, setAvailableShippingOptions] = useState<
    IShippingMethodInfo[] | []
  >([]);
  const [order, setOrder] = useState<IOrderInfo | null>(null);
  const [showResultPopup, setShowResultPopup] = useState<string>(resultPopupState.default);
  const [showEditAddressForm, setShowEditAddressForm] = useState<Partial<IAddress> | null>(null);

  const handleShippingChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      e.preventDefault();
      const index = findIndex(availableShippingOptions, {
        shippingMethod: e.target.value,
      });
      setSelectedShipping(availableShippingOptions[index]);
      dispatch(updateShippingMethod(availableShippingOptions[index]));
    }
  };

  const clearShoppingCartAndOrder = () => {
    setShoppingCartProducts([]);
    setOrder(null);
  };

  const redirectToShoppingCart = () => history.push(locations.shoppingCart);
  const redirectToPayment = () => history.push(locations.payment);
  const redirectToHomePage = () => history.push(locations.homepage);

  const closeModal = () => {
    if (!!showEditAddressForm) {
      setShowEditAddressForm(null);
      return;
    }

    setShowResultPopup(resultPopupState.default);

    if (showResultPopup === resultPopupState.placingOrderFail) {
      redirectToShoppingCart();
    }
    if (showResultPopup === resultPopupState.sendingPaymentSuccess) {
      redirectToHomePage();
    }
    if (showResultPopup === resultPopupState.sendingPaymentFail) {
      redirectToPayment();
    }
  };

  let modalProps = {
    open: false,
    onClose: () => {},
    children: <></>, // because of restrictions of Modal of material-ui package
  };

  let resultPopupProps = {
    open: false,
    title: '',
    text: '',
    onClose: () => {},
  };

  if (showResultPopup === resultPopupState.placingOrderFail) {
    resultPopupProps = {
      open: true,
      title: 'Sorry!',
      text: 'Something went wrong. Please try again later',
      onClose: closeModal,
    };
  }

  if (showResultPopup === resultPopupState.sendingPaymentSuccess) {
    resultPopupProps = {
      open: true,
      title: 'Thank you!',
      text: 'Your order will be delivered soon! The email with order details was sent to you!',
      onClose: closeModal,
    };
  }

  if (showResultPopup === resultPopupState.sendingPaymentFail) {
    resultPopupProps = {
      open: true,
      title: 'Sorry!',
      text: 'Something went wrong. Please check your payment details!',
      onClose: closeModal,
    };
  }

  if (showResultPopup) {
    modalProps = {
      open: !!showResultPopup,
      onClose: closeModal,
      children: <Popup {...resultPopupProps} />,
    };
  }

  if (!!showEditAddressForm) {
    modalProps = {
      open: !!showEditAddressForm,
      onClose: closeModal,
      children: (
        <>
          <EditAddressForm
            isEdit={true}
            formData={showEditAddressForm}
            setFormData={setShowEditAddressForm}
            editAddressID={showEditAddressForm.id}
            closeModal={closeModal}
          />
        </>
      ),
    };
  }

  return (
    <div className="CheckoutPage">
      <div className="StepsOfCheckoutOuter">
        <ul className="StepsOfCheckout">
          {stepsOfCheckout.map((item) => (
            <li key={item.title}>
              <span
                style={item.path === location.pathname ? activeStyleStep : style}
                className="Step"
              >
                {item.step}
              </span>
              <span
                style={item.path === location.pathname ? activeStyleTitle : style}
                className="StepTitle"
              >
                {item.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="CheckoutPageContent">
        <div className="ShippingWrapper">
          <Route
            path={locations.shipping}
            render={(props) => (
              <Shipping
                {...props}
                handleShippingChange={handleShippingChange}
                selectedShipping={selectedShipping}
                setSelectedShipping={setSelectedShipping}
                availableShippingOptions={availableShippingOptions}
                setAvailableShippingOptions={setAvailableShippingOptions}
                setOrder={setOrder}
                setShowResultPopup={setShowResultPopup}
                setShowEditAddressForm={setShowEditAddressForm}
              />
            )}
          />
          <Route
            path={`${locations.paymentAndOrderReviewWrapper}`}
            render={(props) => (
              <Wrapper
                {...props}
                order={order}
                clearShoppingCartAndOrder={clearShoppingCartAndOrder}
                setShowResultPopup={setShowResultPopup}
              />
            )}
          />
        </div>

        <SummarySection
          shoppingCartProducts={shoppingCartProducts}
          selectedShipping={selectedShipping}
        />

        {(showResultPopup || !!showEditAddressForm) && <ModalWrapper {...modalProps} />}
      </div>
    </div>
  );
};
