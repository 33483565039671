import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { getStoredUser } from '../../redux/reducers/selectors';
import HelpText from '../Account/Components/HelpText/HelpText';
import Title from '../Account/Components/Title';
import { Product } from './Product/Product';
import { convertDate, getUniqueKey } from '../../utils';
import { shippingOptions } from '../../utils';
import locations from '../../utils/locations';
import { IOrderItem } from '../../TypesAndInterfaces/Interfaces';
import './OrderDetailsPage.scss';

export const OrderDetailsPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const {
    userInfo: { orders },
  } = useSelector(getStoredUser);

  const order = orders?.find((item: IOrderItem) => item.id === +id);

  if (!order) return null;

  const { email: orderEmail, phone: orderPhoneNumber } = order.orderInfo.contactData;

  const { creationDate, orderInfo, totalPrice, orderItems } = order;

  const orderDate = convertDate(creationDate);
  const shippingMethod = shippingOptions.find(
    (option) => option.title === orderInfo.shippingMethod
  );
  const orderTotal = totalPrice + orderInfo.shippingPrice;

  const redirectToMyOrdersPage = () => {
    history.push(locations.myOrders);
  };

  return (
    <main className="orders-details-page with-space-for-header">
      <div className="orders-details-header">
        <div className="header-wrapper">
          <div className="btn-and-title">
            <button className="btn" onClick={redirectToMyOrdersPage}>
              <ArrowBackIosIcon />
              <span>BACK</span>
            </button>
            <Title title="Order Details" />
          </div>
          <HelpText textAlign="right" />
        </div>
      </div>

      <div className="content-wrapper">
        <div className="content-heading">Details of your order are shown below.</div>
        <div className="details">
          <div className="order-info-wrapper">
            <div className="info">
              <div className="title">Order Number</div>
              <div>{id}</div>
            </div>
            <div className="info">
              <div className="title">Order Date</div>
              <div>{orderDate}</div>
            </div>
            <div className="info">
              <div className="title">Shipping Address</div>
              <div>{orderInfo.fullName}</div>
              <div>{orderEmail}</div>
              <div>{orderPhoneNumber}</div>
              <div>{orderInfo.addressInfo.address}</div>
              {
                // TODO add state data from API when it will be implemented (GLJ-1157)
              }
              <div>{`${orderInfo.addressInfo.city},
              ${orderInfo.addressInfo.state ? ` ${orderInfo.addressInfo.state}` : ''} ${
                orderInfo.addressInfo.postalCode
              }`}</div>
              <div>{orderInfo.addressInfo.country.name}</div>
            </div>
            <div className="info">
              <div className="title">Shipping Method</div>
              <div>{`${shippingMethod?.title} ${shippingMethod?.timeEstimate}`}</div>
              <div>{`$${orderInfo.shippingPrice}`}</div>
            </div>
            <div className="info">
              <div className="title">Order Total</div>
              <div>{`$${orderTotal}`}</div>
            </div>
            <div className="info">
              <div className="title">Payment Information</div>
              {
                // TODO add card data from API when it will be implemented (GLJ-1157)
              }
              <div>**** **** **** {orderInfo.orderPayment.last4Digits}</div>
            </div>
          </div>

          <div className="order-items-wrapper">
            {
              // TODO check that orderItems have properties: color and size. It should be implemented in GLJ-1157
            }
            {orderItems &&
              orderItems.map((item: IOrderItem) => (
                <Product
                  {...item}
                  productId={item.productId.toString()}
                  key={getUniqueKey('product')}
                />
              ))}
          </div>
        </div>
      </div>
    </main>
  );
};
