import { getUrl } from './endpointsUtils';

interface IGetUrlProps {
  id?: string | number;
  parameters?: any;
  defaultParameters?: any;
}

export interface IGetUrlWithRouteProps extends IGetUrlProps {
  route: string;
}

const IS_PRODUCT_SERVER = true;

// steps to define .env variables may be found in README.md
const API_URL = IS_PRODUCT_SERVER
  ? process.env.REACT_APP_PRODUCT_SERVER_URL
  : process.env.REACT_APP_STUB_SERVER_URL;

const toUrlString = (route: string) => `${API_URL}/${route}`;

const routes = {
  countries: toUrlString('countries'),
  shippings: toUrlString('shipping'),
  bindings: toUrlString('bindings'),
  categories: toUrlString('categories'),

  orders: toUrlString('orders'),
  ordersPay: toUrlString('orders/pay'),
  cancelOrder: toUrlString('orders/cancel'),
  ordersList: toUrlString('orders/myorders'),

  latest: toUrlString('products/latest'),
  latestBoards: toUrlString('products/latestboards'),
  products: toUrlString('products/search-by-filter'),
  productsSearch: toUrlString('products/search'),
  productsSelect: toUrlString('products'),
  events: toUrlString('events'),
  files: toUrlString('files'),
  images: toUrlString('images'),
  profile: toUrlString('customers/info'),
  updateProfile: toUrlString('customers/update'),
  wishlist: toUrlString('wishlist'),

  saveAddress: toUrlString('customers/SaveAddress'),
  getAddress: toUrlString('customers/GetAddress'),
  editAddress: toUrlString('customers/EditAddress'),
  deleteAddress: toUrlString('customers/DeleteAddress'),

  sendDeleteAccountConfirmEmail: toUrlString('customers/sendDeleteEmailConfirmation'),
  deleteAccount: toUrlString('customers/deleteCustomer'),

  deleteDataFacebook: toUrlString('customers/deleteCustomer'),

  sendOrderInformationEmail: toUrlString('send-order-information-email'),

  register: toUrlString('register'),
  registerConfirm: toUrlString('confirm-user'),
  sendRegisterConfirmEmail: toUrlString('user-confirmation'),
  login: toUrlString('login'),
  facebookLogin: toUrlString('fb-auth'),
  googleLogin: toUrlString('google-auth'),
  refresh: toUrlString('refresh'),
  logout: toUrlString('logout'),
  validateToken: toUrlString('validate-token'),

  sendPassResetEmail: toUrlString('reset-pass'),
  resetPassword: toUrlString('password-reset'),
  checkResetPasswordToken: toUrlString('verify-ResetPassword-Token'),
  changePassword: toUrlString('change-password'),
  userExists: toUrlString('customers/check-if-user-exists'),
  notificationSettings: toUrlString('NotificationSettings'),
  userPreferences: (customerId: string) =>
    toUrlString(`Customers/${customerId}/NotificationSettings`),
};

export const getUserProfile = () => getUrl({ route: routes.profile });

export const updateUserProfile = () => getUrl({ route: routes.updateProfile });

export const getCategoriesUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.categories,
    id,
    parameters,
    defaultParameters,
  });
};

export const getBoardByIdUrl = ({
  id,
  parameters = undefined,
  defaultParameters = undefined,
}: IGetUrlProps) => {
  return getUrl({
    route: routes.productsSelect,
    id,
    parameters,
    defaultParameters,
  });
};

export const getProductsLatestUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.latest,
    id,
    parameters,
    defaultParameters,
  });

export const getLatestBoardsUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.latestBoards,
    id,
    parameters,
    defaultParameters,
  });

export const getEventsUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.events,
    id,
    parameters,
    defaultParameters,
  });

export const getBindingsUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.bindings,
    id,
    parameters,
    defaultParameters,
  });

export const getProductsUrl = ({
  id,
  parameters,
  defaultParameters,
}: IGetUrlProps = {}): string => {
  return getUrl({
    route: routes.productsSearch,
    id,
    parameters,
    defaultParameters,
  });
};

export const getFilteredProductsUrl = ({
  id,
  parameters,
  defaultParameters,
}: IGetUrlProps = {}): string => {
  return getUrl({
    route: routes.products,
    id,
    parameters,
    defaultParameters,
  });
};
export const getFilesUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.files,
    id,
    parameters,
    defaultParameters,
  });

export const getImagesUrl = ({
  id,
  parameters = undefined,
  defaultParameters = undefined,
}: IGetUrlProps) => {
  if (id) {
    return getUrl({
      route: routes.images,
      id,
      parameters,
      defaultParameters,
    });
  }
};

export const getCountriesUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.countries,
    id,
    parameters,
    defaultParameters,
  });

export const getShippingsUrl = ({
  id = undefined,
  parameters,
  defaultParameters = undefined,
}: IGetUrlProps = {}) =>
  getUrl({
    route: routes.shippings,
    id,
    parameters,
    defaultParameters,
  });

export const getOrdersUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.orders,
    id,
    parameters,
    defaultParameters,
  });

export const getCheckUserExistsUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.userExists,
    id,
    parameters,
    defaultParameters,
  });

export const getOrdersPayUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.ordersPay,
    id,
    parameters,
    defaultParameters,
  });

export const getOrderCancelUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.cancelOrder,
    id,
    parameters,
    defaultParameters,
  });

export const getRegisterUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.register,
    id,
    parameters,
    defaultParameters,
  });

export const getRegisterConfirmUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.registerConfirm,
    id,
    parameters,
    defaultParameters,
  });

export const getSendRegisterConfirmEmailUrl = () =>
  getUrl({
    route: routes.sendRegisterConfirmEmail,
  });

export const getDeleteAccountConfirmEmailUrl = () =>
  getUrl({
    route: routes.sendDeleteAccountConfirmEmail,
  });

export const getDeleteAccountUrl = () =>
  getUrl({
    route: routes.deleteAccount,
  });

export const getDeleteDataFacebookUrl = () =>
  getUrl({
    route: routes.deleteDataFacebook,
  });

export const getOrderInformationEmail = () =>
  getUrl({
    route: routes.sendOrderInformationEmail,
  });

export const getLoginUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.login,
    id,
    parameters,
    defaultParameters,
  });

export const getPasswordResetUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.resetPassword,
    id,
    parameters,
    defaultParameters,
  });

export const getSendResetPasswordEmailUrl = ({
  id,
  parameters,
  defaultParameters,
}: IGetUrlProps = {}) =>
  getUrl({
    route: routes.sendPassResetEmail,
    id,
    parameters,
    defaultParameters,
  });

export const getCheckResetPasswordTokenUrl = ({
  id,
  parameters,
  defaultParameters,
}: IGetUrlProps = {}) =>
  getUrl({
    route: routes.checkResetPasswordToken,
    id,
    parameters,
    defaultParameters,
  });

export const getChangePasswordUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.changePassword,
    id,
    parameters,
    defaultParameters,
  });

export const getFacebookLoginUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.facebookLogin,
    id,
    parameters,
    defaultParameters,
  });

export const getGoogleLoginUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.googleLogin,
    id,
    parameters,
    defaultParameters,
  });

export const getValidateTokenUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.validateToken,
    id,
    parameters,
    defaultParameters,
  });

export const getRefreshUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.refresh,
    id,
    parameters,
    defaultParameters,
  });

export const getLogoutUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) =>
  getUrl({
    route: routes.logout,
    id,
    parameters,
    defaultParameters,
  });

export const getSaveAddressUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.saveAddress,
    id,
    parameters,
    defaultParameters,
  });
};

export const getGetAddressUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.getAddress,
    id,
    parameters,
    defaultParameters,
  });
};

export const getDeleteAddressUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.deleteAddress,
    id,
    parameters,
    defaultParameters,
  });
};

export const getEditAddressUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.editAddress,
    id,
    parameters,
    defaultParameters,
  });
};

export const getWishListUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.wishlist,
    id,
    parameters,
    defaultParameters,
  });
};

export const getOrdersListUrl = ({ id, parameters, defaultParameters }: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.ordersList,
    id,
    parameters,
    defaultParameters,
  });
};

export const getUserPreferencesUrl = (id: string) =>
  getUrl({
    route: routes.userPreferences(id),
  });

export const getNotificationSettingsUrl = ({
  id,
  parameters,
  defaultParameters,
}: IGetUrlProps = {}) => {
  return getUrl({
    route: routes.notificationSettings,
    id,
    parameters,
    defaultParameters,
  });
};
