import React, { Dispatch, SetStateAction } from 'react';

import { StripeContainer } from './StripeContainer';
import './Payment.scss';
import { IOrderInfo } from '../../../../../../TypesAndInterfaces/Interfaces';

interface IPaymentProps {
  order: IOrderInfo | null;
  clearShoppingCartAndOrder: () => void;
  setShowResultPopup: Dispatch<SetStateAction<string>>;
  userEmail: string;
}

export const Payment = ({
  order,
  clearShoppingCartAndOrder,
  setShowResultPopup,
  userEmail,
}: IPaymentProps) => {
  return (
    <div>
      <div className="Payment">
        <div className="PaymentTitle">Payment Information</div>
        <StripeContainer
          order={order}
          clearShoppingCartAndOrder={clearShoppingCartAndOrder}
          setShowResultPopup={setShowResultPopup}
          userEmail={userEmail}
        />
      </div>
    </div>
  );
};
