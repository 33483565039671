import React from 'react';

import ProductList from './Components/ProductList/ProductList';
import './ProductsSection.scss';

const ProductsSection = () => (
  <section className="ProductsSection">
    <ProductList />
  </section>
);

export default ProductsSection;
