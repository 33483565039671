import {
  makeRequest,
  getLogoutUrl,
  getUserProfile,
  updateUserProfile,
  clearTokens,
  clearLoginProvider,
} from '../utils';

export const USER_API = {
  updateProfile: async (formData) => await makeRequest('put', updateUserProfile(), formData),

  getProfile: async (setUserInfo, loggedWith) => {
    const { data, err } = await makeRequest('get', getUserProfile());

    if (data) {
      setUserInfo((userInfo) => ({
        ...userInfo,
        isLoading: false,
        loggedWith,
        data,
      }));

      localStorage.setItem('userData', JSON.stringify(data));
    }

    if (err) {
      clearTokens();
      clearLoginProvider();
      setUserInfo(() => ({
        isLoading: false,
        loggedWith: null,
      }));
    }
  },

  logout: async () => await makeRequest('post', getLogoutUrl()),
};
