import React from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import FacebookIcon from '../../../assets/images/facebook.png';
import { facebookAppId, facebookRedirectURL } from '../../../utils/constants';

const FacebookButton = ({ label, handleFacebookAccessToken }) => {
  const facebookDataHandler = (response) => {
    if (response.status !== 'unknown') {
      handleFacebookAccessToken(response);
    }
  };
  return (
    <FacebookLogin
      appId={facebookAppId}
      redirectUri={facebookRedirectURL}
      autoLoad={false}
      fields="name,email"
      scope="email"
      callback={facebookDataHandler}
      render={({ onClick, isDisabled }) => (
        <button
          onClick={onClick}
          type="button"
          className="sign-in-sign-up-with-facebook"
          disabled={isDisabled}
        >
          <img src={FacebookIcon} alt={label} />
          <span>{label}</span>
        </button>
      )}
    />
  );
};

FacebookButton.propTypes = {
  label: PropTypes.string,
  handleFacebookAccessToken: PropTypes.func,
};

FacebookButton.defaultProps = {
  label: '',
  handleFacebookAccessToken: () => {},
};

export default FacebookButton;
