import { combineReducers } from 'redux';

import { userReducer } from './userReducer';
import { countriesReducer } from './countriesReducer';
import { shippingInfoReducer } from './shippingInfoReducer';
import { categoriesReducer } from './categoriesReducer';
import { wishListItemReducer } from './wishListItemReducer';
import { productsReducer } from './productsReducer';

export default combineReducers({
  user: userReducer,
  categories: categoriesReducer,
  countries: countriesReducer,
  shippingInfo: shippingInfoReducer,
  wishList: wishListItemReducer,
  products: productsReducer,
});
