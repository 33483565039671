import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { getStoredShippingInfo } from '../../../../../../redux/reducers/selectors';
import './AdressInfo.scss';

interface IAddressInfoProps {
  addressInfoTitle: string;
}

export const AddressInfo = ({ addressInfoTitle }: IAddressInfoProps) => {
  const { shippingAddress, shippingMethod } = useSelector(getStoredShippingInfo);
  const [isBillingAddress, setIsBillingAddress] = useState(true);
  const handleBillingAddress = () => setIsBillingAddress((value) => !value);

  const {
    firstName,
    lastName,
    city,
    country,
    address1,
    zipCode,
    phone,
    email,
    state,
  } = shippingAddress;
  const { title, timeEstimate } = shippingMethod;
  const isUsa = country?.name === 'United States';

  return (
    <div className="AddressInfo">
      <div className="AddressInfoTitle">{addressInfoTitle}</div>
      {/*
      todo there is no orderID, because it had not been created yet
      <div className="AddressInfoDesc">{`Order number: ${orderID}`}</div>
      */}
      <div className="PaymentDeteils">
        <div className="ShippingAddressWrapper">
          <div className="ShippingAddressTitle">Shipping Address</div>
          <div>{`${firstName} ${lastName}`}</div>
          <div>{address1}</div>
          <div>{`${city}, ${isUsa ? state : ''} ${zipCode}`}</div>
          <div>{country?.name}</div>
          <div>{phone}</div>
          <div>{email}</div>
        </div>
        <div className="ShippingMethodWrapper">
          <div className="ShippingMethodTitle">Shipping Method</div>
          <div>{title}</div>
          <div>{timeEstimate}</div>
        </div>
      </div>
      <div>
        <div className="BillingAddress">
          <div className="BillingAddressTitle">Billing Address</div>
          <FormControlLabel
            label="My billing address is the same as my shipping address."
            control={<Checkbox checked={isBillingAddress} onChange={handleBillingAddress} />}
          />
        </div>
      </div>
    </div>
  );
};
