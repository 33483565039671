import React from 'react';

import UserMenu from '../UserMenu/UserMenu';
import { REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER } from '../../utils/utils';
import './UserIconPopup.scss';

const UserIconPopup = ({
  loggedWith,
  isLoading,
  firstName,
  lastName,
  setIsModalOpen,
  setIsPopperOpen,
  openDialog,
}) => {
  const handleClick = (isSignIn = false) => () => {
    setIsModalOpen(true);
    setIsPopperOpen(false);
  };
  const handleLogout = () => {
    setIsPopperOpen(false);
    openDialog();
  };

  if (isLoading) {
    return REMOVE_WITH_MARKUP_LOADING_PLACEHOLDER;
  }

  return (
    <div className="user-menu-wrapper">
      <div className="user-menu-title">WELCOME AT BOARDZONE</div>
      <div className="user-menu-unlogged-user-navigation">
        {loggedWith ? (
          `HI ${firstName ? firstName.toUpperCase() : ''} ${lastName ? lastName.toUpperCase() : ''}`
        ) : (
          <>
            <button type="button" onClick={handleClick(true)}>
              SIGN IN
            </button>
            <button type="button" onClick={handleClick()}>
              SIGN UP
            </button>
          </>
        )}
      </div>
      {loggedWith && <UserMenu handleLogout={handleLogout} setIsPopperOpen={setIsPopperOpen} />}
    </div>
  );
};

export default UserIconPopup;
