import React, { FC } from 'react';

import { MainButton } from '../Buttons/MainButton';

interface DeleteAccountProps {
  redirectToHomePage: () => void;
  title?: string;
  subtitle?: string;
}

const DeleteAccountMessage: FC<DeleteAccountProps> = ({ redirectToHomePage, title, subtitle }) => {
  return (
    <div className="password-page-container">
      <div className="reset-form-container">
        <div className="reset-form">
          <>
            <div className="successful-reset-container">
              <div className="title">{title}</div>
              <span className="subtitle">{subtitle}</span>
            </div>
          </>
          <MainButton
            className="sign-in-sign-up-submit-button confirm-btn"
            onClick={redirectToHomePage}
          >
            Go to Boardzone
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountMessage;
