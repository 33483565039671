import {
  ON_FETCHING_DATA_INIT,
  ON_FETCHING_DATA_ERROR,
  ON_GET_ALL_COUNTRIES_SUCCESS,
} from './countriesTypes';
import { makeRequest } from '../../../utils/utils';
import { getCountriesUrl } from '../../../utils/endpoints';
import { ICountry } from '../../../TypesAndInterfaces/Interfaces';

export const getAllCountries = () => async (
  dispatch: (arg0: { type: string; payload?: ICountry[] }) => void
) => {
  dispatch({
    type: ON_FETCHING_DATA_INIT,
  });

  const { data, err } = await makeRequest('get', getCountriesUrl());

  if (data) {
    return dispatch({
      type: ON_GET_ALL_COUNTRIES_SUCCESS,
      payload: data,
    });
  }

  if (err) {
    return dispatch({
      type: ON_FETCHING_DATA_ERROR,
    });
  }
};
