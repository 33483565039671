import React from 'react';

import './EventComponent.scss';
import imgTrip from '../../assets/images/trip.png';
import imgEvent from '../../assets/images/event.png';

const EventComponent = (props) => (
  <div className="EventComponent">
    <div className="EventDate">
      <span>{props.dateFrom.from.number}</span>
      {props.dateFrom.from.month} -<span>{props.dateTo.to.number}</span>
      {props.dateTo.to.month}
    </div>
    <strong className="EventTitle">{props.title}</strong>
    <ul className="EventList">
      <li className="EventPlace">
        <span className="icon-pin-alt  EventIcon" />
        <span className="EventCity">{props.city}</span>,{` ${props.country}`}
      </li>
      <li className="EventPrice">
        <span className="icon-dolar EventIcon" />
        {props.price}
      </li>
      <li className="EventParticipants">
        <span className="icon-user-o EventIcon" />
        <span className="EventPeople">{props.people}</span> participants
      </li>
    </ul>
    <img className="EventImg" src={props.type === 'event' ? imgEvent : imgTrip} alt="" />
  </div>
);

export default EventComponent;
