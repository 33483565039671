export const ON_FETCHING_DATA_INIT = '[wishListItem] ON_FETCHING_DATA_INIT';

export const ON_FETCHING_DATA_ERROR = '[wishListItem] ON_FETCHING_DATA_ERROR';

export const ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS =
  '[wishListItem] ON_GET_WISH_LIST_ITEM_PAGE_SUCCESS';

export const ON_ADD_WISHLIST_ITEM = '[wishListItem] ON_ADD_WISHLIST_ITEM';

export const ON_REMOVE_WISHLIST_ITEM = '[wishListItem] ON_REMOVE_WISHLIST_ITEM';
