import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { indexOf } from 'lodash';

import { Address } from './Address/Address';
import { getStoredCountries, getStoredUser } from '../../../../../redux/reducers/selectors';
import { getUniqueKey } from '../../../../../utils';
import { IAddress } from '../../../../../TypesAndInterfaces/Interfaces';
import './SavedAddresses.scss';

interface iSavedAddressesProps {
  setShowEditAddressForm: Dispatch<SetStateAction<Partial<IAddress> | null>>;
}

export const SavedAddresses = ({ setShowEditAddressForm }: iSavedAddressesProps) => {
  const { savedAddresses, userInfo } = useSelector(getStoredUser);
  const { countries } = useSelector(getStoredCountries);

  return (
    <div className="saved-addresses">
      <div className="shippingAddressTitle">Shipping address</div>
      <div className="title">Saved Addresses</div>
      <div className="content">
        {savedAddresses.map((item: IAddress) => {
          {
            if (savedAddresses.length >= 3) {
              if (
                savedAddresses.indexOf(item) === savedAddresses.length - 1 ||
                savedAddresses.indexOf(item) === savedAddresses.length - 2 ||
                savedAddresses.indexOf(item) === savedAddresses.length - 3
              ) {
                return (
                  <Address
                    {...item}
                    firstName={userInfo.firstName}
                    lastName={userInfo.lastName}
                    country={countries[item.countryId]}
                    key={getUniqueKey('address')}
                    setShowEditAddressForm={setShowEditAddressForm}
                  />
                );
              } else {
                return null;
              }
            } else {
              return (
                <Address
                  {...item}
                  firstName={userInfo.firstName}
                  lastName={userInfo.lastName}
                  country={countries[item.countryId]}
                  key={getUniqueKey('address')}
                  setShowEditAddressForm={setShowEditAddressForm}
                />
              );
            }
          }
        })}
      </div>
    </div>
  );
};
