import React from 'react';

import './AddToWishList.scss';
import heart from '../../assets/images/icon/heart.svg';
import heartFilled from '../../assets/images/icon/heart-red.svg';

interface IAddToWishProps {
  isAdded: boolean;
  onClick: () => void;
}

export const AddToWishList = ({ isAdded, onClick }: IAddToWishProps) => {
  return (
    <button onClick={onClick} className="add-to-wish-list">
      <img
        className="add-to-wish-list__icon"
        src={isAdded ? heartFilled : heart}
        alt="heart icon"
      />
      <span className="add-to-wish-list__description">
        {isAdded ? 'added to your wish list' : 'add to wish list'}
      </span>
    </button>
  );
};
