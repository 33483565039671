import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeElements, StripeCardElement } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';

import { MainButton } from '../../../../../../Components/Buttons/MainButton';
import {
  getOrdersPayUrl,
  getOrdersUrl,
  LOGIN_PROVIDER,
  makeRequest,
  notificationTypes,
} from '../../../../../../utils';
import { resultPopupState } from '../../../../CheckoutPage';
import './PaymentForm.scss';
import { IOrderInfo } from '../../../../../../TypesAndInterfaces/Interfaces';
import { getUserInfo, saveUserAddress } from '../../../../../../redux/actions/user/userActions';
import { NotificationsContext } from '../../../../../../contexts/contexts';

const YOUR_ORDER_HAS_NOT_BEEN_PLACED = 'Sorry! Your order has not been placed.';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: 'black',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

interface IPaymentFormProps {
  order: IOrderInfo | null;
  clearShoppingCartAndOrder: () => void;
  setShowResultPopup: Dispatch<SetStateAction<string>>;
  userEmail: string;
}

export const PaymentForm = ({
  order,
  clearShoppingCartAndOrder,
  setShowResultPopup,
  userEmail,
}: IPaymentFormProps) => {
  const stripe = useStripe();
  const elements = useElements() as StripeElements;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const { notifications, setNotifications } = useContext(NotificationsContext);

  if (!order) {
    return null;
  }

  const makePayment = async () => {
    setIsLoading(true);
    const { data, err } = await makeRequest('post', getOrdersUrl(), order);

    if (err) {
      setNotifications([
        ...notifications,
        {
          type: notificationTypes.error,
          message: YOUR_ORDER_HAS_NOT_BEEN_PLACED,
          id: Date.now(),
        },
      ]);
      setShowResultPopup(resultPopupState.placingOrderFail);
    }

    if (data) {
      if (stripe) {
        const { token } = await stripe.createToken(
          elements.getElement(CardElement) as StripeCardElement
        );

        if (token) {
          const { status, err } = await makeRequest('post', getOrdersPayUrl(), {
            OrderId: data.orderId,
            StripeToken: token.id,
            StripeEmail: userEmail,
          });

          if (err) {
            setShowResultPopup(resultPopupState.sendingPaymentFail);
          }

          if (status === 200) {
            setShowResultPopup(resultPopupState.sendingPaymentSuccess);
            dispatch(getUserInfo(localStorage.getItem(LOGIN_PROVIDER)));
            clearShoppingCartAndOrder();
          }
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onChange = (e: StripeCardElementChangeEvent) => {
    e.complete && setIsDisabled(false);
  };

  return (
    <>
      <div className="payment-form">
        <div className="card-element">
          <CardElement options={{ iconStyle: 'solid', ...CARD_OPTIONS }} onChange={onChange} />
        </div>
        <MainButton
          className="sign-in-sign-up-submit-button"
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={makePayment}
        >
          Pay
        </MainButton>
      </div>
    </>
  );
};
