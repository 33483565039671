import { useDispatch } from 'react-redux';

import { clearUserInfo } from '../redux/actions/user/userActions';

const useAuthHandler = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(clearUserInfo());
  };

  const login = async (loggedWith) => {
    // TODO add AUTH login action
    // dispatch();
  };

  window.addEventListener('storage', function (event) {
    if (event.key !== 'loginProvider' || event.newValue === event.oldValue) {
      return;
    }

    if (event.key === 'loginProvider') {
      event.newValue ? login(event.newValue) : logout();
    }
  });
};

export default useAuthHandler;
