import React from 'react';

import './Subheader.scss';
import CardWrapper from '../../../../Components/CardWrapper/CardWrapper';
import HelpText from '../../../Account/Components/HelpText/HelpText';

const Subheader = () => (
  <section className="SubheaderSection">
    <CardWrapper>
      <h1>Shopping Cart</h1>
      <HelpText textAlign="right" />
    </CardWrapper>
  </section>
);

export default Subheader;
