import React from 'react';
import Switch from '@material-ui/core/Switch';
import { styled } from '@material-ui/core/styles';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      color: '#fff',
      backgroundColor: '#2eca45',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#2eca45',
      },
    },
    '&.Mui-disabled': {
      color: '#fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
  },
}));

export default StyledSwitch;
