import React from 'react';
import { Link } from 'react-router-dom';

import { getImagesUrl } from '../../utils/endpoints';
import locations from '../../utils/locations';
import { useDragDetection } from '../../hooks/useDragDetection';
import './Product.scss';
import { IProduct } from '../../TypesAndInterfaces/Interfaces';

interface IProductProps extends Pick<IProduct, 'brand' | 'model' | 'price' | 'id'> {
  img: string | number;
  delay?: string;
  oldPrice?: number;
  imageSize?: { width: number; height: number };
  imgIsALink?: boolean;
  productList: IProduct[];
}

export const Product = (props: IProductProps) => {
  const {
    id,
    delay,
    img,
    brand,
    model,
    price,
    oldPrice,
    productList,
    imageSize,
    imgIsALink = false,
  } = props;
  const { handleMouseDown, dragging } = useDragDetection(); //Hook for preventing onClick event while dragging items

  return (
    <Link
      onMouseDown={handleMouseDown}
      onClick={(e) => dragging && e.preventDefault()}
      to={{
        pathname: locations.productPage(String(id)),
        state: { productList },
      }}
    >
      <div className="Product" style={{ animationDelay: delay }}>
        <div className="ProductHolder">
          <div className="ProductImgHolder">
            <img
              className="ProductImg"
              alt=""
              src={
                imgIsALink
                  ? (img as string)
                  : getImagesUrl({
                      id: img,
                      parameters: imageSize || null,
                    })
              }
            />
          </div>
          <div className="ProductDescription">
            <strong className="ProductBrand">{brand}</strong>
            <span className="ProductModel">{model}</span>
            <span className="ProductPrice">$ {price}</span>
            <span className="ProductDiscountPrice">{oldPrice}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
