import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { filtersList, flexesList } from '../../../../../../utils/constants';
import { IFiltersProps } from '../../../../../../TypesAndInterfaces/Interfaces';

import '@material-ui/core/FormGroup';

const SelectFlex = ({ filters, clearFilters, handleFiltersChange }: IFiltersProps) => {
  const [chosenFlex, setChosenFlex] = useState<string[]>([]);

  const flexSelectOptions = flexesList.map((level: string, index) => (
    <MenuItem key={index} value={level}>
      <Checkbox checked={chosenFlex.indexOf(level) > -1} />
      <ListItemText primary={level} />
    </MenuItem>
  ));

  // { value: unknown } is used because if material-ui types documentation; if it is not used there will be TS error.
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;

    setChosenFlex(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    const flexReq = {
      [filtersList.Flex]: chosenFlex,
    };
    handleFiltersChange({ ...filters, ...flexReq });
  }, [chosenFlex]);

  useEffect(() => {
    if (clearFilters) {
      setChosenFlex([]);
    }
  }, [clearFilters]);

  return (
    <div className="SelectLeftWrapper">
      <div className="SelectCenterContainer">
        <div className="SelectTitle">
          <span>Flex:</span>
        </div>
        <FormControl variant="outlined">
          <Select
            value={chosenFlex}
            input={<OutlinedInput />}
            multiple
            displayEmpty
            onChange={(e: React.ChangeEvent<{ value: unknown }>): void => handleChange(e)}
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <span>All</span>;
              }
              if ((selected as string[]).length === flexesList.length) {
                return <span>All</span>;
              }
              return (selected as string[]).join(', ');
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {flexSelectOptions}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectFlex;
