import React from 'react';

import locations from '../../../src/utils/locations';
import './Footer.scss';
import SignUpForm from '../SingnUpForm/SignUpForm';
import FooterLinkList from '../FooterLinkList/FooterLinkList';
import CrossHalfRight from '../../assets/images/cross-half-right.png';
import FooterTriangle from '../../assets/images/footer-triangle.png';
import HalftoneRight from '../../assets/images/halftone-right.png';
import FooterCrosses from '../../assets/images/footer-crosses.png';
import HalftoneLeft from '../../assets/images/halftone-left.png';

const firstList = {
  title: 'Company',
  data: [
    {
      src: '#',
      linkTitel: 'Distributors',
    },
    {
      src: '#',
      linkTitel: 'Find a Shop',
    },
    {
      src: '#',
      linkTitel: 'About Us',
    },
    {
      src: '#',
      linkTitel: 'Sitemap',
    },
  ],
};

const secondList = {
  title: 'Get in touch',
  data: [
    {
      src: '#',
      linkTitel: 'Contacts',
    },
    {
      src: 'https://www.dataart.com/privacy-policy',
      linkTitel: ' Privacy Policy',
      external: true,
    },
    {
      src: '#',
      linkTitel: 'Our Team',
    },
  ],
};

const thirdList = {
  title: 'Socials',
  data: [
    {
      src: '#',
      linkTitel: 'Instagram',
      external: true,
    },
    {
      src: '#',
      linkTitel: 'Facebook',
      external: true,
    },
    {
      src: '#',
      linkTitel: 'YouTube',
      external: true,
    },
    {
      src: '#',
      linkTitel: 'Vimeo',
      external: true,
    },
    {
      src: locations.deleteDataFacebook,
      linkTitel: 'Facebook data deletion instructions',
      external: false,
    },
  ],
};

export const Footer = () => (
  <footer className="Footer">
    <h3>Sign up for the latest news</h3>
    <div className="FooterInner">
      <SignUpForm />
      <div className="FooterLinkListOuter">
        <FooterLinkList links={firstList} />
        <FooterLinkList links={secondList} />
        <FooterLinkList links={thirdList} />
      </div>
    </div>
    <img className="CrossHalfRight" src={CrossHalfRight} alt="" />
    <img className="FooterTriangle" src={FooterTriangle} alt="" />
    <img className="HalftoneRight" src={HalftoneRight} alt="" />
    <img className="FooterCrosses" src={FooterCrosses} alt="" />
    <img className="HalftoneLeft" src={HalftoneLeft} alt="" />
    <div className="Copy">
      <span>All rights reserved Copyright {new Date().getFullYear()}</span>
    </div>
  </footer>
);
