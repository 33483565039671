import React, { useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import findIndex from 'lodash/findIndex';

import { getBoardByIdUrl } from '../../utils/endpoints';
import { GeneralInfo } from './Sections/GeneralInfo/GeneralInfo';
import { SimpleBreadcrumbs } from '../../Components/Breadcrumbs/SimpleBreadcrumbs';
import ProductDetails from './Sections/ProductsDetails/ProductDetails';
import CardWrapper from '../../Components/CardWrapper/CardWrapper';
import ProductLink from '../../Components/ProductLink/ProductLink';
import { stateStatuses } from '../../utils/constants';
import { scrollToTop } from '../../utils/utils';
import { makeRequest } from '../../utils';
import {
  ILocationData,
  IProductData,
  IProductDetails,
  ISizes,
} from '../../TypesAndInterfaces/Interfaces';
import locations from '../../utils/locations';
import { getStoredCategories } from '../../redux/reducers/selectors';

import './ProductPage.scss';

const mapDetailsToSizes = (details: IProductDetails[]) =>
  details.map((item, index) => ({
    value: index,
    boardDetailId: item.id,
    size:
      item.productAttributes.length > 0
        ? item.productAttributes.find((o) => o.attributeName === 'Length')?.attributeValue
        : 'No info',
    quantity: item.quantity,
  }));

const ProductPage = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<ILocationData>();
  const { categories } = useSelector(getStoredCategories);
  const [info, setInfo] = useState<IProductData | null>(null);
  const [sizes, setSizes] = useState<ISizes | null>(null);
  const [pageStatus, setPageStatus] = useState(stateStatuses.loading);
  const [locationData, setLocationData] = useState<ILocationData>(state);

  useEffect(() => {
    setPageStatus(() => stateStatuses.loading);
    scrollToTop();
    setLocationData((data) => {
      if (data?.productList) {
        return {
          ...data,
          index: findIndex(data.productList, (x) => x.id === parseInt(id)),
        };
      }
      return data;
    });

    (async () => {
      const { data, err } = await makeRequest('get', getBoardByIdUrl({ id }));
      if (err) {
        setPageStatus(() => stateStatuses.failure);
      }

      if (data) {
        setInfo(data);
        setSizes({
          sizesList: mapDetailsToSizes(data.productDetails),
          selectedIndex: 0,
          selectedQuantity: 1,
          boardDetailId: mapDetailsToSizes(data.productDetails)[0].boardDetailId,
        });

        setPageStatus(() => stateStatuses.success);
      }
    })();
  }, [id]);

  if (pageStatus === stateStatuses.failure) {
    return <Redirect to={locations.productNotFound} />;
  }
  if (pageStatus !== stateStatuses.success) return null;

  // Only gender sections are implemented now until more products with full information will be created in catalog.
  const gender = info?.productDetails[0].productAttributes.find(
    (attribute) => attribute.attributeName === 'Gender'
  )?.attributeValue;

  const subCategory = categories.find((category) => category.id === info?.categoryId);
  const category = categories.find((category) => category.id === subCategory.parentCategoryId);

  return (
    <main className="with-space-for-header">
      <SimpleBreadcrumbs
        sectionName={gender ?? 'Men'}
        categoryName={category!.name}
        subCategoryName={subCategory!.name}
        productTitle={info?.model}
      />
      <div className="BgWrapper">
        <CardWrapper>
          {info && (
            <>
              {sizes && (
                <GeneralInfo info={info} sizes={sizes} setSizes={setSizes} images={info.images} />
              )}
              <ProductDetails description={info.description} />
            </>
          )}
          {locationData?.productList && locationData?.index !== -1 && (
            <div className="ProductLinks">
              <ProductLink
                direction="left"
                info={locationData.productList[locationData.index - 1]}
              />
              <ProductLink
                direction="right"
                info={locationData.productList[locationData.index + 1]}
              />
            </div>
          )}
        </CardWrapper>
      </div>
    </main>
  );
};

export default ProductPage;
