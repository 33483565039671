import React, { ChangeEvent, Dispatch, useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { setFormLabel } from '../../../utils/utils';
import {
  IEditProfileFormData,
  IEditProfileFormFields,
  IInitialErrors,
} from '../../../TypesAndInterfaces/Interfaces';

interface ISimpleFormProps {
  values: IEditProfileFormData;
  errors: FormikErrors<IEditProfileFormData>;
  touched: FormikTouched<IEditProfileFormData>;
  isValid: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  validateForm: (values?: IEditProfileFormData) => Promise<FormikErrors<IEditProfileFormData>>;
  fields: IEditProfileFormFields;
  setIsValidForm: Dispatch<React.SetStateAction<boolean>>;
  handleValueChange: (field: string) => (e: ChangeEvent<HTMLInputElement>) => void;
  showAllErrors?: boolean;
  initialErrors?: IInitialErrors;
  textFieldClassName?: string;
  formControlClassName?: string;
  setErrorNotification: Dispatch<React.SetStateAction<string>>;
}

export const SimpleForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  setFieldTouched,
  validateForm,
  showAllErrors,
  fields,
  setIsValidForm,
  handleValueChange,
  initialErrors = {},
  textFieldClassName = '',
  formControlClassName = '',
  setErrorNotification,
}: ISimpleFormProps) => {
  const change = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setErrorNotification('');
    handleChange(e);
    handleValueChange(name)(e);
  };

  const handleFocus = (name: string) => {
    setFieldTouched(name, false, false);
  };

  const handleBlur = (name: string) => {
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    setIsValidForm(isValid);
  }, [isValid, setIsValidForm]);

  return (
    <>
      {Object.entries(fields).map(([key, item]) => (
        <FormControl variant="outlined" className={formControlClassName} key={key}>
          {setFormLabel(fields[key], !item.editTitle)}
          {item.extraElement ? item.extraElement : ''}
          <TextField
            variant="outlined"
            type={item.type}
            autoComplete={item.type === 'password' ? 'new-password' : ''}
            className={textFieldClassName}
            disabled={!!item?.selectFrom?.length}
            value={values && (values[key] || '')}
            inputProps={{ 'aria-label': `${key}` }}
            name={key}
            helperText={touched[key] || showAllErrors ? errors[key] || initialErrors[key] : ''}
            error={
              (touched[key] || showAllErrors) &&
              (Boolean(errors[key]) || Boolean(initialErrors[key]))
            }
            onChange={change(key)}
            onFocus={() => handleFocus(key)}
            onBlur={() => handleBlur(key)}
          />
        </FormControl>
      ))}
    </>
  );
};
